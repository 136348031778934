// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as isoImageActions from 'common/modules/isoImage/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import IsoImageForm,
{ FIELDS } from 'common/containers/IsoImageForm/IsoImageForm';
import { IsoImageActions } from 'admin/isoImage/components/IsoImageActions';
import CellIcon from 'admin/icon/components/CellIcon/CellIcon';
import { Size } from 'common/components/Size/Size';

enum IsoImageTableColumns {
    ID = 'colId',
    NAME = 'colName',
    USER = 'colUser',
    OS_TYPE = 'colOSType',
    VISIBILITY = 'colVisibility',
    // eslint-disable-next-line @typescript-eslint/no-shadow
    SIZE = 'colSize',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '1%',
    key: IsoImageTableColumns.ID,
    title: <Translate content="isoImage.list.id" />,
}, {
    width: '15%',
    key: IsoImageTableColumns.NAME,
    title: <Translate content="isoImage.list.name" />,
}, {
    width: '15%',
    key: IsoImageTableColumns.USER,
    title: <Translate content="isoImage.list.user" />,
}, {
    key: IsoImageTableColumns.OS_TYPE,
    title: <Translate content="isoImage.list.osType" />,
}, {
    key: IsoImageTableColumns.VISIBILITY,
    title: <Translate content="isoImage.list.visibility" />,
}, {
    key: IsoImageTableColumns.SIZE,
    title: <Translate content="isoImage.list.size" />,
}, getActionColumnProps(),
];

export type IsoImagesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const IsoImages: React.FC<IsoImagesProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    isoImageActions: {
        getIsoImages,
        getIsoImage,
        removeIsoImage,
    },
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getIsoImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getIsoImages({ page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleRemove = (id: number) => async () => {
        await removeIsoImage(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getIsoImage(id);
        setDialogOpen(true);
    };

    const data = list.data.map((isoImage) => ({
        [IsoImageTableColumns.ID]: isoImage.id,
        [IsoImageTableColumns.NAME]: (
            <CellIcon icon={isoImage.icon} name={isoImage.name} />
        ),
        [IsoImageTableColumns.USER]: isoImage.user.email,
        [IsoImageTableColumns.OS_TYPE]: isoImage.os_type,
        [IsoImageTableColumns.VISIBILITY]: isoImage.visibility,
        [IsoImageTableColumns.SIZE]: (
            <Size size={isoImage.size}/>
        ),
        [IsoImageTableColumns.ACTIONS]: (
            <IsoImageActions
                item={isoImage}
                handleEdit={handleEdit(isoImage.id)}
                handleRemove={handleRemove(isoImage.id)}
            />
        ),
        key: isoImage.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="isoImage.title"/>}
                buttonText="isoImage.addButton"
                buttonIcon={ICONS.CD}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="isoImage.emptyView.title"
                            description="isoImage.emptyView.description"
                            buttonText="isoImage.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.CD}
                        />
                    }
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id
                    ? 'isoImage.dialog.editTitle'
                    : 'isoImage.dialog.createTitle'
                } />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <IsoImageForm onSubmit={() => setDialogOpen(false)} fields={Object.values(FIELDS)}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.isoImage.item,
    list: state.isoImage.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.ISO_IMAGE_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.ISO_IMAGE_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    isoImageActions: bindActionCreators(isoImageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IsoImages);

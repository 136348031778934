// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import Login from 'client/auth/containers/AuthLogin/AuthLogin';
import SimpleRegisterForm from 'client/auth/containers/SimpleRegisterForm/SimpleRegisterForm';
import Layout from 'client/common/containers/Layout/Layout';
import Project from 'client/project/containers/Project';
import ProjectItem from 'client/project/containers/projectItem/ProjectItem';
import ProjectServerCreate from 'client/project/containers/projectServer/ProjectServerCreate/ProjectServerCreate';
import ProjectServerItem from 'client/project/containers/projectServer/ProjectServerItem/ProjectServerItem';
import ProjectVncDialog from 'client/project/containers/ProjectVncDialog';
import ResetPassword from 'common/modules/auth/containers/ResetPassword/ResetPassword';
import ForgotPassword from 'common/modules/auth/containers/ForgotPassword/ForgotPassword';
import VerifyEmail from 'client/auth/containers/VerifyEmail/VerifyEmail';
import Account from 'client/account/containers/Account/Account';
import AuthLayout from 'common/modules/auth/layouts/AuthLayout/AuthLayout';
import Documentation from 'common/containers/documentation/Documentation';
import AuthByToken from 'common/modules/auth/containers/AuthByToken/AuthByToken';
import { NoLayout } from 'common/components/NoLayout';
import ISOImages from 'client/isoImage/containers/IsoImages';
import TwoFactorAuthLogin  from 'common/modules/auth/containers/TwoFactorAuthLogin/TwoFactorAuthLogin';

export const routes = [
    {
        layout: AuthLayout,
        routes: [
            {
                component: Login,
                path: '/login',
            },
            {
                component: TwoFactorAuthLogin,
                path: '/login/2fa',
            },
            {
                component: SimpleRegisterForm,
                path: '/projects/:id/join/:token',
            },
            {
                component: ForgotPassword,
                path: '/admin/forgot_password',
            },
            {
                component: ForgotPassword,
                path: '/forgot_password',
            },
            {
                component: ResetPassword,
                path: '/reset_password',
            },
            {
                component: VerifyEmail,
                path: '/verify_email/:id',
            },
            {
                component: AuthByToken,
                path: '/auth/by_token/:token',
            },
        ],
    },
    {
        layout: Layout,
        routes: [
            {
                component: Project,
                path: '/projects',
            },
            {
                component: ProjectItem,
                path: '/projects/:id',
            },
            {
                component: ProjectServerCreate,
                path: '/projects/:id/servers/create',
            },
            {
                component: ProjectServerItem,
                path: '/projects/:id/servers/:vmId',
            },
            {
                component: Account,
                path: '/account',
            },
            {
                component: ISOImages,
                path: '/iso_images',
            },
        ],
    },
    {
        layout: NoLayout,
        routes: [
            {
                component: ProjectVncDialog,
                path: '/vnc_client/:id',
            },
            {
                component: Documentation,
                path: '/documentation',
            },
        ],
    },
];

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as isoImageActions from 'common/modules/isoImage/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    IIsoImageResponse,
    IsoChecksumMethod,
    IsoImageVisibility,
} from 'common/api/resources/IsoImage';
import { OS_TYPES } from 'common/constants';
import { initialIconState } from 'admin/icon/reducer';

interface IIsoImage {
    list: IPaginateApiResponse<IIsoImageResponse[]>;
    item: IIsoImageResponse;
}

export type IsoImageAction = ActionType<typeof isoImageActions>;
export type IsoImageState = IIsoImage;

export const initialIsoImageState: IIsoImageResponse = {
    id: 0,
    name: '',
    icon: initialIconState,
    user: {
        id: 0,
        email: '',
    },
    iso_checksum: '',
    iso_checksum_method: IsoChecksumMethod.MD5,
    iso_url: '',
    os_type: OS_TYPES.LINUX,
    show_tls: true,
    show_url_and_checksum: true,
    size: 0,
    use_tls: true,
    visibility: IsoImageVisibility.PRIVATE,
    is_deleting: false,
};

export default combineReducers<IsoImageState, IsoImageAction>({
    list: (state = paginateInitialState, action: IsoImageAction) => {
        switch (action.type) {
        case getType(isoImageActions.addIsoImageItem):
            return addToPaginated(state, action.payload);
        case getType(isoImageActions.setIsoImageList):
            return action.payload;
        case getType(isoImageActions.removeIsoImageItem):
            return deleteFromPaginated(state, action.payload);
        case getType(isoImageActions.updateIsoImageItem):
            return updateInPaginated(state, action.payload);
        case getType(isoImageActions.setIsoImageItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialIsoImageState, action) => {
        switch (action.type) {
        case getType(isoImageActions.setIsoImageItem):
            return action.payload;
        case getType(isoImageActions.unsetIsoImageItem):
            return { ...initialIsoImageState };
        default:
            return state;
        }
    },
});

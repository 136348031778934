// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { IIconResponse } from 'common/api/resources/Icon';
import { IShortUserResponse } from 'common/api/resources/User';
import { OS_TYPES } from 'common/constants';

export interface IIsoImageRequest {
    name: string;
    icon_id?: number;
    user_id: number;
    visibility: IsoImageVisibility;
    os_type: OS_TYPES;
    iso_url: string;
    use_tls: boolean;
    iso_checksum_method: IsoChecksumMethod;
    iso_checksum: string;
    show_url_and_checksum: boolean;
    show_tls: boolean;
}

export interface IIsoImageResponse {
    id: number;
    name: string;
    icon: IIconResponse;
    user: IShortUserResponse;
    visibility: IsoImageVisibility;
    os_type: OS_TYPES;
    iso_url: string;
    use_tls: boolean;
    iso_checksum_method: IsoChecksumMethod;
    iso_checksum: string;
    size: number;
    show_url_and_checksum: boolean;
    show_tls: boolean;
    is_deleting?: boolean;
}

export interface IIsoImageListFilters {
    search?: string;
    os_type?: string;
}

export interface IIsoImageListRequest extends IRequest<IIsoImageListFilters>, IPaginated {}

export enum IsoChecksumMethod {
    MD5 = 'md5',
    SHA1 = 'sha1',
    SHA256 = 'sha256',
}

export enum IsoImageVisibility {
    PUBLIC = 'public',
    PRIVATE = 'private',
}
// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import { IIpBlockResponse } from 'common/api/resources/IpBlock/model';

export enum VlanMode {
    TRUNK = 'trunk',
    NATIVE_UNTAGGED = 'native-untagged',
    NATIVE_TAGGED = 'native-tagged',
}

export interface IVlanResponse {
    id: number;
    name: string;
    tag: number;
    mode: VlanMode;
    trunks?: number[];
    ip_blocks?: IIpBlockResponse[];
}

export interface IVlanListFilters {}

export interface IVlanRequest {
    name: string;
    tag: number;
    mode: VlanMode;
    trunks?: number[];
    ip_block_ids?: number[];
}

export interface IVlanPatchRequest {}

export interface IVlanListRequest extends IRequest<IVlanListFilters>, IPaginated {}
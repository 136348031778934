// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import * as licenseActions from 'admin/license/solusVM/actions';
import { connect } from 'react-redux';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    Translate,
    ExtendedStatusMessageActions,
    Status,
    Text,
} from '@plesk/ui-library';
import Usage from 'admin/common/components/Usage/Usage';
import CopyText from 'common/containers/CopyText/CopyText';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Loader } from 'common/components';
import { LicenseStatus } from 'common/api/resources/License/solusVM';
import {
    ProgressLoaderWrapper,
    StatusWrapper,
    Table,
} from 'admin/license/solusVM/containers/Styles';
import ComputeResourcesForm from 'admin/license/solusVM/containers/ComputeResourcesForm';
import { Dialog } from 'common/components/Dialog/Dialog';
import LicenseActivationForm from 'admin/license/solusVM/containers/LicenseActivationForm';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';

export type SolusVMLicenseProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export interface IProgressLoaderProps {
    isLoading: boolean;
}

export const SolusVMLicense: React.FC<SolusVMLicenseProps> = ({
    licenseState,
    licenseActions: {
        getLicense,
        refreshLicense,
        resetLicense,
    },
    loadingFlags: {
        isLicenseLoading,
        isLicenseRefreshing,
        isLicenseResetting,
        isComputeResourcesLoading,
    },
    computeResources,
}) => {
    const [dialogOpened, setDialogOpened] = React.useState(false);

    const toggleDialog = () => setDialogOpened(open => !open);
    const exist = licenseState.soluslabs_key !== '';

    React.useEffect(() => {
        if (!exist) {
            getLicense();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [exist]);

    const calculateProgress = (limit: number, actual: number): number => (limit && actual) ? (100 * actual) / limit : 0;
    const crStandardProgress = calculateProgress(licenseState.standard_cr_limit, computeResources.standard);
    const crMiniProgress = calculateProgress(licenseState.mini_cr_limit, computeResources.mini);
    const crMicroProgress = calculateProgress(licenseState.micro_cr_limit, computeResources.micro);

    const licenseStatus = () => {
        if (!exist) {
            return null;
        }

        if (licenseState.status === LicenseStatus.ACTIVE) {
            return (
                <Status intent="success">
                    <Translate content="license.statuses.active"/>
                </Status>
            );
        }

        return (
            <Status intent="danger">
                <Translate content="license.statuses.expired"/>
            </Status>
        );
    };

    return (
        <>
            <PageHeader
                isButtonShown={exist}
                title={
                    <StatusWrapper>
                        <Translate content="license.title"/>
                        {licenseStatus()}
                    </StatusWrapper>
                }
                buttonText="license.activateBtn"
                buttonIcon={ICONS.CARD_RIBBON}
                onButtonClick={toggleDialog}
            />
            <Loader isLoading={isLicenseLoading && !exist}>
                {!exist && (
                    <EmptyView
                        title="license.emptyView.title"
                        description="license.emptyView.description"
                        buttonText="license.emptyView.buttonText"
                        onButtonClick={toggleDialog}
                        icon={ICONS.CARD_RIBBON}
                    />
                )}
                {exist && (
                    <div>
                        <Table>
                            <tbody>
                                <tr>
                                    <td>
                                        <Translate content="license.table.standard" /><br/>
                                        <Text intent={INTENT_TYPE.MUTED} fontSize={SIZE.SM}>
                                            (<Translate content="license.table.standardLimit" />)
                                        </Text>
                                    </td>
                                    <td>
                                        <ProgressLoader isLoading={isComputeResourcesLoading || isLicenseRefreshing}>
                                            <Usage
                                                progress={crStandardProgress}
                                                title={
                                                    <Translate
                                                        content="license.crProgress"
                                                        params={{
                                                            current: computeResources.standard,
                                                            available: licenseState.standard_cr_limit,
                                                        }}
                                                    />
                                                }
                                            />
                                        </ProgressLoader>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Translate content="license.table.mini" /><br/>
                                        <Text intent={INTENT_TYPE.MUTED} fontSize={SIZE.SM}>
                                            (<Translate content="license.table.miniLimit" />)
                                        </Text>
                                    </td>
                                    <td>
                                        <ProgressLoader isLoading={isComputeResourcesLoading || isLicenseRefreshing}>
                                            <Usage
                                                progress={crMiniProgress}
                                                title={
                                                    <Translate
                                                        content="license.crProgress"
                                                        params={{
                                                            current: computeResources.mini,
                                                            available: licenseState.mini_cr_limit,
                                                        }}
                                                    />
                                                }
                                            />
                                        </ProgressLoader>
                                    </td>
                                </tr>
                                <tr>
                                    <td>
                                        <Translate content="license.table.micro" /><br/>
                                        <Text intent={INTENT_TYPE.MUTED} fontSize={SIZE.SM}>
                                            (<Translate content="license.table.microLimit" />)
                                        </Text>
                                    </td>
                                    <td>
                                        <ProgressLoader isLoading={isComputeResourcesLoading || isLicenseRefreshing}>
                                            <Usage
                                                progress={crMicroProgress}
                                                title={
                                                    <Translate
                                                        content="license.crProgress"
                                                        params={{
                                                            current: computeResources.micro,
                                                            available: licenseState.micro_cr_limit,
                                                        }}
                                                    />
                                                }
                                            />
                                        </ProgressLoader>
                                    </td>
                                </tr>
                                <tr>
                                    <td><Translate content="license.key"/></td>
                                    <td>
                                        <CopyText>
                                            {licenseState.ka_key ? `${licenseState.soluslabs_key} (${licenseState.ka_key})` : licenseState.soluslabs_key}
                                        </CopyText>
                                    </td>
                                </tr>
                                {licenseState.expiration_date !== '0000-00-00' && (
                                    <tr>
                                        <td><Translate content="license.expirationDate"/></td>
                                        <td>{licenseState.expiration_date}</td>
                                    </tr>
                                )}
                            </tbody>
                        </Table>
                        <ExtendedStatusMessageActions>
                            <Button
                                isLoading={isLicenseRefreshing}
                                icon={ICONS.REFRESH}
                                onClick={refreshLicense}
                                disabled={isLicenseResetting}
                            >
                                <Translate content="license.refreshBtn" />
                            </Button>
                            <ButtonWithConfirmation
                                confirmationButtonText={<Translate content="license.removeConfirmation.btn" />}
                                isLoading={isLicenseResetting}
                                translations={{
                                    title: <Translate content="license.removeConfirmation.btn" />,
                                    text: <Translate content="license.removeConfirmation.text" />,
                                    button: <Translate content="license.removeConfirmation.btn" />,
                                }}
                                withStyledButton={false}
                                confirmationButtonGhost={false}
                                handleConfirm={resetLicense}
                                icon={ICONS.RECYCLE}
                                placement="top"
                                disabled={isLicenseRefreshing}
                            />
                        </ExtendedStatusMessageActions>
                        <ComputeResourcesForm />
                    </div>
                )}
            </Loader>
            <Dialog
                heading={<Translate content="license.form.title" />}
                closeHandler={toggleDialog}
                isOpen={dialogOpened}
                size="xs"
            >
                <LicenseActivationForm onSubmit={toggleDialog} />
            </Dialog>
        </>
    );
};

const ProgressLoader: React.FC<React.PropsWithChildren<IProgressLoaderProps>> = ({ children, isLoading }) => (
    <ProgressLoaderWrapper>
        <Loader isLoading={isLoading} center={false} indicatorSize={'15px'}>
            {children}
        </Loader>
    </ProgressLoaderWrapper>
);

const mapStateToProps = (state: RootState) => ({
    licenseState: state.solusVMLicense.item.state,
    computeResources: {
        standard: state.solusVMLicense.computeResources.standard.length,
        mini: state.solusVMLicense.computeResources.mini.length,
        micro: state.solusVMLicense.computeResources.micro.length,
    },
    loadingFlags: {
        isLicenseLoading: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_ITEM),
        isLicenseRefreshing: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_REFRESH),
        isLicenseResetting: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_RESET),
        isComputeResourcesLoading: state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_COMPUTE_RESOURCES)
            || state.app.loadingFlags.has(LOADING_FLAGS.SOLUSVM_LICENSE_ASSIGN_COMPUTE_RESOURCES),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    licenseActions: bindActionCreators(licenseActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SolusVMLicense);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components';
import {
    ErrorMessage,
    Footer,
} from 'client/project/containers/projectServer/ProjectServerCreate/Styles';
import {
    CUSTOM_PLAN,
    PlanList,
} from 'common/components/plan/PlanList';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { SERVER_TABS } from 'common/components/ServerTabs/constants/tests';
import { loadPlansOnScroll } from 'common/modules/plan/actions';
import PlanPrice from 'client/common/components/PlanPrice/PlanPrice';
import BackupCard from 'common/containers/BackupCard/BackupCard';
import ConfirmationDialog from 'common/components/ConfirmationDialog/ConfirmationDialog';
import {
    Grid,
    GridCol,
    Checkbox,
    Item,
    Text,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import {
    IPlanListRequest,
    IPlanResponse,
    isLimitExceeded,
} from 'common/api/resources/Plan';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    ComputeResourceVmStatus,
    ComputeResourceVmCustomPlan,
    IVmResizeRequest,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import {
    CheckBoxText,
    CheckBoxWrapper,
    Container,
    Paragraph,
} from 'common/components/ServerTabs/ResizeTab/Styles';
import { HTTP_CODES } from 'common/api/constants';
import { transformErrors } from 'common/validator';
import { CardWithSwitchContainer } from 'common/components/CardWithSwitch/Styles';
import { FIELDS } from 'common/components/plan/PlanForm';
import { filterPlanFields } from 'common/helpers/PlanFields';
import { ConfirmationDescription } from 'common/components/ConfirmationDialog/Styles';
import { IProjectResponse } from 'common/api/resources/Project';

export interface IResizeTabProps {
    hasNextPage: boolean;
    hidePlanName: boolean;
    getPlans: (request?: IPlanListRequest) => void;
    resize: (vmId: number, data: IVmResizeRequest) => void;
    plans: IPlanResponse[];
    loadOnScroll: ReturnType<typeof loadPlansOnScroll>;
    isLoading: boolean;
    isResizing: boolean;
    server: IVmResponse;
    project: IProjectResponse;
    withPriceInfo: boolean;
    canManageBackups: boolean;
    canCustomizePlan?: boolean;
}

export const ResizeTab: React.FC<IResizeTabProps> = ({
    hasNextPage,
    loadOnScroll,
    hidePlanName,
    isResizing,
    isLoading,
    getPlans,
    server,
    plans,
    project,
    resize,
    withPriceInfo,
    canManageBackups,
    canCustomizePlan,
}) => {
    const [planSelection, setPlanSelection] = React.useState<number | typeof CUSTOM_PLAN>(0);
    const [selectedPlan, setSelectedPlan] = React.useState<IPlanResponse>();
    const [customPlan, setCustomPlan] = React.useState<ComputeResourceVmCustomPlan>(server.plan);
    const [backupSettings, setBackupSettings] = React.useState(server.backup_settings);
    const shouldPreserveDisk = server.has_incremental_backups && backupSettings.enabled;
    const [preserveDisk, setPreserveDisk] = React.useState(shouldPreserveDisk);
    const [dialogOpened, setDialogOpened] = React.useState(false);
    const [dialogWarnings, setDialogWarnings] = React.useState<React.ReactNode[]>([]);
    const [formErrors, setFormErrors] = React.useState('');

    const additionalIpsCount = server.ip_addresses.ipv4.filter(item => !item.is_primary).length;
    const hasAdditionalIp = additionalIpsCount > 0;
    const fields = [
        FIELDS.IS_SNAPSHOTS_ENABLED,
        FIELDS.DISK,
        FIELDS.RAM,
        FIELDS.VCPU,
        FIELDS.VCPU_UNITS,
        FIELDS.VCPU_LIMIT,
        FIELDS.IO_PRIORITY,
        FIELDS.SWAP,
        FIELDS.IS_BACKUP_AVAILABLE,
        FIELDS.BACKUP_LIMIT,
        FIELDS.INCREMENTAL_BACKUP,
        FIELDS.IS_ADDITIONAL_IP_AVAILABLE,
        FIELDS.DISK_LIMITS,
        FIELDS.NETWORK_LIMITS,
        FIELDS.VZ_PARAMETERS,
    ];

    const translate = useTranslate();

    React.useLayoutEffect(() => {
        const { image_format, storage_type } = server.plan;

        if (server.id) {
            const filters = {
                storage_type,
                image_format,
                // increase plan size to filter
                // plans with the same size
                disk: [server.specifications.disk],
            };

            // Location is null when CR doesn't have any location
            if (server.location !== null) {
                filters['location_id'] = server.location!.id;
            }

            getPlans({
                filters,
                additional_ips_count: server.ip_addresses.ipv4.filter(item => !item.is_primary).length,
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getPlans, server.id]);

    React.useEffect(() => {
        if (server.has_incremental_backups) {
            setPreserveDisk(true);
        }
    }, [backupSettings.enabled, server.has_incremental_backups]);

    const handleCloseDialog = () => {
        setPlanSelection(0);
        setSelectedPlan(undefined);
        setDialogOpened(false);
    };

    const handleConfirmSelectedPlan = () => {
        setDialogOpened(false);

        if (!server.backup_settings.enabled) {
            return;
        }

        if ((planSelection === CUSTOM_PLAN && !customPlan?.is_backup_available)
            || (planSelection > 0 && !selectedPlan?.is_backup_available)
        ) {
            setBackupSettings({
                ...backupSettings,
                enabled: false,
            });
        }
    };

    const togglePreserveHdd = () => setPreserveDisk(isChecked => !isChecked);

    const handlePlanSelect = (plan: IPlanResponse) => {
        confirmSelection(plan);

        setSelectedPlan(plan);
        setPlanSelection(plan.id);
        setBackupSettings({
            ...backupSettings,
            limit: plan.limits.backups_number,
        });
    };

    const handleCustomizePlan = (plan: ComputeResourceVmCustomPlan) => {
        confirmSelection(plan);

        setPlanSelection(CUSTOM_PLAN);
        setCustomPlan(plan);
        setBackupSettings({
            ...backupSettings,
            limit: plan.limits.backups_number,
        });
    };

    const confirmSelection = (plan: IPlanResponse | ComputeResourceVmCustomPlan) => {
        const warnings = [];

        if (backupSettings.enabled && !plan?.is_backup_available) {
            warnings.push(canManageBackups
                ? (<Translate content="servers.tabs.resize.planDialog.perhapsBackupDescription" />)
                : (<Translate content="servers.tabs.resize.planDialog.backupDescription" />));
        }

        if (hasAdditionalIp && !plan?.is_additional_ips_available) {
            warnings.push(<Translate content="servers.tabs.resize.planDialog.additionalIpDescription" />);
        }

        const areNetworkLimitsExceeded = isLimitExceeded(server.usage.network.incoming.value, plan.limits.network_incoming_traffic) ||
            isLimitExceeded(server.usage.network.outgoing.value, plan.limits.network_outgoing_traffic) ||
            isLimitExceeded(server.usage.network.outgoing.value + server.usage.network.incoming.value, plan.limits.network_total_traffic);

        if (areNetworkLimitsExceeded) {
            warnings.push(<Translate content="servers.tabs.resize.planDialog.networkLimitsDescription" />);
        }

        const doesNewPlanHaveBackupLimit = !server.plan.limits.backups_number.is_enabled && plan.limits.backups_number.is_enabled;
        const isNewBackupsNumberLess = plan.limits.backups_number.is_enabled &&
            server.plan.limits.backups_number.is_enabled &&
            plan.limits.backups_number.limit < server.plan.limits.backups_number.limit;

        if (doesNewPlanHaveBackupLimit || isNewBackupsNumberLess) {
            warnings.push(<Translate content="servers.tabs.resize.planDialog.backupsNumberDescription" />);
        }

        setDialogWarnings(warnings);

        if (warnings.length > 0) {
            setDialogOpened(true);
        }
    };

    const handleResize = async () => {
        try {
            const data: IVmResizeRequest = {
                plan_id: selectedPlan ? selectedPlan.id : 0,
                custom_plan: customPlan,
                preserve_disk: preserveDisk,
                backup_settings: backupSettings,
            };

            if (selectedPlan) {
                delete data.custom_plan;
            } else {
                delete data.plan_id;
            }

            await resize(server.id, data);
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                setFormErrors(Object.values(transformErrors(e.response.data.errors)).join(' '));
            }
        }
    };

    const tooltip = (): React.ReactNode | undefined => {
        if (server.is_processing) {
            return (
                <Translate content="servers.tabs.resize.tooltips.activeTasksRunning" />
            );
        }

        if (!planSelection) {
            return (
                <Translate content="servers.tabs.resize.tooltips.selectPlan" />
            );
        }

        if (server.plan.id === selectedPlan?.id) {
            return (
                <Translate content="servers.tabs.resize.tooltips.samePlan" />
            );
        }

        if (
            (selectedPlan && server.specifications.disk > selectedPlan.params.disk)
            || (planSelection === CUSTOM_PLAN && server.specifications.disk > customPlan.params.disk)
        ) {
            return (
                <Translate content="servers.tabs.resize.tooltips.hddSizeExceed" />
            );
        }

        return undefined;
    };

    const mappedPlans = plans.map(plan => {
        if (preserveDisk) {
            return {
                ...plan,
                params: {
                    ...plan.params,
                    disk: server.specifications.disk,
                },
            };
        }

        return plan;
    });

    if (!plans.length && !canCustomizePlan) {
        return  (
            <Loader isLoading={isLoading}>
                <EmptyView
                    title="servers.tabs.resize.emptyView.title"
                    icon="arrows-outward"
                />
            </Loader>
        );
    }

    const buttonTranslationKey = withPriceInfo && server.project.token_pricing !== undefined ?
        'servers.tabs.resize.popover.buyButton' :
        'servers.tabs.resize.popover.button';

    const shouldResize = selectedPlan?.params.vcpu !== server.specifications.vcpu
        || selectedPlan?.params.ram !== server.specifications.ram
        || (!preserveDisk && selectedPlan?.params.disk > server.specifications.disk);

    return (
        <Loader isLoading={isLoading && plans.length === 0}>
            <Container>
                <Grid>
                    <GridCol xs={12} sm={12} md={12} xl={8} lg={11}>
                        <Item
                            title={
                                <h3>
                                    <Translate content="servers.tabs.resize.infoCard.title" />
                                </h3>
                            }
                            view="card"
                        >
                            <Paragraph>
                                <Translate content="servers.tabs.resize.infoCard.p1" />
                            </Paragraph>
                            <Text intent={INTENT_TYPE.DANGER}>
                                <Translate content="servers.tabs.resize.infoCard.p2" />
                            </Text>
                            {plans.length > 0 && (
                                <CheckBoxWrapper title={canManageBackups
                                    ? translate('servers.tabs.resize.infoCard.backupInfo').toString()
                                    : undefined
                                }>
                                    <Checkbox
                                        checked={preserveDisk}
                                        onChange={togglePreserveHdd}
                                        disabled={shouldPreserveDisk}
                                    >
                                        <CheckBoxText>
                                            <h3>
                                                <Translate content="servers.tabs.resize.infoCard.cpuAndRamOnly.title" />
                                            </h3>
                                            <Translate content="servers.tabs.resize.infoCard.cpuAndRamOnly.description" />
                                        </CheckBoxText>
                                    </Checkbox>
                                </CheckBoxWrapper>
                            )}
                        </Item>
                    </GridCol>
                </Grid>
                <PlanList
                    hasNextPage={hasNextPage}
                    loadOnScroll={loadOnScroll}
                    hidePlanName={hidePlanName}
                    plans={mappedPlans}
                    project={project}
                    selectedPlan={planSelection}
                    onItemClicked={handlePlanSelect}
                    canCustomizePlan={canCustomizePlan}
                    onCustomizePlan={handleCustomizePlan}
                    customPlan={customPlan}
                    minDisk={server.plan.params.disk}
                    fields={filterPlanFields(fields, server.plan)}
                />
                {(canCustomizePlan || plans.find(item => item.is_backup_available)) && (
                    <CardWithSwitchContainer>
                        <BackupCard
                            backupSettings={backupSettings}
                            setBackupSettings={setBackupSettings}
                            isBackupSettingsUpdating={false}
                            nextScheduledBackupAt={null}
                            shouldConfirmOnDisable={server.backup_settings.enabled}
                            disabled={(planSelection > 0 && !selectedPlan?.is_backup_available)
                                || (planSelection === CUSTOM_PLAN && !customPlan?.is_backup_available)
                            }
                            backupPrice={selectedPlan ? selectedPlan.backup_price : 0}
                            backupsNumber={planSelection === CUSTOM_PLAN
                                ? customPlan?.limits.backups_number
                                : selectedPlan?.limits.backups_number
                            }
                        />
                    </CardWithSwitchContainer>
                )}
            </Container>
            <Footer>
                <ButtonWithConfirmation
                    disabled={!!tooltip()}
                    confirmationButtonGhost={false}
                    translations={{
                        text: shouldResize
                            ? (<Translate content="servers.tabs.resize.popover.resizeText" />)
                            : (<Translate content="servers.tabs.resize.popover.changePlanText" />),
                        button: (
                            <Translate content={buttonTranslationKey} />
                        ),
                        title: (
                            <Translate content="servers.tabs.resize.popover.title" />
                        ),
                        tooltip: tooltip(),
                    }}
                    handleConfirm={handleResize}
                    isLoading={isResizing || server.status === ComputeResourceVmStatus.RESIZING}
                    confirmationButtonText={<Translate content={buttonTranslationKey} />}
                    buttonIntent={INTENT_TYPE.PRIMARY}
                    buttonSize={SIZE.LG}
                    withStyledButton={true}
                    placement="top-right"
                    data-cy={SERVER_TABS.RESIZE_BUTTON}
                />
                {withPriceInfo && (
                    <PlanPrice
                        planId={selectedPlan ? selectedPlan.id : 0}
                        isBackupEnabled={backupSettings.enabled}
                        additionalIps={additionalIpsCount}
                    />
                )}
                {formErrors && <ErrorMessage>{formErrors}</ErrorMessage>}
            </Footer>
            <ConfirmationDialog
                isOpen={dialogOpened}
                isLoading={false}
                handleClick={handleConfirmSelectedPlan}
                onClose={handleCloseDialog}
                title={
                    <Translate content="servers.tabs.resize.planDialog.header" />
                }
                innerText={
                    <ConfirmationDescription>
                        {dialogWarnings.map((warning, index) => <span key={index}>{warning}</span>)}
                    </ConfirmationDescription>
                }
                data-cy={SERVER_TABS.SELECT_PLAN_CONFIRMATION}
            />
        </Loader>
    );
};

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import {
    resetPassword,
    unsetNewPassword,
    updateBootMode,
} from 'common/modules/computeResourceVm/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RootState } from 'client/core/store';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';
import { RescueTab } from 'common/components/ServerTabs/RescueTab/RescueTab';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

const mapStateToProps = (state: RootState) => {
    const server = state.project.servers.item;

    return {
        authId: state.auth.user.id,
        server,
        isResettingPassword: state.app.loadingFlags.has(LOADING_FLAGS.RESET_COMPUTE_RESOURCE_VM_PASSWORD) ||
            state.project.servers.item.status === ComputeResourceVmStatus.CHANGING_PASSWORD,
        isSwitchingBootMode: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_SWITCH_BOOT_MODE) ||
            state.project.servers.item.status === ComputeResourceVmStatus.UPDATING,
        canBootFromISOImage: hasPermission(state, PERMISSION_LIST.BOOT_FROM_ISO_IMAGES),
        canGetISOImage: hasPermission(
            state,
            PERMISSION_LIST.GET_ISO_IMAGES,
            PERMISSION_LIST.MANAGE_ALL_ISO_IMAGES,
            PERMISSION_LIST.MANAGE_OWNED_ISO_IMAGES
        ),
        canResetRootPassword: hasPermission(
            state,
            PERMISSION_LIST.MANAGE_SERVERS,
            PERMISSION_LIST.RESET_ROOT_PASSWORD
        ),
        canChangeBootMode: hasPermission(
            state,
            PERMISSION_LIST.MANAGE_SERVERS,
            PERMISSION_LIST.CHANGE_SERVERS_BOOT_MODE
        ),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetPassword: bindActionCreators(resetPassword, dispatch),
    unsetNewPassword: bindActionCreators(unsetNewPassword, dispatch),
    updateBootMode: bindActionCreators(updateBootMode, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RescueTab);

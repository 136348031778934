// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export enum INTENT_TYPE {
    DANGER = 'danger',
    WARNING = 'warning',
    MUTED = 'muted',
    INFO = 'info',
    SUCCESS = 'success',
    PRIMARY = 'primary',
    INACTIVE = 'inactive',
}

export enum SOCKET_CHANNELS {
    BACKUP = 'backup',
    MANAGEMENT_NODE_BACKUP = 'management-node-backup',
    STORAGE = 'storage',
    COMPUTE_RESOURCE = 'compute-resource',
    COMPUTE_RESOURCE_VM = 'compute-resource-vm',
    USER = 'user',
    TASK = 'task',
    USER_TASK = 'user-task',
    PROJECT = 'project',
    APP = 'app',
    UPDATE = 'update',
    ADDITIONAL_IP = 'additional-ip',
    VIRTUAL_SERVER = 'virtual-server',
    SOLUSVM_LICENSE = 'license',
    CLUSTER_IMPORT = 'cluster-import',
}

export enum SOCKET_EVENTS {
    BACKUP_UPDATED = '.backup.updated',
    MANAGEMENT_NODE_BACKUP_UPDATED = '.management-node-backup.updated',
    STORAGE_UPDATED = '.storage.updated',
    UPDATE_USER = '.user.updated',
    SNAPSHOT_UPDATED = '.snapshot.updated',
    UPDATE_COMPUTE_RESOURCE = '.compute-resource.updated',
    UPDATE_COMPUTE_RESOURCE_VM = '.compute-resource-vm.updated',
    UPDATE_DISK_USAGE_COMPUTE_RESOURCE_VM = '.compute-resource-vm.disk-usage-update',
    UPDATE_PASSWORD_COMPUTE_RESOURCE_VM = '.compute-resource-vm.password-updated',
    MOVE_COMPUTE_RESOURCE_VM = '.compute-resource-vm.moved',
    UPDATE_COMPUTE_RESOURCE_INSTALL_STEP = '.install-step.updated',
    TASK_UPDATED = '.task.updated',
    USER_TASK_UPDATED = '.user-task.updated',
    PROJECT_SERVER_UPDATED = '.project-server.updated',
    PROJECT_SERVER_PASSWORD_UPDATED = '.project-server.password-updated',
    VERSION_UPDATED = '.version.updated',
    UPDATE_DATA_UPDATED = '.update-data.updated',
    UPDATE_DATA_UPGRADE_RESULT = '.update-data.upgrade-result',
    ADDITIONAL_IP_ADD_SUCCESSED = '.virtual-server.additional_ip_add_successed',
    ADDITIONAL_IP_ADD_FAILED = '.virtual-server.additional_ip_add_failed',
    ADDITIONAL_IP_DELETE_SUCCESSED = '.virtual-server.additional_ip_delete_successed',
    ADDITIONAL_IP_DELETE_FAILED = '.virtual-server.additional_ip_delete_failed',
    PRIMARY_IP_CHANGE_SUCCESSED = '.virtual-server.primary_ip_change_successed',
    PRIMARY_IP_CHANGE_FAILED = '.virtual-server.primary_ip_change_failed',
    GUEST_TOOLS_INSTALL_SUCCEEDED = '.virtual-server.install_guest_tools_succeeded',
    GUEST_TOOLS_INSTALL_FAILED = '.virtual-server.install_guest_tools_failed',
    SOLUSVM_LICENSE_UPDATED = '.license.updated',
    CLUSTER_IMPORT_UPDATED = '.cluster-import.updated',
}

export const CLS_PREFIX = 'solusnxt-';

export enum PROGRESS_STATUS {
    NOT_STARTED = 'not_started',
    RUNNING = 'running',
    DONE = 'done',
    WARNING = 'warning',
    ERROR = 'error',
    CANCELED = 'canceled'
}

export enum SIZE {
    SM = 'sm',
    MD = 'md',
    LG = 'lg',
    XL = 'xl',
    XS = 'xs',
    FILL = 'fill'
}

export enum ITEM_ACTIONS {
    REMOVE = 'remove',
    EDIT = 'edit',
}

export const VNC_DIALOG_SIZE = {
    WIDTH: 780,
    HEIGHT: 463,
};

export enum KEYBOARD_EVENT_KEYS {
    ENTER = 'Enter',
}

export enum VIEW_TYPE {
    CARD = 'CARD',
    LIST = 'LIST',
}

export enum ITEM_VIEW {
    CARD = 'card',
}

export enum OS_TYPES {
    LINUX = 'Linux',
    WINDOWS = 'Windows',
}

export const CONFIRMATION_DIALOG = {
    OK_BUTTON: 'confirmationDialog-ok',
};

export enum ICONS {
    ARROW_RIGHT_IN = 'arrow-right-in',
    ARROW_TRAY_DOWN = 'arrow-down-tray',
    ARROW_TRAY_OUT = 'arrow-right-out',
    GEAR = 'gear',
    RESOURCE = 'resource',
    CARD_RIBBON = 'card-ribbon',
    CHECK_MARK_CIRCLE_FILLED = 'check-mark-circle-filled',
    TASKS = 'check-list',
    CD = 'cd',
    IP_ADDRESSES = 'ip-addresses',
    QUESTION = 'question-mark-circle',
    BACKUP = 'backup',
    MAIL = 'mail',
    PLAN = 'plans',
    PROJECT = 'projects',
    ADD = 'add',
    EDIT = 'edit',
    DELETE = 'delete',
    EYE_CLOSED = 'eye-closed',
    EYE = 'eye',
    EXCLAMATION_MARK_CIRCLE = 'exclamation-mark-circle',
    REFRESH = 'refresh',
    USER = 'user',
    PLANS = 'plans',
    USERS = 'users',
    FAILED = 'failed',
    OK = 'ok',
    CANCEL = 'cancel',
    SETTINGS = 'settings',
    STORAGE = 'storage',
    SERVERS = 'servers',
    DASHBOARD = 'dashboard',
    PROJECTS = 'projects',
    CLOSE = 'close',
    CLOCK = 'clock',
    CROSS_MARK = 'cross-mark',
    MENU = 'menu',
    IP = 'ip',
    PLUS = 'plus',
    CONSOLE_FILLED = 'console-filled',
    PENCIL = 'pencil',
    POWER = 'power',
    LOADER = 'loader',
    REBOOT = 'reboot',
    RELOAD = 'reload',
    REMOVE = 'remove',
    OPEN = 'open',
    OWNER = 'owner',
    RIBBON = 'ribbon',
    TOOLS = 'tools',
    POINT = 'point',
    CHEVRON_DOWN = 'chevron-down',
    MINUS = 'minus',
    KEY = 'key',
    STAR_STROKE = 'star-stroke',
    START = 'start',
    START_CIRCLE = 'start-circle',
    STOP_CIRCLE = 'stop-circle',
    SEARCH = 'search',
    COPY = 'copy-indicator',
    LOCATION = 'location',
    GLOBE = 'globe',
    UNION = 'union',
    PACKAGE = 'package',
    CREDIT_CARD = 'credit-card',
    SUPPORT = 'support',
    SMILE = 'emoticon-smile',
    ERROR = 'error',
    HEXAGONS = 'hexagons',
    DND = 'dnd',
    BELL = 'bell',
    CALENDAR_CLOCK = 'calendar-clock',
    MEMORY = 'memory',
    CPU = 'cpu',
    PIN = 'pin',
    CHECK_MARK = 'check-mark',
    RESET = 'reset',
    SLIDERS = 'sliders',
    RECYCLE = 'recycle',
    TRANSFER = 'transfer',
    TRIANGLE_EXCLAMATION_MARK_FILLED = 'triangle-exclamation-mark-filled',
    TRIANGLE_EXCLAMATION_MARK = 'triangle-exclamation-mark',
    INFO_CIRCLE = 'info-circle',
    PENCIL_DIALOG = 'pencil-dialog',
    NET = 'net',
    BOX_DIAGONAL_TOP_IN = 'box-diagonal-top-in',
    WEB = 'web',
    HARD_DRIVE = 'hard-drive',
    LOCK_CLOSED_FILLED = 'lock-closed-filled',
    SHIELD = 'shield',
    SHIELD_CHAIN = 'shield-chain',
}

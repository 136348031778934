// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as settingsActions from 'common/modules/settings/actions';
import { initialItemState } from 'admin/limitGroup/reducer';
import {
    BillingType,
    ComputeResourcesBalanceStrategy,
    DNSType,
    FEATURE,
    INotificationSettings,
    ISettingsResponse,
    LicenseModel,
    UPDATE_METHOD,
} from 'common/api/resources/Settings';
import { ROLE_CLIENT } from 'common/api/resources/Role';
import { DiskCacheMode } from 'common/api/resources/ComputeResourceVm';
import { ScheduleType } from 'common/api/resources/model';
import { Unit } from 'common/helpers/units';

export type SettingsAction = ActionType<typeof settingsActions>;
export type SettingsState = ISettingsResponse;

export const initialNotificationSettingsState: INotificationSettings =  {
    enabled: true,
    override_templates: { 'en_US': false },
    subject_templates: { 'en_US': '' },
    body_templates: { 'en_US': '' },
};

export const initialState: SettingsState = {
    limit_group: { ...initialItemState },
    registration: {
        role: ROLE_CLIENT,
    },
    hostname: '',
    send_statistic: true,
    theme: {
        primary_color: '#28AADE',
        secondary_color: '#FFFFFF',
        brand_name: '',
        logo: '',
        favicon: '',
        terms_and_conditions_url: '',
    },
    mail: {
        send_test_mail: false,
        test_mail: '',
        encryption: true,
        from_email: '',
        from_name: '',
        host: '',
        password: '',
        port: 0,
        username: '',
    },
    network_rules: {
        arp: true,
        smtp: true,
        icmp_reply: true,
        icmp: true,
        dhcp: true,
        cloud_init: true,
        portmapper: true,
    },
    notifications: {
        server_create: { ...initialNotificationSettingsState },
        server_reset_password: { ...initialNotificationSettingsState },
        user_reset_password: { ...initialNotificationSettingsState },
        user_verify_email: { ...initialNotificationSettingsState },
        two_factor_auth_recovery_code: { ...initialNotificationSettingsState },
        project_user_invite: { ...initialNotificationSettingsState },
        project_user_left: { ...initialNotificationSettingsState },
        server_incoming_traffic_exceeded: { ...initialNotificationSettingsState },
        server_outgoing_traffic_exceeded: { ...initialNotificationSettingsState },
    },
    compute_resource: {
        rescue_iso_url: '',
        balance_strategy: ComputeResourcesBalanceStrategy.ROUND_ROBIN,
        vs_disk_cache_mode: DiskCacheMode.NONE,
        libvirt_xml_cpu: '<cpu mode=\'host-passthrough\' />',
    },
    non_existent_vms_remover: {
        enabled: false,
        interval: 1,
    },
    billing_integration: {
        type: BillingType.NULL,
        drivers: {
            [BillingType.WHMCS]: {
                url: '',
                token: '',
            },
        },
    },
    dns: {
        type: DNSType.NULL,
        server_hostname_template: '',
        reverse_dns_domain_template: '',
        register_fqdn_on_server_create: false,
        ttl: 0,
        drivers: {
            [DNSType.POWERDNS]: {
                host: '127.0.0.1',
                api_key: 'changeme',
                port: null,
            },
        },
    },
    update: {
        method: UPDATE_METHOD.MANUAL,
        channel: 'stable',
    },
    update_schedule: {
        scheduled_days: [],
        scheduled_time: '00:00',
    },
    update_channels: ['stable', 'mainline', 'testing'],
    features: {
        [FEATURE.HIDE_PLAN_SECTION]: false,
        [FEATURE.HIDE_LOCATION_SECTION]: false,
        [FEATURE.HIDE_PLAN_NAME]: false,
        [FEATURE.HIDE_USER_DATA]: true,
        [FEATURE.ALLOW_REGISTRATION]: false,
        [FEATURE.ALLOW_PASSWORD_RECOVERY]: true,
        [FEATURE.HIDE_API_DOCUMENTATION_LINK]: false,
    },
    socialite_providers: [],
    license: {
        model: LicenseModel.SOLUS_IO,
    },
    management_node_backup: {
        is_enabled: false,
        limit: {
            limit: 0,
            is_enabled: false,
            unit: Unit.UNITS,
        },
        schedule: {
            type: ScheduleType.DAILY,
            time: {
                hour: 0,
                minutes: 0,
            },
            days: [],
        },
        backup_node_id: null,
    },
    server_backup: {
        is_enabled: false,
        limit: {
            limit: 0,
            is_enabled: false,
            unit: Unit.UNITS,
        },
        is_incremental_backup_enabled: false,
        incremental_backups_limit: 0,
        schedule: {
            type: ScheduleType.DAILY,
            time: {
                hour: 0,
                minutes: 0,
            },
            days: [],
        },
        compute_resources: [],
        excluded_servers: [],
    },
    auth: {
        is_session_timeout_enabled: false,
        session_timeout: 60,
        single_session_only: false,
        allow_different_ips: true,
    },
};

export default (state: SettingsState = initialState, action: SettingsAction) => {
    switch (action.type) {
    case getType(settingsActions.setSettings): {
        return action.payload;
    }
    default:
        return state;
    }
};

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { initialUserState } from 'admin/user/reducer';
import { initialShortBackupNodeState } from 'admin/backupNode/reducer';
import {
    BackupCreationMethod,
    BackupRestoreUnavailableReason,
    BackupStatus,
    BackupType,
    IBackupResponse,
} from 'common/api/resources/Backup';
import { combineReducers } from 'redux';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as backupActions from 'common/modules/backup/actions';
import {
    addToPaginated,
    appendToPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import { initialShortComputeResourceVmState } from 'common/modules/computeResourceVm/reducers';

export interface IBackupReducer {
    list: IPaginateApiResponse<IBackupResponse[]>;
    nextScheduledDate: string;
}

export type BackupAction = ActionType<typeof backupActions>;
export type BackupState = Readonly<IBackupReducer>;

export const initialBackupState: IBackupResponse = {
    id: 0,
    creation_method: BackupCreationMethod.AUTO,
    status: BackupStatus.PENDING,
    size: 0,
    compute_resource_vm: { ...initialShortComputeResourceVmState },
    backup_node: { ...initialShortBackupNodeState },
    creator: { ...initialUserState },
    created_at: '',
    backup_fail_reason: '',
    is_deleting: false,
    disk: 0,
    type: BackupType.FULL,
    restore: {
        is_available: true,
        unavailable_reason: BackupRestoreUnavailableReason.NO_REASON,
    },
};

export default combineReducers<BackupState, BackupAction>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(backupActions.setList):
            return action.payload;
        case getType(backupActions.addItem):
            return addToPaginated(state, action.payload);
        case getType(backupActions.unsetList):
            return { ...paginateInitialState };
        case getType(backupActions.appendList):
            return appendToPaginated(state, action.payload);
        case getType(backupActions.updateItem):
            return updateInPaginated(state, action.payload);
        case getType(backupActions.updateItemProgress):
            return updateInPaginated(state, {
                id: action.payload.id,
                backup_progress: action.payload.progress,
            });
        case getType(backupActions.removeBackupItems):
            const backups = state.data.reduce((prev: IBackupResponse[], current: IBackupResponse) => {
                if (action.payload.includes(current.id)) {
                    return prev;
                }

                return [...prev, current];
            }, []);

            return {
                ...state,
                data: backups,
            };
        case getType(backupActions.setBackupItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    nextScheduledDate: (state = '', action) => {
        switch(action.type) {
        case getType(backupActions.setNextScheduledBackupDate):
            return action.payload;
        default:
            return state;
        }
    },
});

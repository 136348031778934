// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/vlan/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    IVlanListRequest,
    IVlanPatchRequest,
    IVlanRequest,
    IVlanResponse,
} from 'common/api/resources/Vlan/model';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { vlans } from 'common/api/resources/Vlan';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';

export const setVlanList = createCustomAction(
    types.SET_VLAN_LIST,
    (data: IPaginateApiResponse<IVlanResponse[]>) => ({ payload: data })
);
export const setVlanItem = createCustomAction(
    types.SET_VLAN_ITEM,
    (data: IVlanResponse) => ({ payload: data })
);

export const unsetVlanItem = createCustomAction(types.UNSET_VLAN_ITEM);

export const addVlanItem = createCustomAction(
    types.ADD_VLAN_ITEM,
    (data: IVlanResponse) => ({ payload: data })
);
export const updateVlanItem = createCustomAction(
    types.UPDATE_VLAN_ITEM,
    (data: IVlanResponse) => ({ payload: data })
);

export const setVlanItemIsDeleting = createCustomAction(
    types.SET_VLAN_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const setVlanItemIsLoading = createCustomAction(
    types.SET_VLAN_IS_LOADING,
    (id: number, isLoading: boolean) => ({ payload: { id, is_loading: isLoading } })
);

export const removeVlanItem = createCustomAction(
    types.REMOVE_VLAN_ITEM,
    (id: number) => ({ payload: id })
);

export const getVlans = (params?: IVlanListRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.VLAN_LIST));
    try {
        const result = await vlans.list(params);
        dispatch(setVlanList(result.data));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VLAN_LIST));
    }
};

export const getVlan = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.VLAN_ITEM));
    try {
        const result = await vlans.item(id);
        dispatch(setVlanItem(result.data.data));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VLAN_ITEM));
    }
};

export const createVlan = (data: IVlanRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.VLAN_ITEM_SAVE));
    try {
        const result = await vlans.create(data);
        dispatch(addVlanItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'vlan.toasts.saved')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VLAN_ITEM_SAVE));
    }
};

export const updateVlan = (id: number, data: IVlanRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.VLAN_ITEM_SAVE));
    try {
        const result = await vlans.update(id, data);
        dispatch(updateVlanItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'vlan.toasts.saved')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.VLAN_ITEM_SAVE));
    }
};

export const patchVlan = (id: number, data: IVlanPatchRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setVlanItemIsLoading(id, true));
    try {
        const result = await vlans.patch(id, data);
        dispatch(updateVlanItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'vlan.toasts.saved')(dispatch);
        return result;
    } finally {
        dispatch(setVlanItemIsLoading(id, false));
    }
};

export const removeVlan = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setVlanItemIsLoading(id, true));
    try {
        const result = await vlans.remove(id);
        dispatch(removeVlanItem(id));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'vlan.toasts.removed')(dispatch);
        return result;
    } finally {
        dispatch(setVlanItemIsLoading(id, false));
    }
};
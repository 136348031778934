// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    ProgressStep,
    Translate,
} from '@plesk/ui-library';
import { Dialog } from 'common/components/Dialog/Dialog';
import { TASK_STATUS } from 'common/api/resources/Task';

interface ITaskDialogsProps {
    isOpen: boolean;
    handleClose: () => void;
}

export type TaskDialogProps =
    ITaskDialogsProps &
    ReturnType<typeof mapStateToProps>;

const TaskDialog: React.FC<TaskDialogProps> = ({
    tasks: { item },
    isOpen,
    handleClose,
}) => {
    const renderStep = () => {
        if ([TASK_STATUS.FAILED, TASK_STATUS.DONE_WITH_ERRORS].includes(item.status as TASK_STATUS)) {
            return (
                <ProgressStep icon="gear" status="error">
                    {item.output}
                </ProgressStep>
            );
        }

        if (item.status === TASK_STATUS.DONE) {
            return (
                <ProgressStep icon="gear" status="done">
                    {item.output}
                </ProgressStep>
            );
        }

        return (<ProgressStep icon="gear" progress={item.progress} status={item.status} />);
    };

    return (
        <Dialog
            heading={<Translate
                content="task.dialogTitle"
                params={{ task : `${item.action} ${item.compute_resource ? `on ${item.compute_resource.host}` : ''}` }}
            />}
            closeHandler={handleClose}
            isOpen={isOpen}
            size="xs"
        >
            {renderStep()}
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    tasks: state.task,
});

export default connect(mapStateToProps)(TaskDialog);

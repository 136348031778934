// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createGlobalStyle } from 'styled-components';
import { ISettingsTheme } from 'common/api/resources/Settings';

interface IStyledTabsProps {
    theme: ISettingsTheme;
}

export const ThemedStyles = createGlobalStyle<IStyledTabsProps>`
   .pul-button--primary {
      background-color: ${props => props.theme.primary_color} !important;
      color: ${props => props.theme.secondary_color} !important;
   }
   
   .pul-input__input, .pul-textarea {  
      &:focus, &:active, &:hover {
        border-color: ${props => props.theme.primary_color} !important;
        box-shadow: none !important;
      }
   }
   
   .pul-button--ghost {
      color: ${props => props.theme.primary_color} !important;
   }
   
   a {
      color: ${props => props.theme.primary_color} !important;
   }

    .pul-layer .pul-drawer-header {
        background: ${props => props.theme.primary_color};
        box-shadow:  ${props => props.theme.secondary_color};
        border-radius: 2px;
        font-size: 24px;
        line-height: 32px;
        color: #FFFFFF;
    }
    
    .pul-action__icon {
      &:hover {
         color: ${props => props.theme.primary_color} !important;
      }
    }
   
    .pul-status--info:before {
      background-color: ${props => props.theme.primary_color} !important;
    }
`;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { ICommonState } from 'common/store';
import * as confirmIdentityActions from 'common/modules/auth/confirmIdentity/actions';
import { Dialog } from 'common/components/Dialog/Dialog';
import { Translate } from '@plesk/ui-library';
import { SIZE } from 'common/constants';
import { connect } from 'react-redux';
import ConfirmIdentityForm from 'common/modules/auth/containers/ConfirmIdentityForm/ConfirmIdentityForm';
import { clearInsecureAction } from 'common/modules/auth/confirmIdentity/insecureActionHelpers';

export type ConfirmIdentityDialogProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ConfirmIdentityDialog: React.FC<ConfirmIdentityDialogProps> = ({
    isOpened,
    confirmIdentityActions: {
        closeConfirmIdentityDialog,
    },
}) => {
    const closeHandler = () => {
        closeConfirmIdentityDialog();
        clearInsecureAction();
    };

    return (
        <Dialog
            heading={(
                <Translate content={'auth.confirmIdentity.dialog.title'}/>
            )}
            closeHandler={closeHandler}
            isOpen={isOpened}
            size={SIZE.XS}
        >
            <ConfirmIdentityForm onSubmit={closeConfirmIdentityDialog} />
        </Dialog>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    isOpened: state.confirmIdentity.isDialogOpened,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    confirmIdentityActions: bindActionCreators(confirmIdentityActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmIdentityDialog);
// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    BootMode,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { ICONS } from 'common/constants';

interface IServerBootModeIconProps {
    server: IVmResponse;
    width?: number;
}

export const ServerBootModeIcon: React.FC<IServerBootModeIconProps> = ({
    server,
    width = 12,
}) => {
    if (server.boot_mode === BootMode.DISK && server.settings.os_image.icon) {
        return (
            <img
                src={server.settings.os_image.icon}
                alt={server.settings.os_image.name}
                width={width}
            />
        );
    }

    if (server.boot_mode === BootMode.ISO_IMAGE && server.settings.iso_image?.icon) {
        return (
            <img
                src={server.settings.iso_image.icon}
                alt={server.settings.iso_image.name}
                width={width}
            />
        );
    }

    return (<img src={ICONS.CD} alt="" width={width}/>);
};

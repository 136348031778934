// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { PageSection } from 'common/components/PageHeader/Styles';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import WebhooksTable from 'admin/eventHandler/containers/WebhooksTable/WebhooksTable';
import { TEST } from 'admin/eventHandler/constants/tests';
import {
    EVENT_HANDLER_TABS,
    OPENED_DIALOG,
} from 'admin/eventHandler/constants';

export interface IEventHandlerProps extends RouteComponentProps {}

export const EventHandlers: React.FC<IEventHandlerProps> = ({
    history,
    location: {
        hash,
    },
}) => {
    if (!hash) {
        history.replace(EVENT_HANDLER_TABS.WEBHOOKS_TAB);
    }

    const tabs = Object.values(EVENT_HANDLER_TABS);

    const [openedDialog, setOpenedDialog] = React.useState(OPENED_DIALOG.NONE);

    const openDialog = (dialog: OPENED_DIALOG) => () => {
        setOpenedDialog(dialog);
    };

    const closeDialog = () => setOpenedDialog(OPENED_DIALOG.NONE);

    const handleTabSelect = (index: number) => history.replace(tabs[index]);

    const renderHeaderButton = () => {
        if (hash === EVENT_HANDLER_TABS.WEBHOOKS_TAB) {
            return (
                <Button
                    intent={INTENT_TYPE.PRIMARY}
                    size={SIZE.LG}
                    icon={ICONS.WEB}
                    onClick={openDialog(OPENED_DIALOG.WEBHOOKS)}
                    data-cy={TEST.WEBHOOKS.CREATE_BUTTON}
                >
                    <Translate content="eventHandler.webhooks.createBtn" />
                </Button>
            );
        }

        return null;
    };

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        <Translate content="eventHandler.title" />
                    </PageSection>
                )}
                actionButton={renderHeaderButton()}
            />
            <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                <TabList>
                    <Tab data-cy={TEST.TAB.WEBHOOKS}>
                        <Translate content="eventHandler.webhooks.title"/>
                    </Tab>
                </TabList>
                <TabPanel>
                    <WebhooksTable
                        openWebhooksDialog={openDialog(OPENED_DIALOG.WEBHOOKS)}
                        closeWebhooksDialog={closeDialog}
                        isDialogOpened={openedDialog === OPENED_DIALOG.WEBHOOKS}
                    />
                </TabPanel>
            </Tabs>
        </>
    );
};

export default withRouter(EventHandlers);

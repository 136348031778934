// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    IVmResponse,
    ComputeResourceVmStatus,
} from 'common/api/resources/ComputeResourceVm';
import { SERVER } from 'admin/computeResource/constants/tests';
import {
    Translate,
    Button,
    Icon,
    Menu,
    Dropdown,
    MenuItem,
    Text,
} from '@plesk/ui-library';
import { dataCySelector } from 'common/tests/selectors';
import { popupCenter } from 'common/helpers/popup';
import {
    ICONS,
    VNC_DIALOG_SIZE,
} from 'common/constants';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { COLORS } from 'admin/core/theme';
import {
    ActionsContainer,
    InlineActions,
} from 'admin/computeResourceVm/containers/ComputeResourceVmActions/Styles';
import MenuItemWithConfirmation from 'common/components/MenuItemWithConfirmation';
import ButtonWithInputConfirmation
    from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';
import MenuItemWithInputConfirmation from 'common/components/MenuItemWithInputConfirmation/MenuItemWithInputConfirmation';
import CopyText from 'common/containers/CopyText/CopyText';
import { RootState } from 'admin/core/store';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

interface IComputeResourceVmActionsProps {
    item: IVmResponse;
    iconSize: string;
    onUpdate: (id: number) => void;
    onDelete: (id: number) => void;
}

export type ComputeResourceVmActionsProps =
    IComputeResourceVmActionsProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceVmActions: React.FC<ComputeResourceVmActionsProps> = ({
    item,
    iconSize,
    computeResourceVmActions: {
        restartComputeResourceVm,
        startComputeResourceVm,
        stopComputeResourceVm,
    },
    onUpdate,
    onDelete,
    canStartServers,
    canStopServers,
    canRestartServers,
    canDeleteServers,
    canPowerOffServers,
    canOpenVncConsole,
}) => {
    const handleVNCOpen = () => {
        popupCenter(`/admin/vnc_client/${item.id}`, VNC_DIALOG_SIZE.WIDTH, VNC_DIALOG_SIZE.HEIGHT);
    };
    const handleRestart = () => restartComputeResourceVm(item.id, { force: false });
    const handleStart = () => startComputeResourceVm(item.id);
    const handleStop = () => stopComputeResourceVm(item.id, { force: false });
    const handlePowerOff = () => stopComputeResourceVm(item.id, { force: true });
    const handleUpdate = () => onUpdate(item.id);
    const handleDelete = () => onDelete(item.id);

    const isStarted = item.real_status === ComputeResourceVmStatus.STARTED;

    const actions = (
        <>
            {canStartServers && (
                <Button
                    ghost={true}
                    disabled={isStarted || item.is_suspended}
                    icon={<Icon name="start-circle" size={iconSize} />}
                    onClick={handleStart}
                    tooltip={<Translate content="computeResource.servers.start" />}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.START)}
                />
            )}
            {canOpenVncConsole && (
                <Button
                    ghost={true}
                    disabled={!isStarted || !item.settings.vnc_enabled}
                    icon={<Icon name="console-filled" size={iconSize} />}
                    onClick={handleVNCOpen}
                    tooltip={<Translate content="computeResource.servers.vnc" />}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.VNC)}
                />
            )}
            {canRestartServers && (
                <ButtonWithConfirmation
                    icon={<Icon name="reset" size={iconSize} />}
                    disabled={!isStarted}
                    translations={{
                        button: (
                            <Translate content="computeResource.servers.restartPopover.button" />
                        ),
                        title: (
                            <Translate content="computeResource.servers.restartPopover.title" />
                        ),
                        tooltip: (
                            <Translate content="computeResource.servers.restartPopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRestart}
                    buttonColor={COLORS.PRIMARY}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.RESTART)}
                />
            )}
            {canStopServers && (
                <ButtonWithConfirmation
                    icon={<Icon name="stop-circle" size={iconSize} />}
                    disabled={!isStarted}
                    translations={{
                        button: (
                            <Translate content="computeResource.servers.stopPopover.button" />
                        ),
                        title: (
                            <Translate content="computeResource.servers.stopPopover.title" />
                        ),
                        tooltip: (
                            <Translate content="computeResource.servers.stopPopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleStop}
                    buttonColor={COLORS.PRIMARY}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.STOP)}
                />
            )}
            {canPowerOffServers && (
                <ButtonWithConfirmation
                    icon={<Icon name="power" size={iconSize} />}
                    disabled={!isStarted}
                    translations={{
                        button: (
                            <Translate content="computeResource.servers.powerOffPopover.button" />
                        ),
                        title: (
                            <Translate content="computeResource.servers.powerOffPopover.title" />
                        ),
                        text: (
                            <Translate content="computeResource.servers.powerOffPopover.text" />
                        ),
                        tooltip: (
                            <Translate content="computeResource.servers.powerOffPopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handlePowerOff}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.SHUTDOWN)}
                />
            )}
            <Button
                ghost={true}
                icon={<Icon name={ICONS.PENCIL} size={iconSize} />}
                onClick={handleUpdate}
                tooltip={<Translate content="computeResource.servers.editBtn" />}
                data-cy={dataCySelector(item.id, SERVER.ACTIONS.EDIT)}
            />
            <ButtonWithInputConfirmation
                disabled={!canDeleteServers}
                isLoading={item.is_deleting}
                confirmation={item.name}
                icon={<Icon name={ICONS.RECYCLE} size={iconSize} />}
                translations={{
                    confirmationButton: (
                        <Translate content="computeResource.servers.buttonWithConfirmation.button" />
                    ),
                    title: (
                        <Translate content="computeResource.servers.buttonWithConfirmation.title" />
                    ),
                    text: (
                        <Translate
                            content='computeResource.servers.buttonWithConfirmation.text'
                            params={{ name: <CopyText isInline={true}><Text bold>{item.name}</Text></CopyText> }}
                        />
                    ),
                    tooltip: (canDeleteServers
                        ? <Translate content="computeResource.servers.buttonWithConfirmation.tooltip" />
                        : <Translate content="servers.delete.cannotDelete" />
                    ),
                    label: (
                        <Translate content="computeResource.servers.buttonWithConfirmation.label" />
                    ),
                }}
                handleConfirm={handleDelete}
                data-cy={dataCySelector(item.id, SERVER.ACTIONS.REMOVE)}
            />
        </>
    );

    const menu = (
        <Menu>
            {canStartServers && (
                <MenuItem
                    onClick={handleStart}
                    icon={ICONS.START_CIRCLE}
                    disabled={isStarted || item.is_suspended}
                >
                    <Translate content="computeResource.servers.start" />
                </MenuItem>
            )}
            {canOpenVncConsole && (
                <MenuItem
                    onClick={handleVNCOpen}
                    icon="console-filled"
                    disabled={!isStarted || !item.settings.vnc_enabled}
                >
                    <Translate content="computeResource.servers.vnc" />
                </MenuItem>
            )}
            {canRestartServers && (
                <MenuItemWithConfirmation
                    icon={<Icon name={ICONS.RESET} />}
                    disabled={!isStarted}
                    translations={{
                        button: (
                            <Translate content="computeResource.servers.restartPopover.button" />
                        ),
                        title: (
                            <Translate content="computeResource.servers.restartPopover.title" />
                        ),
                    }}
                    menuItemText={<Translate content="computeResource.servers.restartPopover.button" />}
                    handleConfirm={handleRestart}
                    buttonColor={COLORS.PRIMARY}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.RESTART)}
                />
            )}
            {canStopServers && (
                <MenuItemWithConfirmation
                    icon={<Icon name={ICONS.STOP_CIRCLE} />}
                    disabled={!isStarted}
                    translations={{
                        button: (
                            <Translate content="computeResource.servers.stopPopover.button" />
                        ),
                        title: (
                            <Translate content="computeResource.servers.stopPopover.title" />
                        ),
                    }}
                    menuItemText={<Translate content="computeResource.servers.stopPopover.button" />}
                    handleConfirm={handleStop}
                    buttonColor={COLORS.PRIMARY}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.STOP)}
                />
            )}
            {canPowerOffServers && (
                <MenuItemWithConfirmation
                    icon={<Icon name={ICONS.POWER} />}
                    disabled={!isStarted}
                    translations={{
                        button: (
                            <Translate content="computeResource.servers.powerOffPopover.button" />
                        ),
                        title: (
                            <Translate content="computeResource.servers.powerOffPopover.title" />
                        ),
                    }}
                    menuItemText={<Translate content="computeResource.servers.powerOffPopover.button" />}
                    handleConfirm={handlePowerOff}
                    data-cy={dataCySelector(item.id, SERVER.ACTIONS.SHUTDOWN)}
                />
            )}
            <MenuItem onClick={handleUpdate} icon={ICONS.PENCIL}>
                <Translate content="computeResource.servers.editBtn" />
            </MenuItem>
            <MenuItemWithInputConfirmation
                isLoading={item.is_deleting}
                icon={<Icon name={ICONS.RECYCLE} />}
                disabled={!canDeleteServers}
                confirmation={item.name}
                translations={{
                    button: (
                        <Translate content="computeResource.servers.buttonWithConfirmation.button" />
                    ),
                    title: (
                        <Translate content="computeResource.servers.buttonWithConfirmation.title" />
                    ),
                    text: (
                        <Translate
                            content='computeResource.servers.buttonWithConfirmation.text'
                            params={{ name: <Text bold>{item.name}</Text> }}
                        />
                    ),
                    label: (
                        <Translate content="computeResource.servers.buttonWithConfirmation.label" />
                    ),
                }}
                menuItemText={<Translate content="computeResource.servers.buttonWithConfirmation.button" />}
                handleConfirm={handleDelete}
                data-cy={dataCySelector(item.id, SERVER.ACTIONS.REMOVE)}
            />
        </Menu>
    );

    return (
        <ActionsContainer>
            <InlineActions>
                {actions}
            </InlineActions>
            <Dropdown
                menu={menu}
            >
                <Button
                    ghost={true}
                    icon="kebab"
                />
            </Dropdown>
        </ActionsContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    canStartServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS) || (
        state.auth.user.id === state.computeResourceVm.item.user.id
        && hasPermission(state, PERMISSION_LIST.START_SERVERS)
    ),
    canStopServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS) || (
        state.auth.user.id === state.computeResourceVm.item.user.id
        && hasPermission(state, PERMISSION_LIST.STOP_SERVERS)
    ),
    canRestartServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS) || (
        state.auth.user.id === state.computeResourceVm.item.user.id
        && hasPermission(state, PERMISSION_LIST.RESTART_SERVERS)
    ),
    canDeleteServers: hasPermission(state, PERMISSION_LIST.DELETE_SERVERS) && (
        state.auth.user.id === state.computeResourceVm.item.user.id
        || hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS)
    ),
    canPowerOffServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS) || (
        state.auth.user.id === state.computeResourceVm.item.user.id
        && hasPermission(state, PERMISSION_LIST.POWER_OFF_SERVERS)
    ),
    canOpenVncConsole: hasPermission(state, PERMISSION_LIST.OPEN_VNC_CONSOLE, PERMISSION_LIST.MANAGE_SERVERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceVmActions);

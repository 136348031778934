// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import * as planActions from 'common/modules/plan/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    IPlanResponse,
    IPlanCreateRequest,
    PlanUpdateRequest,
} from 'common/api/resources/Plan';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Action,
    Icon,
    Switch,
    Toolbar,
    ToolbarGroup,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import List from 'common/components/List/List';
import { STORAGE_TYPES_TRANSLATION_MAP } from 'common/api/resources/StorageType';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { SetAsDefaultAction } from 'admin/common/components/SetAsDefaultAction';
import { getNewPosition } from 'common/helpers/position';
import { IListReorderedItem } from 'common/reducers/list';
import { PLANS } from 'admin/plan/constants/tests';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { VIRTUALIZATION_TYPE_TRANSLATION_MAP } from 'common/api/resources/ComputeResource';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import PlanForm,
{ FIELDS } from 'common/components/plan/PlanForm';
import { initialKVMPlanRequest } from 'common/modules/plan/reducer';
import { SIZE } from 'common/constants';
import { filterPlanFields } from 'common/helpers/PlanFields';
import { CancelTokenSource } from 'axios';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import { ItemPerPageOption } from 'common/api/resources/Request/request';

export type PlanProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="plan.list.id" />,
}, {
    key: 'colName',
    title: <Translate content="plan.list.name" />,
    cellProps: {
        className: 'cell-bold cell-default',
    },
}, {
    key: 'colVirtualizationType',
    title: <Translate content="plan.list.virtualizationType" />,
}, {
    key: 'colStorageType',
    title: <Translate content="plan.list.storageType" />,
}, {
    key: 'colImageFormat',
    title: <Translate content="plan.list.imageFormat" />,
}, {
    key: 'colCpu',
    title: <Translate content="plan.list.cpu" />,
}, {
    key: 'colDisk',
    title: <Translate content="plan.list.disk" />,
}, {
    key: 'colRam',
    title: <Translate content="plan.list.ram" />,
}, {
    width: '1%',
    key: 'colSnapshots',
    title: <Translate content="plan.list.snapshots" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, {
    width: '1%',
    key: 'colVisibility',
    title: <Translate content="plan.list.visibility" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

enum OPENED_DRAWER {
    CREATE,
    EDIT,
    NONE,
}

export const Plan: React.FC<PlanProps> = ({
    isLoadingList,
    isSavingItem,
    isDeletingItem,
    planActions: {
        getPlans,
        updateDefaultPlan,
        updatePlanVisibility,
        updatePlanPosition,
        removePlan,
        removePlans,
        getPlan,
        createPlan,
        updatePlan,
    },
    list,
    meta,
    metaList,
    planToEdit,
    reorderable,
}) => {
    const [drawer, setDrawer] = React.useState(OPENED_DRAWER.NONE);
    const [selection, setSelection] = React.useState<string[]>([]);
    const [blockDefault, setBlockDefault] = React.useState(false);
    const [blockVisibility, setBlockVisibility] = React.useState(false);

    const removeItems = React.useCallback(async (ids: number | number[]) => {
        try {
            Array.isArray(ids) ? await removePlans(ids) : await removePlan(ids);
            setSelection([]);
        } catch (e) {
            throw e;
        }
        reloadListData(metaList, loadPaginated);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemoveSelected = () => removeItems(selection.map(id => parseInt(id, 10)));
    const handleCreate = () => setDrawer(OPENED_DRAWER.CREATE);
    const handleClose = () => setDrawer(OPENED_DRAWER.NONE);
    const handleRemove = (id: number) => async () => await removeItems(id);
    const handleSetDefault = (item: IPlanResponse) => async () => {
        if (blockDefault) {
            return;
        }

        try {
            setBlockDefault(true);
            await updateDefaultPlan(item.id, { is_default: !item.is_default });
        } finally {
            setBlockDefault(false);
        }
    };
    const handleEdit = (id: number) => async () => {
        await getPlan(id);
        setDrawer(OPENED_DRAWER.EDIT);
    };
    const handleToggleVisibility = (plan: IPlanResponse) => async () => {
        if (blockVisibility && plan.is_loading) {
            return;
        }

        try {
            setBlockVisibility(true);
            await updatePlanVisibility(plan.id, { is_visible: !plan.is_visible });
        } finally {
            setBlockVisibility(false);
        }
    };
    const handleDragEnd = async (reorderData: IListReorderedItem) => {
        if (list[reorderData.oldIndex] !== undefined) {
            const newPos = getNewPosition(reorderData.oldIndex, reorderData.newIndex, list);

            await updatePlanPosition(list[reorderData.oldIndex].id, { position: newPos });
        }
    };

    const data = React.useMemo(() => list.map((plan) => {
        const actionsEl = (
            <StyledActions>
                <SetAsDefaultAction
                    title={<Translate content="plan.tooltip.setAsDefault"/>}
                    isDefault={plan.is_default}
                    isLoading={plan.is_loading && blockDefault}
                    onClick={handleSetDefault(plan)}
                    data-cy={dataCySelector(plan.id, PLANS.DEFAULT)}
                />
                <Tooltip title={<Translate content="plan.tooltip.edit"/>}>
                    <Action
                        icon={<Icon name="pencil" />}
                        className="action-icon"
                        onClick={handleEdit(plan.id)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={plan.is_deleting}
                    translations={{
                        button: <Translate content="plan.buttonWithConfirmation.button" />,
                        title:  <Translate content="plan.buttonWithConfirmation.title" />,
                        tooltip: <Translate content="plan.tooltip.remove" />,
                    }}
                    handleConfirm={handleRemove(plan.id)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            colId: plan.id,
            colName: plan.name,
            colVirtualizationType: VIRTUALIZATION_TYPE_TRANSLATION_MAP[plan.virtualization_type],
            colStorageType: STORAGE_TYPES_TRANSLATION_MAP[plan.storage_type],
            colImageFormat: plan.image_format,
            colCpu: plan.params.vcpu,
            colDisk: plan.params.disk,
            colRam: convertToDataUnit(plan.params.ram, DataUnit.MiB),
            colActions: actionsEl,
            colSnapshots:  plan.is_snapshots_enabled && (
                <Icon name="check-mark" />
            ),
            colVisibility:  (
                <Switch
                    checked={plan.is_visible}
                    onChange={handleToggleVisibility(plan)}
                    loading={plan.is_loading && blockVisibility}
                    data-cy={dataCySelector(plan.id, PLANS.VISIBILITY)}
                />
            ),
            key: plan.id.toString(),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [list, isSavingItem, blockDefault, blockVisibility, isDeletingItem]);

    const loadPaginated = React.useCallback(
        (page: number, cancelToken?: CancelTokenSource) => getPlans({ page }, cancelToken),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        []
    );

    const isFirstLoading = useRequestCancellationEffect(token => getPlans({}, token), []);

    const handlePerPage = (perPage: ItemPerPageOption) => getPlans({ per_page: perPage === 'all' ? -1 : perPage });

    return (
        <>
            <PageHeader
                title={<Translate content="plan.title"/>}
                buttonText="plan.addBtn"
                buttonIcon="plans"
                onButtonClick={handleCreate}
                isButtonShown={data.length > 0}
            />
            <List
                onItemsPerPageChange={handlePerPage}
                isLoading={isLoadingList}
                meta={meta}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                emptyView={
                    <EmptyView
                        title="plan.emptyView.title"
                        description="plan.emptyView.description"
                        buttonText="plan.emptyView.buttonText"
                        onButtonClick={handleCreate}
                        icon="plans"
                    />
                }
                toolbar={
                    <Toolbar data-cy={PLANS.TOOLBAR}>
                        <ToolbarGroup title="actions">
                            <ButtonWithConfirmation
                                data-cy={PLANS.BATCH_DELETE_BTN}
                                disabled={!selection.length}
                                isLoading={isDeletingItem}
                                confirmationButtonGhost={false}
                                confirmationButtonText={<Translate content="plan.removeBtn" />}
                                translations={{
                                    button: <Translate content="plan.batchButtonWithConfirmation.button" />,
                                    title:  <Translate content="plan.batchButtonWithConfirmation.title" />,
                                    tooltip: <Translate content="plan.batchButtonWithConfirmation.tooltip" />,
                                }}
                                handleConfirm={handleRemoveSelected}
                                icon="recycle"
                            />
                        </ToolbarGroup>
                    </Toolbar>
                }
                data-cy={PLANS.TABLE}
                columns={columns}
                data={data}
                selection={selection}
                onSelectionChange={setSelection}
                onReorderEnd={handleDragEnd}
                reorderable={reorderable}
            />
            <Dialog
                heading={<Translate content="plan.actionDialog.titleAdd" />}
                closeHandler={handleClose}
                isOpen={drawer === OPENED_DRAWER.CREATE}
                size="xs"
            >
                <PlanForm
                    fields={Object.values(FIELDS)}
                    onSubmit={async (plan) => {
                        await createPlan(plan as IPlanCreateRequest);
                        handleClose();
                    }}
                    initialState={{
                        ...initialKVMPlanRequest,
                        available_locations: [],
                        available_os_image_versions: [],
                        available_applications: [],
                    }}
                />
            </Dialog>
            <Dialog
                heading={<Translate content="plan.actionDialog.titleEdit" />}
                closeHandler={handleClose}
                isOpen={drawer === OPENED_DRAWER.EDIT}
                size={SIZE.XS}
            >
                <PlanForm
                    fields={filterPlanFields([
                        FIELDS.NAME,
                        FIELDS.IS_VISIBLE,
                        FIELDS.IS_SNAPSHOTS_ENABLED,
                        FIELDS.TOKENS_COLUMNS,
                        FIELDS.AVAILABLE_LOCATIONS,
                        FIELDS.AVAILABLE_OS_IMAGE_VERSIONS,
                        FIELDS.AVAILABLE_APPLICATIONS,
                        FIELDS.IS_BACKUP_AVAILABLE,
                        FIELDS.BACKUP_PRICE,
                        FIELDS.BACKUP_LIMIT,
                        FIELDS.INCREMENTAL_BACKUP,
                        FIELDS.IS_ADDITIONAL_IP_AVAILABLE,
                        FIELDS.ADDITIONAL_IP_TOKENS,
                        FIELDS.ISO_IMAGE_TOKENS,
                        FIELDS.NETWORK_LIMITS,
                        FIELDS.VZ_PARAMETERS,
                    ], planToEdit)}
                    onSubmit={async (plan) => {
                        await updatePlan(planToEdit.id, plan as PlanUpdateRequest);
                        handleClose();
                    }}
                    initialState={planToEdit}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    planToEdit: state.plan.item,
    list: state.plan.list.data.sort((a, b) => a.position - b.position),
    meta: state.plan.list.meta,
    metaList: state.plan.list,
    reorderable: true,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST),
    isSavingItem: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_PLAN_ITEM),
    isDeletingItem: state.app.loadingFlags.has(LOADING_FLAGS.REMOVE_PLAN_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    planActions: bindActionCreators(planActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Plan);

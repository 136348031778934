// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    CloseLink,
    StyledDialog,
} from 'common/components/Dialog/Styles';
import {
    Button,
    Heading,
} from '@plesk/ui-library';

interface IDialogProps {
    closeHandler: () => void;
    isOpen: boolean;
    size: string;
    heading: React.ReactNode | string;
}

export const Dialog: React.FC<React.PropsWithChildren<IDialogProps>> = ({
    children,
    closeHandler,
    heading,
    ...rest
}) => (
    <StyledDialog closable={false} {...rest}>
        <CloseLink>
            <Button onClick={closeHandler} ghost={true} icon="cross-mark" />
        </CloseLink>
        <Heading level={3}>
            {heading}
        </Heading>
        {children}
    </StyledDialog>
);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    FC,
    ComponentProps,
} from 'react';
import {
    FormField,
    IFormField,
} from '@plesk/ui-library';
import { IFormProps } from 'common/components/Form/types';
import TagsInput from 'common/components/Form/FormFieldTags/TagsInput';

export interface IFormFieldTagsProps extends IFormField {
    placeholder?: string;
    disabled?: boolean;
    autoFocus?: boolean;
    delimiters?: string[];
    inputProps?: Partial<ComponentProps<typeof TagsInput>>;
}

const FormFieldTags: FC<IFormFieldTagsProps> = ({ placeholder, disabled, autoFocus, delimiters, inputProps, ...props }) => (
    <FormField {...props}>
        {({ getValue, setValue, getId, getName }: IFormProps<string[]|null>) => (
            <TagsInput
                inputId={getId()}
                name={getName()}
                value={getValue()}
                onChange={setValue}
                placeholder={placeholder}
                disabled={disabled}
                autoFocus={autoFocus}
                delimiters={delimiters}
                {...inputProps}
            />
        )}
    </FormField>
);

export default FormFieldTags;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { IPlanResponse } from 'common/api/resources/Plan';
import { COLORS } from 'common/theme';
import {
    Icon,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { ItemPlan } from 'common/components/plan/Styles';
import { ICONS } from 'common/constants';
import Action from 'common/components/Action/Action';
import { PlanCardDescription } from 'common/components/plan/components/PlanCardDescription';
import { dataCySelector } from 'common/tests/selectors';
import { CUSTOMIZE_PLAN } from 'common/components/plan/constants/tests';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';

export interface IPlanCardProps extends React.HTMLProps<HTMLDivElement> {
    project: IProjectResponse;
    plan: IPlanResponse;
    isSelected: boolean;
    onItemClicked: (plan: IPlanResponse) => void;
    showName?: boolean;
    disabled?: boolean;
    onCustomize: (plan: IPlanResponse) => void;
    canCustomizeItem?: boolean;
}

export const PlanCard: React.FC<IPlanCardProps> = ({
    project,
    plan,
    onItemClicked,
    onCustomize,
    isSelected,
    showName,
    disabled,
    canCustomizeItem,
}) => {
    const handleClick = (item: IPlanResponse) => () => !disabled && onItemClicked(item);
    const planPrice = React.useMemo(() => {
        if (project.token_pricing) {
            return {
                per_month: formatPricePerMonth(plan.tokens_per_month, project.token_pricing),
                per_hour: formatPricePerHour(plan.tokens_per_hour, project.token_pricing),
            };
        }

        return undefined;
    }, [plan, project]);

    return (
        <ItemPlan
            isSelected={isSelected}
            isDisabled={disabled}
            onClick={handleClick(plan)}
            data-cy={plan.name}
        >
            <PlanCardDescription
                name={plan.name}
                ram={plan.params.ram}
                vcpu={plan.params.vcpu}
                disk={plan.params.disk}
                showName={showName}
                price={planPrice}
            />
            {!plan.is_visible && (
                <Icon name={ICONS.EYE_CLOSED} size="16" style={{
                    color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60,
                    marginLeft: 'auto',
                }} />
            )}
            {canCustomizeItem && (
                <Tooltip title={<Translate content="servers.create.plan.customize"/>}>
                    <Action
                        disabled={disabled}
                        onClick={(e: React.MouseEvent<HTMLElement>) => {
                            e.stopPropagation();
                            onCustomize(plan);
                        }}
                        icon={
                            <Icon name={ICONS.COPY} size="32" style={{
                                color: isSelected ? COLORS.WHITE_0 : COLORS.GREY_60,
                                position: 'absolute',
                                bottom: '7px',
                                right: '12px',
                            }} />
                        }
                        data-cy={dataCySelector(plan.id, CUSTOMIZE_PLAN)}
                    />
                </Tooltip>
            )}
        </ItemPlan>
    );
};

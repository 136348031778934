// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as limitGroupActions from 'admin/limitGroup/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { addToPaginated } from 'common/reducers/list';
import { ILimitGroupResponse } from 'common/api/resources/LimitGroup';

interface ILimitGroup {
    list: IPaginateApiResponse<ILimitGroupResponse[]>;
    item: ILimitGroupResponse;
}

export type LimitGroupAction = ActionType<typeof limitGroupActions>;
export type LimitGroupState = ILimitGroup;

export const initialItemState: ILimitGroupResponse = {
    id: 0,
    name: '',
    running_vms: {
        limit: 0,
        is_enabled: true,
    },
    vms: {
        limit: 0,
        is_enabled: true,
    },
    additional_ips: {
        limit: 0,
        is_enabled: true,
    },
    additional_ipv6: {
        limit: 0,
        is_enabled: true,
    },
    iso_images: {
        limit: 0,
        is_enabled: true,
    },
    iso_images_size: {
        limit: 0,
        is_enabled: true,
    },
    users_count: 0,
    is_deleting: false,
};

export default combineReducers<LimitGroupState, LimitGroupAction>({
    list: (state = paginateInitialState, action: LimitGroupAction) => {
        switch (action.type) {
        case getType(limitGroupActions.appendLimitGroups):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(limitGroupActions.addLimitGroupItem):
            return addToPaginated(state, action.payload);
        case getType(limitGroupActions.setLimitGroupList):
            return action.payload;
        case getType(limitGroupActions.removeLimitGroupItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(limitGroupActions.updateLimitGroupItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case getType(limitGroupActions.setLimitGroupItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = initialItemState, action) => {
        switch (action.type) {
        case getType(limitGroupActions.setLimitGroupItem):
            return action.payload;
        case getType(limitGroupActions.unsetLimitGroupItem):
            return { ...initialItemState };
        default:
            return state;
        }
    },
});

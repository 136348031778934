// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    copyToClipboard,
    Form,
    FormField,
    FormFieldText,
    Paragraph,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import isEmpty from 'validator/lib/isEmpty';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as apiTokenActions from 'admin/apiToken/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IApiTokenRequest } from 'common/api/resources/ApiToken';
import {
    intMinRule,
    validate,
} from 'common/validator';
import CopyText from 'common/containers/CopyText/CopyText';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { ISelectOption } from 'common/components';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { Button } from 'admin/common/components/Button/Button';
import { DialogHeadingContainer } from 'admin/sshKey/containers/styles';
import { Dialog } from 'common/components/Dialog/Dialog';
import { INTENT_TYPE } from 'common/constants';
import { createOptionsLoader } from 'common/components/Select/helpers';

interface IApiTokenDialogProps {
    isOpened: boolean;
    onClose: () => void;
}

const userToSelectOptions = (user: IUserResponse) => ({
    label: user.email,
    value: user.id.toString(),
});

export type ApiTokenDialogProps =
    IApiTokenDialogProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ApiTokenDialog: React.FC<ApiTokenDialogProps> = ({
    isOpened,
    onClose,
    isSaving,
    formErrors,
    user,
    apiTokenActions: { createApiToken },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {

    const defaultValues: IApiTokenRequest = Object.freeze({
        user_id: user.id,
        name: '',
    });

    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });
    const [selectedUser, setSelectedUser] = React.useState<ISelectOption>(userToSelectOptions(user));

    const [token, setToken] = React.useState<string>();

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IApiTokenRequest) => {
        const validationErrors = validate<IApiTokenRequest>(values, {
            name: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired"/>,
                comparison: true,
            },
            user_id: intMinRule(<Translate
                content="validate.fieldRequired"
                params={{ min: 0 }}
            />, 0),
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            setToken(await createApiToken(values));
        } catch (e) {
            throw e;
        }
    };

    const loadUserOptions = createOptionsLoader<IUserResponse>(
        users.list,
        userToSelectOptions
    );

    const handleCopyAndClose = () => {
        if (token) {
            copyToClipboard(token);
            clearFormErrors();
            onClose();
        }
    };

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserChange = (option: any) => {
        setSubmitValues((state) => ({
            ...state,
            user_id: option ? option.value : null,
        }));
        setSelectedUser(option);
    };

    const generateForm = () => {
        if (token) {
            return (
                <>
                    <Section>
                        <Paragraph>
                            <Translate content="apiToken.tokenAddedText"/>
                        </Paragraph>
                        <CopyText width="380px">{token}</CopyText>
                    </Section>
                    <Button
                        onClick={handleCopyAndClose}
                        fill={true}
                        intent={INTENT_TYPE.PRIMARY}
                        size="lg"
                    >
                        <Translate content="apiToken.copyAndClose"/>
                    </Button>
                </>
            );
        }
        return (
            <Form<IApiTokenRequest>
                id="apiTokenForm"
                values={submitValues}
                errors={formErrors}
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        placeholder="Name"
                        errors={formErrors}
                        autoFocus={true}
                        size="fill"
                        label={<Translate content="apiToken.list.name"/>}
                    />
                    <FormField
                        name="user_id"
                        label={<Translate content="apiToken.list.user"/>}
                    >
                        <AsyncSelectInput
                            value={selectedUser}
                            placeholder={<Translate content="apiToken.list.user"/>}
                            loadOptions={loadUserOptions}
                            onChange={handleUserChange}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            menuPosition="fixed"
                            required={true}
                            isClearable={true}
                        />
                    </FormField>
                </Section>
                <Button
                    type="submit"
                    isLoading={isSaving}
                    form="apiTokenForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size="lg"
                >
                    <Translate content="apiToken.generateBtn"/>
                </Button>
            </Form>
        );
    };

    return (
        <Dialog
            heading={(
                <DialogHeadingContainer>
                    {token ? <Translate content="apiToken.tokenAdded"/> : <Translate content="apiToken.addNewToken"/>}
                </DialogHeadingContainer>
            )}
            closeHandler={onClose}
            isOpen={isOpened}
            size="xs"
        >
            {generateForm()}
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    formErrors: state.app.formErrors,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_AUTH_API_TOKEN),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenDialog);

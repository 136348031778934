// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    BootMode,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';

export const getServerBootModeName = (server: IVmResponse) => {
    switch (server.boot_mode) {
    case BootMode.DISK:
        return server.settings.os_image.name;
    case BootMode.RESCUE:
        return (
            <Translate content="servers.rescueISO"/>
        );
    case BootMode.ISO_IMAGE:
        return server.settings.iso_image ? server.settings.iso_image.name : server.name;
    default:
        return server.name;
    }
};
// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    ISchedule,
    IScheduleTime,
    ScheduleType,
} from 'common/api/resources/model';
import { ValueType } from 'react-select';
import { ISelectOption } from 'common/components';
import {
    FormField,
    Translate,
} from '@plesk/ui-library';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import * as React from 'react';
import { IFormProps } from 'common/components/Form/types';
import SelectInput from 'common/components/Select/SelectInput';
import WeekdaysSelector from 'common/components/WeekdaysSelector/WeekdaysSelector';
import moment from 'moment';
import { FormFieldTime } from 'common/components/Form/FormFieldTime/FormFieldTime';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';

export interface IScheduleForm {
    type: ScheduleType;
    time: string;
    days: number[];
}

export interface IScheduleProps {
    schedule: ISchedule;
    fieldName: string;
    onChange: (schedule: ISchedule) => void;
    types?: ScheduleType[];
}

const backupScheduleTypeTranslations = {
    [ScheduleType.MONTHLY]: <Translate content="schedule.type.monthly" />,
    [ScheduleType.WEEKLY]: <Translate content="schedule.type.weekly" />,
    [ScheduleType.DAILY]: <Translate content="schedule.type.daily" />,
    [ScheduleType.HOURLY]: <Translate content="schedule.type.hourly" />,
};

const daysOfMonthsOptions: ISelectOption[] = [...Array(31)].map((_, day) => {
    const value = (day + 1).toString();

    return {
        value,
        label: value,
    };
});

export const scheduleToFormData = (schedule: ISchedule) => ({
    type: schedule.type,
    time: moment()
        .utc()
        .hour(schedule.time.hour)
        .minutes(schedule.time.minutes)
        .format('HH:mm'),
    days: schedule.days,
});

export const formDataToSchedule = (data: IScheduleForm): ISchedule => {
    const [ hour, minutes ] = data.time
        .split(':')
        .map(v => parseInt(v, 10));

    return {
        type: data.type,
        time: {
            hour,
            minutes,
        },
        days: data.days,
    };
};

const timeToObject = (time: string): IScheduleTime => {
    const [ hour, minutes ] = time
        .split(':')
        .map(v => parseInt(v, 10));

    return {
        hour,
        minutes,
    };
};

const timeToString = (time: IScheduleTime): string => moment()
    .utc()
    .hour(time.hour)
    .minutes(time.minutes)
    .format('HH:mm');

export const Schedule: React.FC<IScheduleProps> = ({
    schedule,
    fieldName,
    onChange,
    types = Object.values(ScheduleType),
}) => {
    const handleTypeChange = (type: ScheduleType) => {
        onChange({
            ...schedule,
            days: [],
            time: {
                hour: 0,
                minutes: 0,
            },
            type,
        });
    };

    const handleDaysChange = (days: ValueType<ISelectOption>) => {
        onChange({
            ...schedule,
            days: days ? days.map((item: ISelectOption) => parseInt(item.value as string, 10)) : [],
        });
    };

    const handleTimeChange = (time: string) => {
        onChange({
            ...schedule,
            time: timeToObject(time),
        });
    };

    const handleMinutesChange = (minutes: string) => {
        onChange({
            ...schedule,
            time: {
                hour: 0,
                minutes: +minutes,
            },
        });
    };

    const renderTypeForm = (type: ScheduleType) => {
        const renderTime = () => (
            <FormFieldTime
                required={true}
                label={<Translate content="schedule.time"/>}
                name={`${fieldName}[time]`}
                onChange={handleTimeChange}
                value={timeToString(schedule.time)}
                size={75}
            />
        );

        if (type === ScheduleType.DAILY) {
            return renderTime();
        }

        if (type === ScheduleType.MONTHLY) {
            return (
                <>
                    <FormField
                        required={true}
                        name={`${fieldName}[days]`}
                        label={<Translate content="schedule.days"/>}
                    >
                        {({ getId }: IFormProps<unknown>) => (
                            <SelectInput
                                inputId={getId()}
                                isMulti={true}
                                onChange={handleDaysChange}
                                value={schedule.days
                                    ? schedule.days.map(i => daysOfMonthsOptions[i - 1])
                                    : []
                                }
                                options={daysOfMonthsOptions}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    {renderTime()}
                </>
            );
        }

        if (type === ScheduleType.WEEKLY) {
            return (
                <>
                    <FormField
                        required={true}
                        name={`${fieldName}[days]`}
                        label={<Translate content="schedule.days"/>}
                    >
                        {({ getId }: IFormProps<unknown>) => (
                            <WeekdaysSelector
                                inputId={getId()}
                                isMulti={true}
                                onChange={handleDaysChange}
                                value={schedule.days}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    {renderTime()}
                </>
            );
        }

        if (type === ScheduleType.HOURLY) {
            return (
                <FormFieldNumber
                    name={`${fieldName}[minutes]`}
                    label={<Translate content="schedule.minutes"/>}
                    min={0}
                    max={59}
                    value={schedule.time.minutes}
                    onChange={handleMinutesChange}
                />
            );
        }

        return null;
    };

    return (
        <>
            <FormField
                name={`${fieldName}[type]`}
                required={true}
                value={schedule.type}
                label={<Translate content="schedule.schedule"/>}
            >
                <SegmentedControl
                    buttons={types.map(value => ({
                        value,
                        title: backupScheduleTypeTranslations[value],
                    }))}
                    selected={schedule.type}
                    onChange={handleTypeChange}
                />
            </FormField>
            {renderTypeForm(schedule.type)}
        </>
    );
};

export default Schedule;

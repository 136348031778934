// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import enLocale from 'client/core/I10n/en_US';
import commonEnLocale from 'common/I10n/en_US';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { routes } from 'client/core/router';
import { PersistGate } from 'redux-persist/integration/react';
import { GlobalStyle } from 'common/styles/Global';
import { renderRoutes } from 'common/components/AppRoute';
import ThemeProvider from 'common/containers/ThemeProvider/ThemeProvider';
import EchoProvider from 'client/common/containers/EchoProvider/EchoProvider';
import { moduleMapper } from 'common/modules/app/language/helpers';
import LocaleProvider from 'common/components/LocaleProvider';
import Startup from 'client/common/containers/Startup';
import FeaturesRegister from 'common/containers/FeaturesRegister/FeaturesRegister';
import { Redirect } from 'react-router';
import {
    history,
    persistor,
    store,
} from 'client/core/store';

export const defaultLocale = { ...enLocale, ...commonEnLocale };

export const loadClientLocales = (locale: string) => Promise.all([
    import(`../../client/core/I10n/${locale}`).then(moduleMapper),
    import(`../../common/I10n/${locale}`).then(moduleMapper),
])
    .then(m => m.reduce((acc, cur) => ({ ...acc, ...cur }), {}))
    .catch(() => null);

export const ClientApp: React.FC = () => {
    if ('Cypress' in window) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).clientStore = store;
    }

    return (
        <Provider store={store}>
            <FeaturesRegister/>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <EchoProvider>
                        <Startup>
                            <LocaleProvider
                                defaultMessages={defaultLocale}
                                loadLocales={loadClientLocales}
                            >
                                <ThemeProvider>
                                    <GlobalStyle />
                                    {renderRoutes(routes, () => <Redirect to="/projects" />)}
                                </ThemeProvider>
                            </LocaleProvider>
                        </Startup>
                    </EchoProvider>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
};

export default ClientApp;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import * as projectActions from 'common/modules/project/actions';
import * as mailConfirmationActions from 'client/project/actions/mailConfirmation';
import {
    Action,
    Button,
    List,
    Popover,
    Translate,
    Icon,
} from '@plesk/ui-library';
import { AlertMessage } from 'client/common/components';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import ProjectCard from 'client/project/containers/projectCard/ProjectCard';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    CutTitle,
    Loader,
} from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { isCreatingNewProject } from 'client/project/selectors/server';
import {
    HeaderContainer,
    HeaderContent,
    HeaderTitle,
} from 'client/common/styles/Header';
import { CardList } from 'client/common/components/Card';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { TabsExpander } from 'client/common/styles/Tabs';
import ViewSelect from 'common/components/ViewSelect';
import { setViewType } from 'common/modules/app/viewType/actions';
import {
    ICONS,
    VIEW_TYPE,
} from 'common/constants';
import { StyledList } from 'client/common/styles/List';
import { HostnameCell } from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServersList/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { Figure } from 'client/project/components/Figure/Figure';
import ProjectForm from 'client/project/containers/ProjectForm';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { statusTooltipMapping } from 'client/project/constants/translation';
import { getActionColumnProps } from 'common/helpers/list';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export type ProjectProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="projects.list.colName"/>,
    cellProps: {
        className: 'cell-os',
    },
}, {
    key: 'colDescription',
    title: <Translate content="projects.list.colDescription" />,
    cellProps: {
        className: 'cell-description',
    },
}, {
    width: '10%',
    key: 'colOwner',
    title: <Translate content="projects.list.colOwner" />,
    cellProps: {
        className: 'cell-owner',
    },
}, {
    width: '15%',
    key: 'colServers',
    title: <Translate content="projects.list.colServers" />,
}, {
    width: '15%',
    key: 'colMembers',
    title: <Translate content="projects.list.colMembers" />,
}, getActionColumnProps(),
];

export const Project: React.FC<ProjectProps> = ({
    history,
    selectedView,
    setSelectedView,
    projects,
    isLoading,
    isSaving,
    isCreating,
    isUserLockedOrSuspended: userLockedOrSuspended,
    userStatus,
    isVerifyMessageShown,
    projectActions: { getProjects, removeProject },
    mailConfirmationActions: { setIsShown },
    settings,
    canCreateServers,
    canCreateProjects,
}) => {
    React.useEffect(() => {
        getProjects();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [isPopoverOpened, setPopoverOpened] = React.useState({});
    const handleOpen = (id: number) => () => setPopoverOpened({ ...isPopoverOpened, [id]: true });
    const handleClose = (id: number) => () => setPopoverOpened({ ...isPopoverOpened, [id]: false });

    const handleCloseVerification = () => setIsShown(false);
    const handleViewChange = (value: string) => setSelectedView(VIEW_TYPE[value]);
    const handleSelect = (id: number) => () => history.push(`/projects/${id}`);
    const handleDelete = (project: IProjectResponse) => () => removeProject(project);
    const handleSelectServers = (project: IProjectResponse) => () => {
        if (project.servers > 0) {
            return history.push(`/projects/${project.id}#servers`);
        }

        if (userLockedOrSuspended) {
            return;
        }

        return history.push(`/projects/${project.id}/servers/create`);
    };
    const handleSelectMembers = (project: IProjectResponse) => () => history.push(`/projects/${project.id}#members`);

    const data = projects.data.map((item) => {
        const actionsEl = (
            <>
                <Popover
                    onClose={handleClose(item.id)}
                    visible={isPopoverOpened[item.id]}
                    target={(
                        <Button
                            ghost={true}
                            disabled={!item.isLoading && isSaving}
                            icon={ICONS.PENCIL}
                        />
                    )}
                    placement="bottom-left"
                >
                    <ProjectForm project={item} onCreated={handleClose(item.id)} />
                </Popover>
                {item.is_owner && (
                    <ButtonWithConfirmation
                        translations={{
                            text: (
                                <Translate content="projects.form.deleteConfirmationText" />
                            ),
                            button: (
                                <Translate content="projects.form.deleteButton" />
                            ),
                            title: (
                                <Translate content="projects.form.deleteTitle" />
                            ),
                            tooltip: (
                                <Translate content={item.is_default
                                    ? 'projects.form.defaultProjectRemovalTooltip'
                                    : 'projects.form.deleteTooltip'
                                } />
                            ),
                        }}
                        disabled={item.is_default}
                        handleConfirm={handleDelete(item)}
                        icon={ICONS.RECYCLE}
                    />
                )}
            </>
        );

        return {
            colName: (
                <HostnameCell>
                    <Figure
                        title={item.name}
                        hideTitle={true}
                        style={{
                            height: '16px',
                            width: '16px',
                            backgroundSize: '16px 16px',
                            marginRight: '8px',
                        }}
                    />
                    <Action onClick={handleSelect(item.id)}><CutTitle title={item.name} /></Action>
                </HostnameCell>
            ),
            colDescription: <CutTitle style={{ color: '#737373' }} title={item.description} />,
            colOwner: item.is_owner && (
                <Icon
                    style={{ color: settings.theme.primary_color }}
                    name={ICONS.RIBBON}
                />
            ),
            colServers: (() => {
                if (item.servers > 0) {
                    return (
                        <Action onClick={handleSelectServers(item)}>
                            <Translate content="projects.form.serverList" params={{ count: item.servers }} />
                        </Action>
                    );
                }

                if (item.servers === 0 && canCreateServers) {
                    return (
                        <Action style={{ fontWeight: 600 }} onClick={handleSelectServers(item)}>
                            <Translate content="projects.form.createServer" />
                        </Action>
                    );
                }

                return '';
            })(),
            colMembers: (
                <Action onClick={handleSelectMembers(item)}>
                    <Translate content="projects.form.membersList" params={{ count: item.members }} />
                </Action>
            ),
            colActions: actionsEl,
            key: item.id.toString(),
        };
    });

    return (
        <Loader isLoading={isLoading}>
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitle>
                        <h1><Translate content="projects.title" /></h1>
                    </HeaderTitle>
                </HeaderContent>
                {canCreateProjects && (
                    <Popover
                        onClose={handleClose(0)}
                        visible={isPopoverOpened[0]}
                        target={(
                            <HeaderButton
                                isMobile={true}
                                onClick={handleOpen(0)}
                                icon={ICONS.PLUS}
                                tooltip={userLockedOrSuspended && (statusTooltipMapping[userStatus])}
                                disabled={userLockedOrSuspended || isCreating}
                            >
                                <Translate content="projects.addProject" />
                            </HeaderButton>
                        )}
                        placement="bottom-left"
                    >
                        <ProjectForm onCreated={handleClose(0)} />
                    </Popover>
                )}
            </HeaderContainer>
            {isVerifyMessageShown && (
                <AlertMessage onClose={handleCloseVerification} title={<Translate content="projects.emailVerified" />} />
            )}
            <Tabs defaultIndex={0}>
                <TabList>
                    <Tab style={{ display: 'none' }}><Translate content="projects.tabs.list" /></Tab>
                    <TabsExpander />
                    <ViewSelect
                        selected={selectedView}
                        onChange={handleViewChange}
                    />
                </TabList>
                <TabPanel>
                    <Loader isLoading={isLoading}>
                        {selectedView === VIEW_TYPE.CARD && (
                            <CardList
                                items={projects.data}
                                component={(project, key) => <ProjectCard key={project.id} project={project} />}
                            />
                        )}
                        {selectedView === VIEW_TYPE.LIST && (
                            <StyledList>
                                <List
                                    emptyView={null}
                                    columns={columns}
                                    data={data}
                                />
                            </StyledList>
                        )}
                    </Loader>
                </TabPanel>
            </Tabs>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedView: state.app.viewType,
    isVerifyMessageShown: state.project.is_verify_message_shown,
    projects: state.project.projects.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_LIST),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_ITEM),
    isCreating: isCreatingNewProject(state),
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
    userStatus: state.auth.user.status,
    settings: state.settings,
    canCreateServers: hasPermission(state, PERMISSION_LIST.CREATE_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
    canCreateProjects: hasPermission(state, PERMISSION_LIST.CREATE_PROJECTS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectActions: bindActionCreators(projectActions, dispatch),
    mailConfirmationActions: bindActionCreators(mailConfirmationActions, dispatch),
    setSelectedView: bindActionCreators(setViewType, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Project));

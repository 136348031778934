// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { DiskCacheMode } from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';
import SelectWithConfirmation, { ISelectWithConfirmationProps } from 'common/components/SelectWithConfirmation/SelectWithConfirmation';
import { ISelectOption } from 'common/components/SelectInput';
import { ValueType } from 'react-select';

export interface IDiskCacheModeControlProps extends Omit<
    ISelectWithConfirmationProps,
    'onChange' | 'dialog' | 'isLoading' | 'isDisabled'
> {
    disabled?: boolean;
    loading?: boolean;
    value: DiskCacheMode;
    onChange: (value: DiskCacheMode) => void;
}

const KVMDiskCacheModeControl: React.FC<IDiskCacheModeControlProps> = ({
    disabled = false,
    loading = false,
    value,
    onChange,
    ...props
}) => {
    const handleChange = (option: ValueType<ISelectOption>) => {
        onChange((option as ISelectOption).value as DiskCacheMode);
    };

    const options: ISelectOption[] = Object.values(DiskCacheMode).map(status => ({
        value: status,
        label: status.charAt(0).toUpperCase() + status.slice(1),
    }));
    const getValue = (status: DiskCacheMode) =>
        options.find((option) => option.value === status);

    return (
        <SelectWithConfirmation
            minHeight="40px"
            width="350px"
            {...props}
            onChange={handleChange}
            value={getValue(value)}
            options={options}
            isDisabled={disabled}
            isLoading={loading}
            dialog={{
                title: <Translate content="servers.controls.kvmDeskCacheMode.confirmation.title" />,
                text: <Translate content="servers.controls.kvmDeskCacheMode.confirmation.text" />,
                button: <Translate content="servers.controls.kvmDeskCacheMode.confirmation.button" />,
            }}
        />
    );
};

export default KVMDiskCacheModeControl;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import validator from 'validator';

type ValidationErrorValue = IValidatorError | {
    message: React.ReactNode | string;
};

export interface IValidatorError {
    [key: string]: ValidationErrorValue;
}

export interface IValidationRule {
    validator: (input: string) => boolean;
    message: React.ReactNode;
    comparison: boolean;
}

export interface IValidationRules {
    [key: string]: IValidationRule | IValidationRule[];
}

export const transformErrors = (errors: IValidatorError): IValidatorError => {
    const res = {};

    Object.entries(errors).forEach(([ key, value ]) => {
        const path = key.split('.');

        if (path.length === 1) {
            res[key] = value;
            return;
        }

        let ref = res;
        path.slice(0, -1).forEach((k) => {
            if (! ref.hasOwnProperty(k)) {
                ref[k] = {};
            }
            ref = ref[k];
        });
        ref[path.slice(-1)[0]] = value;
    });

    return res;
};

interface IStringer {
    toString(): string;
}

export const validateScalar = <T extends IStringer>(value: T, rule: IValidationRule): React.ReactNode | string | undefined => {
    if (typeof value === 'undefined') {
        return rule.message;
    }

    if (rule.validator(value.toString()) === rule.comparison) {
        return rule.message;
    }

    return undefined;
};

export const validate = <T extends IStringer>(values: T, rules: IValidationRules): IValidatorError => {
    const errors: IValidatorError = {};

    Object.keys(rules).forEach(key => {
        const value = key.split('.').reduce((v, k) => v[k], values);
        const valueRules = rules[key];

        if (valueRules === undefined) {
            return;
        }

        const validateRule = (rule: IValidationRule) => {
            if (typeof value === 'undefined') {
                errors[key] = { message: rule.message };
                return;
            }

            if (errors[key] === undefined && rule.validator(value.toString()) === rule.comparison) {
                errors[key] = { message: rule.message };
            }
        };

        if (Array.isArray(valueRules)) {
            return valueRules.forEach(validateRule);
        }

        return validateRule(valueRules);
    });

    // Transform errors into proper form which is expects UI-library form.
    return transformErrors(errors);
};

export const isIp = (value: string, version?: validator.IPVersion) => validator.isIP(value.trim(), version);

export const isRange = (value: string, version?: validator.IPVersion) => {
    const values = value.trim().split('/');

    return validator.isIP(values[0], version) && values[1] !== undefined && validator.isInt(values[1], { min: 1, max: 128 });
};

export const ipRule = (message: JSX.Element, version?: validator.IPVersion) => ({
    validator: (value: string) => isIp(value, version),
    message,
    comparison: false,
});

export const rangeRule = (message: JSX.Element, version?: validator.IPVersion) => ({
    validator: (value: string) => isRange(value, version),
    message,
    comparison: false,
});

export const requiredRule = (message: JSX.Element) => ({
    validator: (value: string) => validator.isEmpty(value, { ignore_whitespace: true }),
    message,
    comparison: true,
});

export const domainRule = (message: JSX.Element) => ({
    validator: (value: string) => validator.isURL(value),
    message,
    comparison: false,
});

export const numericRule = (message: JSX.Element) => ({
    validator: (value: string) => validator.isNumeric(value),
    message,
    comparison: false,
});

export const intMinRule = (message: JSX.Element, min?: number) => ({
    validator: (value: string) => validator.isInt(value, { min }),
    message,
    comparison: false,
});

export const intMinMaxRule = (message: JSX.Element, min: number, max: number) => ({
    validator: (value: string) => validator.isInt(value, { min, max }),
    message,
    comparison: false,
});

export const floatMinMaxRule = (message: JSX.Element, min: number, max: number) => ({
    validator: (value: string) => validator.isFloat(value, { min, max }),
    message,
    comparison: false,
});

export const passwordRule = (message: JSX.Element) => ({
    validator: (value: string) => validator.matches(value, /^.*(?=.{3,})(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9]).{8,}$/),
    message,
    comparison: false,
});

export const conditionRule = (message: JSX.Element, condition: boolean) => ({
    validator: () => condition,
    message,
    comparison: true,
});

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType as EventHandlerActionType,
    IEventHandlerResponse,
} from 'common/api/resources/EventHandler/model';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as eventHandlersActions from 'admin/eventHandler/actions';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';

interface IEventHandlerReducer {
    item: IEventHandlerResponse;
    list: IPaginateApiResponse<IEventHandlerResponse[]>;
}

export type EventHandlerActions = ActionType<typeof eventHandlersActions>;
export type EventHandlerState = Readonly<IEventHandlerReducer>;

export const initialEventHandlerState: IEventHandlerResponse = {
    id: 0,
    name: '',
    action_type: EventHandlerActionType.WEBHOOK,
    events: [],
    action_params: {
        url: '',
        request_params: '',
    },
    is_enabled: false,
};

export default combineReducers<EventHandlerState, EventHandlerActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(eventHandlersActions.setEventHandlerList):
            return action.payload;
        case getType(eventHandlersActions.addEventHandlerItem):
            return addToPaginated(state, action.payload);
        case getType(eventHandlersActions.updateEventHandlerItem):
            return updateInPaginated(state, action.payload);
        case getType(eventHandlersActions.removeEventHandlerItem):
            return deleteFromPaginated(state, action.payload);
        case getType(eventHandlersActions.setEventHandlerItemIsDeleting):
            return updateInPaginated(state, action.payload);
        case getType(eventHandlersActions.setEventHandlerItemIsLoading):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialEventHandlerState, action) => {
        switch (action.type) {
        case getType(eventHandlersActions.setEventHandlerItem):
            return action.payload;
        case getType(eventHandlersActions.unsetEventHandlerItem):
            return initialEventHandlerState;
        default:
            return state;
        }
    },
});

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import { CheckBox } from 'common/components/CheckBox/CheckBox';
import {
    CONFIRMATION_DIALOG,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import { ConfirmationDialogItem } from 'common/components/ConfirmationDialog/Styles';

export interface IConfirmationDialogProps {
    isOpen: boolean;
    isLoading: boolean;
    isDisabled?: boolean;
    handleClick: () => void;
    onClose: () => void;
    title: string | React.ReactNode;
    innerText: string | React.ReactNode;
    size?: SIZE;
}

export const ConfirmationDialog: React.FC<IConfirmationDialogProps> = ({
    handleClick,
    isOpen,
    onClose,
    isDisabled,
    title,
    innerText,
    isLoading,
    size = SIZE.SM,
}) => {
    const [useForce, setUseForce] = React.useState(false);

    const handleChangeForce = () => {
        setUseForce(!useForce);
    };

    return (
        <>
            <Dialog
                isOpen={isOpen}
                heading={title}
                size={size}
                closeHandler={onClose}
            >
                <ConfirmationDialogItem>
                    {innerText}
                </ConfirmationDialogItem>
                {isDisabled && (
                    <ConfirmationDialogItem>
                        <CheckBox
                            name="type"
                            checked={useForce}
                            onChange={handleChangeForce}
                        >
                            <Translate content="confirmation.dialog.forceConfirm" />
                        </CheckBox>
                    </ConfirmationDialogItem>
                )}
                <ConfirmationDialogItem>
                    <Button
                        intent={INTENT_TYPE.DANGER}
                        disabled={!useForce && isDisabled}
                        onClick={handleClick}
                        state={isLoading ? 'loading' : 'active'}
                        data-cy={CONFIRMATION_DIALOG.OK_BUTTON}
                    >
                        <Translate content="confirmation.dialog.ok" />
                    </Button>
                    <Button
                        onClick={onClose}
                    >
                        <Translate content="confirmation.dialog.cancel" />
                    </Button>
                </ConfirmationDialogItem>
            </Dialog>
        </>
    );
};

export default ConfirmationDialog;

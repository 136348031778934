// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    applyMiddleware,
    CombinedState,
    combineReducers,
    createStore,
} from 'redux';
import { StateType } from 'typesafe-actions';
import activityLogReducer,
{ ActivityLogState } from 'admin/activityLog/reducer';
import authReducer from 'common/modules/auth/reducer';
import twoFactorAuthReducer from 'common/modules/auth/twoFactorAuth/reducer';
import backupNodeReducer,
{ BackupNodeState } from 'admin/backupNode/reducer';
import computeResourceReducer,
{ ComputeResourceState } from 'admin/computeResource/reducers';
import limitGroupReducer,
{ LimitGroupState } from 'admin/limitGroup/reducer';
import roleReducer,
{ RoleState } from 'admin/role/reducer';
import planReducer,
{ PlanState } from 'common/modules/plan/reducer';
import userReducer,
{ UserState } from 'admin/user/reducer';
import projectReducer,
{ ProjectState } from 'common/modules/project/reducer';
import dashboardReducer,
{ DashboardState } from 'admin/dashboard/reducer';
import ipBlockReducer,
{ IpBlockState } from 'admin/ipBlock/reducer';
import osImageReducer,
{ OsImageState } from 'common/modules/osImage/reducer';
import locationReducer,
{ LocationState } from 'common/modules/location/reducer';
import iconReducer,
{ IconState } from 'admin/icon/reducer';
import applicationReducer,
{ ApplicationState } from 'common/modules/application/reducer';
import solusIOLicenseReducer,
{ SolusIOLicenseState } from 'admin/license/solusIO/reducer';
import settingsReducer,
{ SettingsState } from 'common/modules/settings/reducer';
import storageTypeReducer,
{ StorageTypeState } from 'admin/storageType/reducer';
import sshKeyReducer,
{ SshKeyState } from 'common/modules/sshKey/reducer';
import notificationReducer,
{ NotificationState } from 'admin/notification/reducer';
import updateReducer,
{ UpdateState } from 'admin/update/reducer';
import taskReducer,
{ TaskState } from 'admin/task/reducer';
import permissionReducer,
{ PermissionState } from 'common/modules/permission/reducer';
import storageReducer,
{ StorageState } from 'admin/storage/reducer';
import defaultTemplateReducer,
{ DefaultTemplateState } from 'admin/defaultTemplate/reducer';
import {
    connectRouter,
    routerMiddleware,
} from 'connected-react-router';
import { createBrowserHistory } from 'history';
import appReducer from 'common/modules/app/reducer';
import {
    persistReducer,
    persistStore,
    createTransform,
} from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import thunk from 'redux-thunk';
import {
    composeEnhancers,
    ICommonState,
} from 'common/store';
import computeResourceVmReducer,
{ VmState } from 'common/modules/computeResourceVm/reducers';
import backupReducer,
{ BackupState } from 'common/modules/backup/reducers';
import languageReducer from 'common/modules/language/reducer';
import graphReducer from 'common/modules/graph/reducers';
import osImageVersionReducer,
{ OsImageVersionState } from 'common/modules/osImage/reducerVersions';
import { tokenMiddleware } from 'common/modules/auth/middlewares/tokenMiddleware';
import { languageUpdateMiddleware } from 'common/redux/middleware/languageUpdateMiddleware';
import {
    AUTH_PERSIST_KEY,
    STORE_PERSIST_KEY,
} from 'common/redux/constants';
import { rootReducerDecorator } from 'common/modules/root/reducer';
import snapshotReducer,
{ SnapshotState } from 'common/modules/snapshot/reducers';
import serversMigrationReducer,
{ ServersMigrationState } from 'admin/serversMigration/reducers';
import { PersistConfig } from 'redux-persist/es/types';
import { FEATURES } from 'common/modules/app/features/constants';
import apiTokenReducer,
{ ApiTokenState } from 'admin/apiToken/reducer';
import externalIntegrationReducer,
{ ExternalIntegrationState } from 'admin/externalIntegration/reducer';
import solusVMLicenseReducer,
{ SolusVMLicenseState } from 'admin/license/solusVM/reducer';
import managementNodeBackupReducer,
{ ManagementNodeBackupState } from 'admin/managementNodeBackup/reducer';
import clusterImportReducer,
{ ClusterImportState } from 'admin/clusterImport/reducer';
import eventHandlerReducer,
{ EventHandlerState } from 'admin/eventHandler/reducer';
import vlanReducer,
{ VlanState } from 'admin/vlan/reducer';
import isoImageReducer,
{ IsoImageState } from 'common/modules/isoImage/reducer';
import confirmIdentityReducer from 'common/modules/auth/confirmIdentity/reducer';

export interface IAppState extends ICommonState {
    application: ApplicationState;
    apiToken: ApiTokenState;
    backup: BackupState;
    managementNodeBackup: ManagementNodeBackupState;
    backupNode: BackupNodeState;
    role: RoleState;
    plan: PlanState;
    user: UserState;
    ipBlock: IpBlockState;
    permission: PermissionState;
    dashboard: DashboardState;
    computeResource: ComputeResourceState;
    computeResourceVm: VmState;
    icon: IconState;
    osImage: OsImageState;
    osImageVersion: OsImageVersionState;
    location: LocationState;
    storageType: StorageTypeState;
    sshKey: SshKeyState;
    storage: StorageState;
    settings: SettingsState;
    limitGroup: LimitGroupState;
    notification: NotificationState;
    task: TaskState;
    solusIOLicense: SolusIOLicenseState;
    solusVMLicense: SolusVMLicenseState;
    update: UpdateState;
    project: ProjectState;
    snapshot: SnapshotState;
    serversMigration: ServersMigrationState;
    activityLog: ActivityLogState;
    externalIntegration: ExternalIntegrationState;
    clusterImport: ClusterImportState;
    eventHandler: EventHandlerState;
    vlan: VlanState;
    defaultTemplate: DefaultTemplateState;
    isoImage: IsoImageState;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const appPersistConfig: PersistConfig<CombinedState<any>> = {
    key: STORE_PERSIST_KEY,
    storage,
    transforms: [
        createTransform<Set<FEATURES>, FEATURES[]>(
            (inboundState) => Array.from(inboundState.values()),
            (outboundState) => new Set(outboundState),
            { whitelist: ['features'] }
        ),
    ],
    whitelist: ['menu', 'language', 'features', 'lastUsedSshKeys'],
};

export const history = createBrowserHistory();
export const rootReducer = combineReducers({
    app: persistReducer(appPersistConfig, appReducer),
    auth: authReducer,
    twoFactorAuth: twoFactorAuthReducer,
    confirmIdentity: confirmIdentityReducer,
    application: applicationReducer,
    apiToken: apiTokenReducer,
    backup: backupReducer,
    managementNodeBackup: managementNodeBackupReducer,
    backupNode: backupNodeReducer,
    dashboard: dashboardReducer,
    plan: planReducer,
    role: roleReducer,
    storage: storageReducer,
    user: userReducer,
    ipBlock: ipBlockReducer,
    permission: permissionReducer,
    computeResource: computeResourceReducer,
    computeResourceVm: computeResourceVmReducer,
    icon: iconReducer,
    osImage: osImageReducer,
    osImageVersion: osImageVersionReducer,
    limitGroup: limitGroupReducer,
    location: locationReducer,
    notification: notificationReducer,
    task: taskReducer,
    solusIOLicense: solusIOLicenseReducer,
    solusVMLicense: solusVMLicenseReducer,
    storageType: storageTypeReducer,
    sshKey: sshKeyReducer,
    settings: settingsReducer,
    snapshot: snapshotReducer,
    update: updateReducer,
    project: projectReducer,
    router: connectRouter(history),
    language: languageReducer,
    graph: graphReducer,
    serversMigration: serversMigrationReducer,
    activityLog: activityLogReducer,
    externalIntegration: externalIntegrationReducer,
    clusterImport: clusterImportReducer,
    eventHandler: eventHandlerReducer,
    vlan: vlanReducer,
    defaultTemplate: defaultTemplateReducer,
    isoImage: isoImageReducer,
});

const persistConfig = {
    key: AUTH_PERSIST_KEY,
    storage,
    whitelist: ['auth'],
};

const persistedReducer = persistReducer(persistConfig, rootReducerDecorator(rootReducer));

export const store = createStore(
    persistedReducer,
    composeEnhancers(
        applyMiddleware(thunk, routerMiddleware(history), tokenMiddleware, languageUpdateMiddleware)
    )
);

export const persistor = persistStore(store);

export type RootState = StateType<typeof rootReducer>;

export default rootReducer;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import {
    ILoadOptions,
    ISelectRequiredOption,
} from 'common/components';
import * as React from 'react';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import { users } from 'common/api/resources/User';
import { Translate } from '@plesk/ui-library';

export interface IUserFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    margin_left?: string;
    value?: ValueType<ISelectRequiredOption>;
}

export const UserFilter: React.FC<IUserFilterProps> = ({
    onChange,
    margin_left,
    value,
}) => {
    const loadUsers: ILoadOptions = async (search, loadedOptions, { page }) => {
        const response = await users.list({
            page,
            filters: {
                search,
            },
        });

        return {
            options: response.data.data.map(user => ({
                label: user.email,
                value: user.id.toString(),
            })),
            hasMore: !!response.data.links.next,
            additional: {
                page: page + 1,
            },
        };
    };

    return (
        <FixedFormField margin_left={margin_left} data-cy={FILTERS.USER}>
            <AsyncSelectInput
                inputId="user-filter"
                isClearable={true}
                placeholder={<Translate content="filters.user"/>}
                loadOptions={loadUsers}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
            />
        </FixedFormField>
    );
};

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export enum DataUnit {
    KiB = 'KiB',
    MiB = 'MiB',
    GiB = 'GiB',
    TiB = 'TiB',
    PiB = 'PiB',
}

export enum DiskBandwidthUnit {
    Bps  = 'Bps',
    Kbps = 'KBps',
    Mbps = 'MBps',
}

export enum BandwidthUnit {
    Kbps = 'Kbps',
    Mbps = 'Mbps',
    Gbps = 'Gbps',
}

export enum IOpsUnit {
    iops = 'iops',
}

export enum Unit {
    UNITS = 'units',
}

const delimiter = 1024;

export const KiB = delimiter;
export const MiB = KiB * delimiter;
export const GiB = MiB * delimiter;
export const TiB = GiB * delimiter;
export const PiB = TiB * delimiter;

const multiplies = {
    [DataUnit.KiB]: KiB,
    [DataUnit.MiB]: MiB,
    [DataUnit.GiB]: GiB,
    [DataUnit.TiB]: TiB,
    [DataUnit.PiB]: PiB,
};

export const convertToDataUnit = (bytes: number, unit: DataUnit, decimal: number = 2): number => {
    if (!Object.values(DataUnit).includes(unit)) {
        throw new Error(`Unhandled unit "${unit}"`);
    }

    const result = bytes / multiplies[unit];
    if (decimal === 0) {
        return Math.floor(result);
    }

    const k = Math.pow(10, decimal);

    return Math.floor(result * k) / k;
};

export const usagePercentage = (total: number, used: number): number => {
    if (total === 0 || used === 0) {
        return 0;
    }

    return Math.floor(used * 100 / total);
};

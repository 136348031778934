// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import { Translate } from '@plesk/ui-library';
import { SIZE } from 'common/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import List from 'common/components/List/List';

interface IClusterImportErrorDialogProps {
    isOpen: boolean;
    handleClose: () => void;
}

export type ClusterImportErrorDialogProps =
    IClusterImportErrorDialogProps &
    ReturnType<typeof mapStateToProps>;

const columns = [{
    key: 'colId',
    title: <Translate content="clusterImport.errorDialog.list.id" />,
    width: '5%',
}, {
    key: 'colName',
    title: <Translate content="clusterImport.errorDialog.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
    width: '10%',
}, {
    key: 'colReason',
    title: <Translate content="clusterImport.errorDialog.list.reason" />,
    width: '85%',
    cellProps: {
        style: { whiteSpace: 'normal' },
    },
}];

export const ClusterImportErrorDialog: React.FC<ClusterImportErrorDialogProps> = ({
    item,
    isOpen,
    handleClose,
}) => {
    const data = item.errors_on_import.map(error => ({
        colId: error.source_id,
        colName: error.source_name,
        colReason: error.reason,
        key: item.id.toString(),
    }));

    return (
        <Dialog
            heading={<Translate content="clusterImport.errorDialog.title" params={{
                name: item.name,
            }}/>}
            closeHandler={handleClose}
            isOpen={isOpen}
            size={SIZE.MD}
        >
            <List
                columns={columns}
                data={data}
            />
        </Dialog>
    );
};


const mapStateToProps = (state: RootState) => ({
    item: state.clusterImport.item,
});

export default connect(mapStateToProps)(ClusterImportErrorDialog);
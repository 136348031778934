// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import enLocale from 'admin/core/I10n/en_US';
import commonEnLocale from 'common/I10n/en_US';
import { ConnectedRouter } from 'connected-react-router';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';
import { GlobalStyle } from 'common/styles/Global';
import { routes } from 'admin/core/router';
import { renderRoutes } from 'common/components/AppRoute';
import { ThemeProvider } from 'styled-components';
import '@plesk/ui-library/dist/plesk-ui-library.css';
import { ADMIN } from 'common/styles/variables';
import EchoProvider from 'admin/common/containers/EchoProvider/EchoProvider';
import { AdminGlobalStyle } from 'admin/common/styles/AdminGlobalStyle';
import { moduleMapper } from 'common/modules/app/language/helpers';
import LocaleProvider from 'common/components/LocaleProvider';
import Startup from 'admin/common/containers/Startup';
import FeaturesRegister from 'common/containers/FeaturesRegister/FeaturesRegister';
import {
    history,
    persistor,
    store,
} from 'admin/core/store';
import { Redirect } from 'react-router';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import { runGC as runKeypairCacheGC } from 'admin/computeResource/services/cache';

export const defaultLocale = { ...enLocale, ...commonEnLocale };

export const loadAdminLocales = (locale: string) => Promise.all([
    import(`../../admin/core/I10n/${locale}`).then(moduleMapper),
    import(`../../common/I10n/${locale}`).then(moduleMapper),
])
    .then(m => m.reduce((acc, cur) => ({ ...acc, ...cur }), {}))
    .catch(() => null);

export const AdminApp: React.FC = () => {
    if ('Cypress' in window) {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        (window as any).adminStore = store;
    }

    runKeypairCacheGC();

    return (
        <Provider store={store}>
            <FeaturesRegister/>
            <PersistGate loading={null} persistor={persistor}>
                <ConnectedRouter history={history}>
                    <EchoProvider>
                        <Startup>
                            <ThemeProvider theme={ADMIN.THEME}>
                                <LocaleProvider
                                    defaultMessages={defaultLocale}
                                    loadLocales={loadAdminLocales}
                                >
                                    <AdminGlobalStyle />
                                    <GlobalStyle />
                                    {renderRoutes(routes, () => (
                                        <Redirect to={[ADMIN_ROUTE_PREFIX, 'dashboard'].join('/')} />
                                    ))}
                                </LocaleProvider>
                            </ThemeProvider>
                        </Startup>
                    </EchoProvider>
                </ConnectedRouter>
            </PersistGate>
        </Provider>
    );
};

export default AdminApp;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { EventName } from 'common/api/resources/EventHandler';

export enum EVENT_HANDLER_TABS {
    WEBHOOKS_TAB = '#webhooks',
}

export enum OPENED_DIALOG {
    WEBHOOKS,
    NONE,
}

export const TABLE_ACTIONS = {
    ENABLE: 'enable',
    EDIT: 'edit',
    REMOVE: 'remove',
};

export const eventNameTranslations = {
    [EventName.ADD_OR_REMOVE_ADDITIONAL_IP]: 'eventHandler.events.addOrRemoveAdditionalIp',
    [EventName.SUSPEND_SERVER]: 'eventHandler.events.suspendServer',
    [EventName.RESUME_SERVER]: 'eventHandler.events.resumeServer',
    [EventName.DELETE_SERVER]: 'eventHandler.events.deleteServer',
    [EventName.CHANGE_SERVER_HOSTNAME]: 'eventHandler.events.changeServerHostname',
    [EventName.CHANGE_SERVER_ROOT_PASSWORD]: 'eventHandler.events.changeServerRootPassword',
    [EventName.REINSTALL_SERVER]: 'eventHandler.events.reinstallServer',
};

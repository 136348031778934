// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { IpBlockType } from 'common/api/resources/IpBlock';
import {
    ContentName,
    IpBlockTypeItem,
} from 'admin/computeResourceVm/components/IpBlockType/Styles';

export type IIpBlockTypeCardProps = {
    isSelected: boolean;
    onSelect: (ipBlockTypes: IpBlockType[]) => void;
    disabled?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const IpBlockTypeCard: React.FC<IIpBlockTypeCardProps> = ({
    isSelected,
    onSelect,
    disabled = false,
    children,
}) => (
    <IpBlockTypeItem
        isSelected={isSelected}
        onClick={disabled ? undefined : onSelect}
        isDisabled={disabled}
    >
        <ContentName>
            { children }
        </ContentName>
    </IpBlockTypeItem>
);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    GridCol,
    GridSizeProps,
    Item,
} from '@plesk/ui-library';
import { ItemText } from 'common/components/ServerTabs/SettingsTab/Styles';

export interface ISettingsPropertyCardProps {
    title: React.ReactNode;
    titleId?: string;
    text?: React.ReactNode;
    'data-cy'?: string;
}

export type SettingsPropertyCardProps =
    ISettingsPropertyCardProps &
    GridSizeProps;

const SettingsPropertyCard: React.FC<React.PropsWithChildren<SettingsPropertyCardProps>> = ({
    title,
    titleId,
    text,
    'data-cy': dataCy,
    children,
    ...grigSizeProps
}) => (
    <GridCol {...grigSizeProps} data-cy={dataCy}>
        <Item
            title={
                <h3 id={titleId}>{title}</h3>
            }
            view="card"
        >
            {children}
            {text ? (
                <ItemText>{text}</ItemText>
            ) : null}
        </Item>
    </GridCol>
);

export default SettingsPropertyCard;

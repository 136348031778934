// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import AuthLogin from 'admin/auth/containers/AuthLogin/AuthLogin';
import AuthByLink from 'admin/auth/containers/AuthByLink/AuthByLink';
import ForgotPassword from 'common/modules/auth/containers/ForgotPassword/ForgotPassword';
import AuthLayout from 'common/modules/auth/layouts/AuthLayout/AuthLayout';
import ComputeResources from 'admin/computeResource/list/containers/ComputeResources';
import ComputeResourcePage from 'admin/computeResource/page/containers/ComputeResourcePage';
import Tasks from 'admin/task/containers/Tasks';
import Role from 'admin/role/containers/Role';
import IpBlock from 'admin/ipBlock/containers/IpBlock';
import IpTable from 'admin/ipBlock/containers/IpTable';
import User from 'admin/user/containers/Users/Users';
import Layout from 'admin/common/containers/Layout';
import Plan from 'admin/plan/containers/Plan';
import Icon from 'admin/icon/containers/Icons';
import OsImage from 'admin/osImage/containers/OsImage';
import Location from 'admin/location/containers/Location';
import Application from 'admin/application/containers/Application';
import ApplicationForm from 'admin/application/containers/ApplicationForm/ApplicationForm';
import NetworkRulesSettings from 'admin/settings/containers/Settings/NetworkRulesSettings';
import MailSettings from 'admin/settings/containers/Settings/MailSettings';
import UserAreaSettings from 'admin/settings/containers/Settings/UserAreaSettings';
import SystemUpdatesSettings from 'admin/settings/containers/Settings/SystemUpdatesSettings';
import DNSSettings from 'admin/settings/containers/Settings/DNSSettings';
import BillingIntegrationSettings from 'admin/settings/containers/Settings/BillingIntegrationSettings';
import ComputeResourceSettings from 'admin/settings/containers/Settings/ComputeResourceSettings';
import SshKeys from 'admin/sshKey/containers/SshKeys';
import LimitGroup from 'admin/limitGroup/containers/LimitGroups';
import Dashboard from 'admin/dashboard/containers/Dashboard';
import License from 'admin/license/License';
import ComputeResourceVncDialog from 'admin/computeResource/page/containers/dialogs/vncDialog/ComputeResourceVncDialog';
import Help from 'admin/help/components/Help';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import Update from 'admin/update/containers/Update';
import BackupNodes from 'admin/backupNode/containers/BackupNodes';
import ComputeResourceVms from 'admin/computeResourceVm/components/ComputeResourceVms/ComputeResourceVms';
import ComputeResourceVm from 'admin/computeResourceVm/containers/ComputeResourceVm';
import ComputeResourceVmCreate
    from 'admin/computeResourceVm/containers/ComputeResourceVmCreate/ComputeResourceVmCreate';
import Documentation from 'common/containers/documentation/Documentation';
import Backups from 'admin/backup/containers/Backups/Backups';
import Language from 'admin/language/containers/Language';
import Storages from 'admin/storage/containers/Storages';
import ApiTokens from 'admin/apiToken/containers/ApiTokens';
import ActivityLogs from 'admin/activityLog/containers/ActivityLogs';
import GeneralSettings from 'admin/settings/containers/Settings/GeneralSettings';
import ExternalIntegrations from 'admin/externalIntegration/containers/ExternalIntegrations';
import { NoLayout } from 'common/components/NoLayout';
import {
    IAppRoute,
    ILayoutRoute,
} from 'common/components/AppRoute';
import NotificationsSettings from 'admin/settings/containers/Settings/NotificationsSettings';
import ManagementNodeBackups from 'admin/managementNodeBackup/containers/ManagementNodeBackups/ManagementNodeBackups';
import ClusterImport from 'admin/clusterImport/containers/ClusterImports';
import ClusterImportComputeResources from 'admin/clusterImport/containers/ClusterImportComputeResources';
import EventHandlers from 'admin/eventHandler/containers/EventHandlers/EventHandlers';
import Vlans from 'admin/vlan/containers/Vlans';
import IsoImages from 'admin/isoImage/containers/IsoImages';
import Account from 'admin/account/containers/Account';
import TwoFactorAuthLogin from 'common/modules/auth/containers/TwoFactorAuthLogin/TwoFactorAuthLogin';

const routesMapper = (appRoutes: IAppRoute[]) => appRoutes.map(route => {
    route.path = `${ADMIN_ROUTE_PREFIX}${route.path}`;

    return route;
});

export const routes: ILayoutRoute[] = [
    {
        layout: AuthLayout,
        routes: routesMapper([
            {
                component: AuthLogin,
                path: '/login',
            },
            {
                component: TwoFactorAuthLogin,
                path: '/login/2fa',
            },
            {
                component: AuthByLink,
                path: '/auth_by_link',
            },
            {
                component: ForgotPassword,
                path: '/forgot_password',
            },
        ]),
    },
    {
        layout: Layout,
        routes: routesMapper([
            {
                component: BackupNodes,
                path: '/backup_nodes',
            },
            {
                component: Backups,
                path: '/backups',
            },
            {
                component: ManagementNodeBackups,
                path: '/management_node_backups',
            },
            {
                component: Role,
                path: '/roles',
            },
            {
                component: Dashboard,
                path: '/dashboard',
            },
            {
                component: User,
                path: '/users',
            },
            {
                component: SshKeys,
                path: '/ssh_keys',
            },
            {
                component: Storages,
                path: '/storage',
            },
            {
                component: ComputeResources,
                path: '/compute_resources',
            },
            {
                component: ComputeResourcePage,
                path: '/compute_resources/:id',
            },
            {
                component: Tasks,
                path: '/tasks',
            },
            {
                component: IpBlock,
                path: '/ip_blocks',
            },
            {
                component: Vlans,
                path: '/vlans',
            },
            {
                component: Icon,
                path: '/icons',
            },
            {
                component: Plan,
                path: '/plans',
            },
            {
                component: IpTable,
                path: '/ip_blocks/:id/ips',
            },
            {
                component: OsImage,
                path: '/os_images',
            },
            {
                component: Application,
                path: '/applications',
            },
            {
                component: ApplicationForm,
                path: '/applications/:id',
            },
            {
                component: IsoImages,
                path: '/iso_images',
            },
            {
                component: ExternalIntegrations,
                path: '/external_integrations',
            },
            {
                component: LimitGroup,
                path: '/limit_groups',
            },
            {
                component: Location,
                path: '/locations',
            },
            {
                component: Update,
                path: '/updates',
            },
            {
                component: Help,
                path: '/help',
            },
            {
                component: License,
                path: '/license',
            },
            {
                component: GeneralSettings,
                path: '/settings/general',
            },
            {
                component: NetworkRulesSettings,
                path: '/settings/network_rules',
            },
            {
                component: NotificationsSettings,
                path: '/settings/notifications',
            },
            {
                component: MailSettings,
                path: '/settings/mail',
            },
            {
                component: UserAreaSettings,
                path: '/settings/user_area',
            },
            {
                component: SystemUpdatesSettings,
                path: '/settings/system_updates',
            },
            {
                component: DNSSettings,
                path: '/settings/dns',
            },
            {
                component: Language,
                path: '/settings/languages',
            },
            {
                component: BillingIntegrationSettings,
                path: '/settings/billing_integration',
            },
            {
                component: ComputeResourceSettings,
                path: '/settings/compute_resource',
            },
            {
                component: ComputeResourceVms,
                path: '/servers',
            },
            {
                component: ComputeResourceVmCreate,
                path: '/servers/create',
            },
            {
                component: ComputeResourceVm,
                path: '/servers/:id',
            },
            {
                component: ApiTokens,
                path: '/api_tokens',
            },
            {
                component: ActivityLogs,
                path: '/activity_logs',
            },
            {
                component: ClusterImport,
                path: '/cluster_imports',
            },
            {
                component: ClusterImportComputeResources,
                path: '/cluster_imports/:id/compute_resources',
            },
            {
                component: EventHandlers,
                path: '/settings/event_handlers',
            },
            {
                component: Account,
                path: '/account',
            },
        ]),
    },
    {
        layout: NoLayout,
        routes: routesMapper([
            {
                component: Documentation,
                path: '/documentation',
            },
            {
                component: ComputeResourceVncDialog,
                path: '/vnc_client/:id',
            },
        ]),
    },
];

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import * as authActions from 'common/modules/auth/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IUpdatePasswordRequest } from 'common/api/resources/Auth';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RootState } from 'client/core/store';
import { HTTP_CODES } from 'common/api/constants';
import {
    passwordRule,
    validate,
} from 'common/validator';
import {
    StyledForm,
    Text,
} from 'common/modules/auth/components/StyledForm';
import {
    Button,
    Form,
    Section,
    Translate,
} from '@plesk/ui-library';
import LogoBox from 'common/modules/auth/components/LogoBox/LogoBox';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import {
    AuthContainer,
    Header,
} from 'common/modules/auth/Styles';

export type ResetPasswordProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ResetPassword: React.FC<ResetPasswordProps> = ({
    location,
    authActions: { updatePassword },
    formErrorsActions: { setFormErrors },
    formErrors,
    isLoading,
    auth,
}) => {
    const [errorMessage, setErrorMessage] = React.useState('');
    const token = new URLSearchParams(location.search).get('token');

    const handleSubmit = async(values: IUpdatePasswordRequest) => {
        const { password } = values;

        const errors = validate<IUpdatePasswordRequest>(values, {
            password: passwordRule(<Translate content="validate.badPassword" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        setErrorMessage('');

        try {
            await updatePassword({
                token,
                password,
            });
        } catch (e) {
            if (e.response.status === HTTP_CODES.BAD_REQUEST || e.response.status >= HTTP_CODES.VALIDATION_ERROR) {
                setErrorMessage(e.response.data.message);
                return;
            }
        }
    };

    if (auth.isAuth || !token) {
        return (<Redirect to="/" />);
    }

    return (
        <AuthContainer>
            <LogoBox/>
            <Header>
                <Translate content="auth.resetPasswordTitle"/>
            </Header>
            <StyledForm>
                <Form
                    onSubmit={handleSubmit}
                    applyButton={false}
                    vertical={true}
                    errors={formErrors}
                    cancelButton={false}
                    hideRequiredLegend={true}
                    submitButton={false}
                >
                    <Section>
                        <FormFieldPassword
                            size="fill"
                            name="password"
                            label={<Translate content="auth.password"/>}
                            description={<Translate content="account.settings.passwordDescription" />}
                            required={false}
                        />
                        <Text>{errorMessage}</Text>
                        <Button
                            fill={true}
                            intent="primary"
                            type="submit"
                            state={isLoading ? 'loading' : null}
                        >
                            <Translate content="auth.updatePasswordBtn"/>
                        </Button>
                    </Section>
                </Form>
            </StyledForm>
        </AuthContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    formErrors: state.app.formErrors,
    isLoading: (
        state.app.loadingFlags.has(LOADING_FLAGS.UPDATE_PASSWORD) ||
        state.app.loadingFlags.has(LOADING_FLAGS.USER)
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ResetPassword));

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import isEmpty from 'validator/lib/isEmpty';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import * as limitGroupActions from 'admin/limitGroup/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { validate } from 'common/validator';
import { ILimitGroupRequest } from 'common/api/resources/LimitGroup';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    Form,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Limit } from 'common/components/Limit/Limit';
import { FORM } from 'admin/limitGroup/constants/tests';

interface ILimitGroupFormProps {
    onCreated: () => void;
}

export type LimitGroupFormProps =
    ILimitGroupFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const LimitGroupForm: React.FC<LimitGroupFormProps> = ({
    onCreated,
    isItemCreating,
    formErrors,
    limitGroup,
    limitGroupActions: {
        createLimitGroup,
        updateLimitGroup,
        unsetLimitGroupItem,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...limitGroup });

    React.useEffect(() => () => {
        clearFormErrors();
        unsetLimitGroupItem();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: ILimitGroupRequest) => {
        const errors = validate<ILimitGroupRequest>(values, {
            name: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            vms: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            running_vms: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            additional_ips: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            additional_ipv6: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            iso_images: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
            iso_images_size: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            values.id ? await updateLimitGroup(values.id, values) : await createLimitGroup(values);
            onCreated();
        } catch (e) {
            throw e;
        }
    };

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    const handleToggle = (field: string) => () => {
        const limit = submitValues[field];

        setSubmitValues({
            ...submitValues,
            [field]: {
                limit: !limit.is_enabled ? limit.limit : 0,
                is_enabled: !limit.is_enabled,
            },
        });
    };

    return (
        <>
            <Form
                id="limitGroupForm"
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
                footerClassName="hidden"
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size="fill"
                        label={<Translate content="limitGroup.form.name" />}
                        required={true}
                    />
                    <Limit
                        onChangeIsEnabled={handleToggle('vms')}
                        limit={submitValues.vms}
                        fieldName="vms[limit]"
                        label={<Translate content="limitGroup.form.vms" />}
                        required={false}
                        data-cy={FORM.VMS}
                    />
                    <Limit
                        onChangeIsEnabled={handleToggle('running_vms')}
                        limit={submitValues.running_vms}
                        fieldName="running_vms[limit]"
                        label={<Translate content="limitGroup.form.runningVms" />}
                        required={false}
                        data-cy={FORM.RUNNING_VMS}
                    />
                    <Limit
                        onChangeIsEnabled={handleToggle('additional_ips')}
                        limit={submitValues.additional_ips}
                        fieldName="additional_ips[limit]"
                        label={<Translate content="limitGroup.form.additionalIpv4" />}
                        required={false}
                        data-cy={FORM.ADDITIONAL_IPV4}
                    />
                    <Limit
                        onChangeIsEnabled={handleToggle('additional_ipv6')}
                        limit={submitValues.additional_ipv6}
                        fieldName="additional_ipv6[limit]"
                        label={<Translate content="limitGroup.form.additionalIpv6" />}
                        required={false}
                        data-cy={FORM.ADDITIONAL_IPV6}
                    />
                    <Limit
                        onChangeIsEnabled={handleToggle('iso_images')}
                        limit={submitValues.iso_images}
                        fieldName="iso_images[limit]"
                        label={<Translate content="limitGroup.form.isoImages" />}
                        required={false}
                        data-cy={FORM.ISO_IMAGES}
                    />
                    <Limit
                        onChangeIsEnabled={handleToggle('iso_images_size')}
                        limit={submitValues.iso_images_size}
                        fieldName="iso_images_size[limit]"
                        label={<Translate content="limitGroup.form.isoImagesSize" />}
                        required={false}
                        data-cy={FORM.ISO_IMAGES_SIZE}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="limitGroupForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemCreating}
            >
                <Translate content="limitGroup.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    limitGroup: state.limitGroup.item,
    formErrors: nestStringProperties(state),
    isItemCreating: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_LIMIT_GROUP_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    limitGroupActions: bindActionCreators(limitGroupActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitGroupForm);

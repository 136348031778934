// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import {
    CodeEditor,
    Form,
    FormField,
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldText,
    Section,
    setIn,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import React from 'react';
import * as eventHandlerActions from 'admin/eventHandler/actions';
import {
    EventName,
    IEventHandlerRequest,
    IEventHandlerResponse,
} from 'common/api/resources/EventHandler';
import { StyledCodeEditor } from 'admin/common/styles/CodeEditor';
import {
    domainRule,
    requiredRule,
    validate,
} from 'common/validator';
import { eventNameTranslations } from 'admin/eventHandler/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';

interface IWebhookFormProps {
    onSubmit: () => void;
}

export type WebhookFormProps =
    IWebhookFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const eventHandlerToRequest = (eventHandler: IEventHandlerResponse): IEventHandlerRequest => {
    const { id, ...request } = eventHandler;
    return {
        ...request,
        action_params: {
            ...request.action_params,
            request_params: prettifyRequestParams(
                id === 0 ? JSON.stringify(defaultRequestParams) : request.action_params.request_params
            ),
        },
    };
};

const defaultRequestParams = { secret: '' };
const prettifyRequestParams = (requestParams: string) => JSON.stringify(JSON.parse(requestParams), null, '\t');

export const WebhookForm: React.FC<WebhookFormProps> = ({
    item,
    isSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    eventHandlerActions: {
        createEventHandler,
        updateEventHandler,
        unsetEventHandlerItem,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IEventHandlerRequest>(eventHandlerToRequest(item));
    const translate = useTranslate();

    const eventNameOptions = React.useMemo(() => Object.values(EventName).map((evenName) => (
        <option key={evenName} value={evenName}>
            {translate(eventNameTranslations[evenName])}
        </option>
    )), [translate]);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: IEventHandlerRequest) => {
        const rules = {
            'name': requiredRule(<Translate content="validate.fieldRequired" />),
            'action_params.url': requiredRule(<Translate content="validate.fieldRequired" />),
            'action_params.request_params': requiredRule(<Translate content="validate.fieldRequired" />),
            events: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        if (values.action_params.url !== '') {
            rules['action_params.url'] = domainRule(<Translate content="validate.badUrl" />);
        }

        const errors = validate<IEventHandlerRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        clearFormErrors();

        if (item.id === 0) {
            await createEventHandler(values);
        } else {
            await updateEventHandler(item.id, values);
        }

        onSubmit();
    };

    const handleRequestParamsChange = (value: string) => {
        setSubmitValues({
            ...submitValues,
            action_params: {
                ...submitValues.action_params,
                request_params: value,
            },
        });
    };

    React.useEffect(() => () => {
        clearFormErrors();
        unsetEventHandlerItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Section>
                <Form
                    id="webhookForm"
                    values={submitValues}
                    onFieldChange={handleFieldChange}
                    onSubmit={handleSubmit}
                    footerClassName="hidden"
                    vertical={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    errors={formErrors}
                >
                    <FormFieldText
                        name="name"
                        size={SIZE.FILL}
                        label={<Translate content="eventHandler.form.name" />}
                        required={true}
                    />
                    <FormFieldText
                        name="action_params[url]"
                        size={SIZE.FILL}
                        label={<Translate content="eventHandler.webhooks.form.url" />}
                        required={true}
                    />
                    <FormFieldSelect
                        name="events"
                        label={<Translate content="eventHandler.form.events" />}
                        size={SIZE.FILL}
                        required={true}
                        multiple={true}
                        clearable={true}
                        searchable={true}
                    >
                        {eventNameOptions}
                    </FormFieldSelect>
                    <FormField
                        name="action_params[request_params]"
                        label={<Translate content="eventHandler.webhooks.form.requestParams" />}
                        required={true}
                    >
                        <StyledCodeEditor height={150}>
                            <CodeEditor
                                mode="javascript"
                                onChange={handleRequestParamsChange}
                                options={{
                                    tabSize: 2,
                                    smartIndent: false,
                                }}
                            >
                                {submitValues.action_params.request_params}
                            </CodeEditor>
                        </StyledCodeEditor>
                    </FormField>
                    <FormFieldCheckbox
                        name="is_enabled"
                        label={<Translate content="eventHandler.form.enabled"/>}
                    />
                </Form>
            </Section>
            <Button
                type="submit"
                form="webhookForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isSaving}
            >
                <Translate content="eventHandler.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.eventHandler.item,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.EVENT_HANDLER_ITEM_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    eventHandlerActions: bindActionCreators(eventHandlerActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebhookForm);

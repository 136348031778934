// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { SERVER } from 'admin/computeResource/constants/tests';
import {
    Button,
    Text,
    Toolbar,
    ToolbarGroup,
    Translate,
} from '@plesk/ui-library';
import { FilterContainer } from 'admin/common/components/Filters/Styles';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { ComputeResourceVmStatusFilter } from 'admin/common/components/Filters/ComputeResourceVmStatusFilter/ComputeResourceVmStatusFilter';
import { ComputeResourceFilter } from 'admin/common/components/Filters/ComputeResourceFilter/ComputeResourceFilter';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';
import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { IComputeResourceVmFilters } from 'admin/computeResourceVm/containers/ComputeResourceVmTable/ComputeResourceVmTable';
import ButtonWithInputConfirmation from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';
import { ICONS } from 'common/constants';
import CopyText from 'common/containers/CopyText/CopyText';
import { VirtualizationTypeFilter } from 'admin/common/components/Filters/VirtualizationTypeFilter/VirtualizationTypeFilter';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export const DELETE_CONFIRMATION: string = 'delete';

export interface IComputeResourceVmOperationsProps {
    selection: number[];
    setSelection: (items: string[]) => void;
    filters: IComputeResourceVmFilters;
    setFilters: (filters: IComputeResourceVmFilters) => void;
    withFilters?: boolean;
    openMigrationDialog: () => void;
    handleBatchDelete: () => void;
}

export type ComputeResourceVmOperationsProps =
    IComputeResourceVmOperationsProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceVmOperations: React.FC<ComputeResourceVmOperationsProps> = ({
    computeResourceVms,
    selection,
    setSelection,
    filters,
    setFilters,
    computeResourceVmActions: {
        startComputeResourceVms,
        restartComputeResourceVms,
        stopComputeResourceVms,
        batchInstallGuestTools,
    },
    withFilters,
    isRunningBatchStart,
    isRunningBatchStop,
    isRunningBatchRestart,
    isRunningBatchDelete,
    isRunningBatchInstallGuestTools,
    canBatchStart,
    canBatchStop,
    canBatchRestart,
    canBatchDelete,
    openMigrationDialog,
    handleBatchDelete,
}) => {
    const isBatchOperationsDisabled = isRunningBatchStart
        || isRunningBatchStop
        || isRunningBatchRestart
        || isRunningBatchDelete
        || isRunningBatchInstallGuestTools
        || selection.length === 0;
    const handleBatchRestart = () => {
        restartComputeResourceVms(selection, { force: false });
        setSelection([]);
    };

    const handleBatchStart = () => {
        startComputeResourceVms(selection);
        setSelection([]);
    };

    const handleBatchStop = () => {
        stopComputeResourceVms(selection, { force: false });
        setSelection([]);
    };

    const handleBatchInstallGuestTools = () => {
        batchInstallGuestTools(selection);
        setSelection([]);
    };

    const handleFilterChange = (filterName: string) => (option: ValueType<ISelectRequiredOption>) => {
        setFilters({ ...filters, [filterName]: option });
    };

    return (
        <Toolbar>
            {canBatchRestart && (
                <ToolbarGroup title="restart-action">
                    <ButtonWithConfirmation
                        data-cy={SERVER.BATCH_ACTIONS.RESTART}
                        disabled={isBatchOperationsDisabled}
                        isLoading={isRunningBatchRestart}
                        confirmationButtonGhost={false}
                        confirmationButtonText={<Translate content="computeResource.servers.batchActions.restart" />}
                        translations={{
                            title: (
                                <Translate content="computeResource.servers.batchActions.batchRestartPopover.title" />
                            ),
                            button: (
                                <Translate content="computeResource.servers.batchActions.batchRestartPopover.button" />
                            ),
                            tooltip: (
                                <Translate content="computeResource.servers.batchActions.batchRestartPopover.tooltip" />
                            ),
                        }}
                        icon={ICONS.RESET}
                        handleConfirm={handleBatchRestart}
                    />
                </ToolbarGroup>
            )}
            {canBatchStart && (
                <ToolbarGroup title="start-action">
                    <ButtonWithConfirmation
                        data-cy={SERVER.BATCH_ACTIONS.START}
                        disabled={isBatchOperationsDisabled}
                        isLoading={isRunningBatchStart}
                        confirmationButtonGhost={false}
                        confirmationButtonText={<Translate content="computeResource.servers.batchActions.start" />}
                        translations={{
                            title: (
                                <Translate content="computeResource.servers.batchActions.batchStartPopover.title" />
                            ),
                            button: (
                                <Translate content="computeResource.servers.batchActions.batchStartPopover.button" />
                            ),
                            tooltip: (
                                <Translate content="computeResource.servers.batchActions.batchStartPopover.tooltip" />
                            ),
                        }}
                        icon={ICONS.START_CIRCLE}
                        handleConfirm={handleBatchStart}
                    />
                </ToolbarGroup>
            )}
            {canBatchStop && (
                <ToolbarGroup title="stop-action">
                    <ButtonWithConfirmation
                        data-cy={SERVER.BATCH_ACTIONS.STOP}
                        disabled={isBatchOperationsDisabled}
                        isLoading={isRunningBatchStop}
                        confirmationButtonGhost={false}
                        confirmationButtonText={<Translate content="computeResource.servers.batchActions.stop" />}
                        translations={{
                            title: (
                                <Translate content="computeResource.servers.batchActions.batchStopPopover.title" />
                            ),
                            button: (
                                <Translate content="computeResource.servers.batchActions.batchStopPopover.button" />
                            ),
                            tooltip: (
                                <Translate content="computeResource.servers.batchActions.batchStopPopover.tooltip" />
                            ),
                        }}
                        icon="stop-circle"
                        handleConfirm={handleBatchStop}
                    />
                </ToolbarGroup>
            )}
            <ToolbarGroup title="migrate-action">
                <Button
                    data-cy={SERVER.BATCH_ACTIONS.MIGRATE}
                    disabled={isBatchOperationsDisabled}
                    ghost={false}
                    icon={ICONS.TRANSFER}
                    onClick={openMigrationDialog}
                >
                    <Translate content="computeResource.servers.batchActions.migrate" />
                </Button>
            </ToolbarGroup>
            <ToolbarGroup title="remove-action">
                <ButtonWithInputConfirmation
                    data-cy={SERVER.BATCH_ACTIONS.REMOVE}
                    disabled={isBatchOperationsDisabled || !canBatchDelete}
                    isLoading={isRunningBatchDelete}
                    ghost={false}
                    confirmation={DELETE_CONFIRMATION}
                    placement={'bottom'}
                    translations={{
                        title: (
                            <Translate content="computeResource.servers.batchActions.deleteConfirmation.title" />
                        ),
                        label: (
                            <Translate
                                content="computeResource.servers.batchActions.deleteConfirmation.label"
                                params={{ confirmation: <CopyText isInline={true}>{DELETE_CONFIRMATION}</CopyText> }}
                            />
                        ),
                        text: (
                            <Translate
                                content="computeResource.servers.batchActions.deleteConfirmation.text"
                                params={{ count: <Text bold>{selection.length}</Text> }}
                            />
                        ),
                        button: (
                            <Translate content="computeResource.servers.batchActions.delete" />
                        ),
                        confirmationButton: (
                            <Translate content="computeResource.servers.batchActions.batchDeletePopover.button" />
                        ),
                        tooltip: (canBatchDelete
                            ? <Translate content="computeResource.servers.batchActions.batchDeletePopover.tooltip" />
                            : <Translate content="servers.delete.cannotDelete" />
                        ),
                    }}
                    icon={ICONS.RECYCLE}
                    handleConfirm={handleBatchDelete}
                />
            </ToolbarGroup>
            <ToolbarGroup title="install-guest-tools-action">
                <ButtonWithConfirmation
                    data-cy={SERVER.BATCH_ACTIONS.INSTALL_GUEST_TOOLS}
                    disabled={isBatchOperationsDisabled}
                    isLoading={isRunningBatchInstallGuestTools}
                    confirmationButtonGhost={false}
                    confirmationButtonText={<Translate content="computeResource.servers.batchActions.installGuestTools.button" />}
                    translations={{
                        title: (
                            <Translate content="computeResource.servers.batchActions.installGuestTools.confirmation.title" />
                        ),
                        button: (
                            <Translate content="computeResource.servers.batchActions.installGuestTools.confirmation.button" />
                        ),
                        tooltip: (
                            <Translate content="computeResource.servers.batchActions.installGuestTools.tooltip" />
                        ),
                    }}
                    handleConfirm={handleBatchInstallGuestTools}
                />
            </ToolbarGroup>
            {withFilters && (
                <FilterContainer>
                    <FilterForm alignRight={true}>
                        <ComputeResourceVmStatusFilter
                            onChange={handleFilterChange('status')}
                            value={filters.status}
                        />
                        <ComputeResourceFilter
                            onChange={handleFilterChange('computeResource')}
                            value={filters.computeResource}
                        />
                        <UserFilter
                            onChange={handleFilterChange('user')}
                            value={filters.user}
                        />
                        <VirtualizationTypeFilter
                            onChange={handleFilterChange('virtualizationType')}
                            value={filters.virtualizationType}
                        />
                    </FilterForm>
                </FilterContainer>
            )}
        </Toolbar>
    );
};


const mapStateToProps = (state: RootState) => ({
    computeResourceVms: state.computeResourceVm.list,
    isRunningBatchStart: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_START),
    isRunningBatchStop: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_STOP),
    isRunningBatchRestart: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_RESTART),
    isRunningBatchDelete: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_DELETE),
    isRunningBatchInstallGuestTools: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BATCH_INSTALL_GUEST_TOOLS),
    canBatchStart: hasPermission(state, PERMISSION_LIST.START_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
    canBatchStop: hasPermission(state, PERMISSION_LIST.STOP_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
    canBatchRestart: hasPermission(state, PERMISSION_LIST.RESTART_SERVERS, PERMISSION_LIST.MANAGE_SERVERS),
    canBatchDelete: hasPermission(state, PERMISSION_LIST.DELETE_SERVERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceVmOperations);

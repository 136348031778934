// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import NotFound from 'common/components/ErrorPages/NotFound/NotFound';
import {
    Route,
    RouteComponentProps,
    Switch,
} from 'react-router';
import { isAdminSite } from 'common/helpers/core';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';

export interface IAppRoute {
    path: string;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    component: React.ComponentType<any>;
    exact?: boolean;
}

export interface ILayoutRoute {
    layout: React.ComponentType<React.PropsWithChildren>;
    routes: IAppRoute[];
}

const Layout: React.FC<ILayoutRoute> = ({ layout: LayoutComponent, routes }) => (
    <Route exact={true} path={routes.map(route => route.path)}>
        <LayoutComponent>
            <Switch>
                {routes.map(route => <Route exact={true} key={route.path} {...route} />)}
                <Route component={NotFound} />
            </Switch>
        </LayoutComponent>
    </Route>
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const renderRoutes = (routes: ILayoutRoute[], rootFallback?: React.ComponentType<RouteComponentProps<any>> | React.ComponentType<any>) => (
    <>
        {rootFallback && (
            <Route exact={true} path={isAdminSite() ? ADMIN_ROUTE_PREFIX : '/'} component={rootFallback} />
        )}
        {routes.map(
            (route, i) => (<Layout key={i} {...route} />)
        )}
    </>
);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import * as backupNodeActions from 'admin/backupNode/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { StyledTable } from 'common/components/styles/StyledTable';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { StyledActions } from 'common/components/Actions/Styles';
import { Dialog } from 'common/components/Dialog/Dialog';
import BackupNodeForm from 'admin/backupNode/containers/BackupNodeForm';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { humanizeType } from 'admin/backupNode/constants';
import { dataCySelector } from 'common/tests/selectors';
import {
    TABLE,
    TABLE_ACTIONS,
} from 'admin/backupNode/constants/tests';
import {
    Action,
    Icon,
    List,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    DataUnit,
    convertToDataUnit,
} from 'common/helpers/units';
import { getActionColumnProps } from 'common/helpers/list';

export type BackupNodesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="backupNode.list.id" />,
}, {
    width: '15%',
    key: 'colName',
    title: <Translate content="backupNode.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colType',
    title: <Translate content="backupNode.list.type" />,
}, {
    key: 'colComputeResources',
    title: <Translate content="backupNode.list.computeResources" />,
}, {
    key: 'colBackups',
    title: <Translate content="backupNode.list.backups" />,
}, {
    key: 'colUsage',
    title: <Translate content="backupNode.list.usage" />,
}, {
    key: 'colUsedForManagementNode',
    width: '1%',
    title: <Translate content="backupNode.list.usedForManagementNode" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

export const BackupNodes: React.FC<BackupNodesProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    managementsNodeBackupsBackupNodeId,
    backupNodesActions: {
        getBackupNodes,
        getBackupNode,
        loadBackupNodesOnScroll,
        removeBackupNode,
    },
    formErrorsActions: {
        clearFormErrors,
    },
    unsetBackupNodeItem: unsetItem,
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getBackupNodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemove = (id: number) => async () => await removeBackupNode(id);
    const handleEdit = (id: number) => async () => {
        getBackupNode(id);
        setDialogOpen(true);
    };

    const data = list.data.map((node) => {
        const actions = (
            <StyledActions>
                <Tooltip title={<Translate content="backupNode.tooltip.edit"/>}>
                    <Action
                        icon="pencil"
                        className="action-icon"
                        onClick={handleEdit(node.id)}
                        data-cy={dataCySelector(node.id, TABLE_ACTIONS.EDIT)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    disabled={!node.can_delete}
                    isLoading={node.is_deleting}
                    translations={{
                        title: (
                            <Translate content="backupNode.buttonWithConfirmation.title" />
                        ),
                        button: (
                            <Translate content="backupNode.buttonWithConfirmation.button" />
                        ),
                        tooltip: (
                            <Translate content={node.can_delete ?
                                'backupNode.buttonWithConfirmation.tooltip.enabled' :
                                'backupNode.buttonWithConfirmation.tooltip.disabled'
                            } />
                        ),
                    }}
                    handleConfirm={handleRemove(node.id)}
                    data-cy={dataCySelector(node.id, TABLE_ACTIONS.REMOVE)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            colId: node.id,
            colName: node.name,
            colType: humanizeType(node.type),
            colComputeResources: node.compute_resources_count,
            colBackups: node.backups_count,
            colUsage: convertToDataUnit(node.total_backups_size, DataUnit.GiB),
            colUsedForManagementNode: managementsNodeBackupsBackupNodeId === node.id
                ? (
                    <Icon
                        name="check-mark-circle"
                        intent="success"
                        data-cy={dataCySelector(node.id, TABLE.USED_FOR_MANAGEMENT_NODE_BACKUPS_ICON)}
                    />
                )
                : '',
            colActions: actions,
            key: node.id.toString(),
        };
    });

    const openCreateDialog = () => {
        unsetItem();
        setDialogOpen(true);
    };
    const closeDialog = () => {
        setDialogOpen(false);
        clearFormErrors();
    };

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="backupNode.title"/>}
                buttonText="backupNode.addBtn"
                buttonIcon="storage"
                onButtonClick={openCreateDialog}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadBackupNodesOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="backupNode.emptyView.title"
                                    description="backupNode.emptyView.description"
                                    buttonText="backupNode.emptyView.buttonText"
                                    onButtonClick={openCreateDialog}
                                    icon="storage"
                                />
                            }
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={<Translate content={item.id ? 'backupNode.dialog.editTitle' : 'backupNode.dialog.createTitle'} />}
                closeHandler={closeDialog}
                isOpen={isDialogOpen}
                size="xs"
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <BackupNodeForm onSubmit={closeDialog}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.backupNode.item,
    list: state.backupNode.list,
    isLoadingList: !state.backupNode.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_NODE_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_NODE_GET),
    managementsNodeBackupsBackupNodeId: state.settings.management_node_backup.backup_node_id,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupNodesActions: bindActionCreators(backupNodeActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    unsetBackupNodeItem: bindActionCreators(backupNodeActions.unsetBackupNodeItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupNodes);

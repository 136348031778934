// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as ipBlockActions from 'admin/ipBlock/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addItemsToPaginated,
    addToPaginated,
} from 'common/reducers/list';
import {
    IIpBlockResponse,
    IpBlockType,
    IpBlockListType,
} from 'common/api/resources/IpBlock';
import { IIpResponse } from 'common/api/resources/Ip';

interface IIpBlock {
    list: IPaginateApiResponse<IIpBlockResponse[]>;
    item: IIpBlockResponse;
    ipList: IPaginateApiResponse<IIpResponse[]>;
}

export type IpBlockAction = ActionType<typeof ipBlockActions>;
export type IpBlockState = Readonly<IIpBlock>;

export const initialIpBlockState: IIpBlockResponse = {
    id: 0,
    name: '',
    list_type: IpBlockListType.RANGE,
    type: IpBlockType.IPv4,
    gateway: '',
    netmask: '',
    ns_1: '',
    ns_2: '',
    compute_resources: [],
    from: '',
    to: '',
    reserved_ips_count: 0,
    is_deleting: false,
    range: '',
    subnet: '',
    total_ips_count: '',
    reverse_dns: {
        enabled: false,
        zone: '',
        template: '',
    },
};

export default combineReducers<IpBlockState, IpBlockAction>({
    list: (state = paginateInitialState, action: IpBlockAction) => {
        switch (action.type) {
        case getType(ipBlockActions.appendIpBlocks):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(ipBlockActions.addIpBlockItem):
            return addToPaginated(state, action.payload);
        case getType(ipBlockActions.setIpBlockList):
            return action.payload;
        case getType(ipBlockActions.removeIpBlockItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(ipBlockActions.removeIpBlockItems):
            const ipBlocks = state.data.reduce((prev: IIpBlockResponse[], current: IIpBlockResponse) => {
                if (action.payload.includes(current.id)) {
                    return prev;
                }

                return [...prev, current];
            }, []);
            return {
                ...state,
                data: ipBlocks,
            };
        case getType(ipBlockActions.setIpBlockItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        case getType(ipBlockActions.updateIpBlockItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        default:
            return state;
        }
    },
    item: (state = initialIpBlockState, action) => {
        switch (action.type) {
        case getType(ipBlockActions.setIpBlockItem):
            return action.payload;
        case getType(ipBlockActions.unsetIpBlockItem):
            return { ...initialIpBlockState };
        default:
            return state;
        }
    },
    ipList: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(ipBlockActions.addIpToBlockItem):
            return addToPaginated(state, action.payload);
        case getType(ipBlockActions.addIpsToBlockItem):
            return addItemsToPaginated(state, action.payload);
        case getType(ipBlockActions.removeIpItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(ipBlockActions.setIpBlockIps):
            return action.payload;
        case getType(ipBlockActions.appendIps):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(ipBlockActions.updateIpItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case getType(ipBlockActions.removeIpItems):
            const ips = state.data.reduce((prev: IIpResponse[], current: IIpResponse) => {
                if (action.payload.includes(current.id)) {
                    return prev;
                }

                return [...prev, current];
            }, []);
            return {
                ...state,
                data: ips,
            };
        case getType(ipBlockActions.setIpItemIsLoading):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_loading: action.payload.isLoading };
                    }

                    return item;
                }),
            };
        default:
            return state;
        }
    },
});

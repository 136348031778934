// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const SET_IPBLOCK_LIST = 'ipBlock/SET_IPBLOCK_LIST';
export const ADD_IPBLOCK_ITEM = 'ipBlock/ADD_IPBLOCK_ITEM';
export const ADD_IP_TO_BLOCK_ITEM = 'ipBlock/ADD_IP_TO_BLOCK_ITEM';
export const ADD_IPS_TO_BLOCK_ITEM = 'ipBlock/ADD_IPS_TO_BLOCK_ITEM';
export const SET_IPBLOCK_ITEM = 'ipBlock/SET_IPBLOCK_ITEM';
export const UNSET_IPBLOCK_ITEM = 'ipBlock/UNSET_IPBLOCK_ITEM';
export const REMOVE_IPBLOCK_ITEM = 'ipBlock/REMOVE_IPBLOCK_ITEM';
export const REMOVE_IP_ITEM = 'ipBlock/REMOVE_IP_ITEM';
export const REMOVE_IPBLOCK_ITEMS = 'ipBlock/REMOVE_IPBLOCK_ITEMS';
export const REMOVE_IP_ITEMS = 'ipBlock/REMOVE_IP_ITEMS';
export const UPDATE_IP_ITEM = 'ipBlock/UPDATE_IP_ITEM';
export const UPDATE_IPBLOCK_ITEM = 'ipBlock/UPDATE_IPBLOCK_ITEM';
export const SET_IP_BLOCK_IP_LIST = 'ipBlock/SET_IP_BLOCK_IP_LIST';
export const SET_IP_BLOCK_ITEM_IS_DELETING = 'ipBlock/SET_IP_BLOCK_ITEM_IS_DELETING';
export const SET_IP_ITEM_IS_LOADING = 'ipBlock/SET_IP_ITEM_IS_LOADING';
export const APPEND_IP_BLOCKS = 'ipBlock/APPEND_IP_BLOCKS';
export const APPEND_IPS = 'ipBlock/APPEND_IPS';

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { IProjectTokenPricingResponse } from 'common/api/resources/Project';

const MAX_HOUR_DECIMALS = 4;

export const calculatePrice = (tokens: number, tokenPricing: IProjectTokenPricingResponse): number =>
    tokens * tokenPricing.unit_cost;

export const formatPricePerMonth = (price: number, tokenPricing: IProjectTokenPricingResponse): string => {
    const calculatedPrice = price  *  tokenPricing.unit_cost;
    const numberString = calculatedPrice.toFixed(tokenPricing.currency_decimals)
        .replace('.', tokenPricing.currency_decimals_separator)
        .replace(/\B(?=(\d{3})+(?!\d),.*$)/g, tokenPricing.currency_thousands_separator);

    return `${tokenPricing.currency_prefix}${numberString}${tokenPricing.currency_suffix}`;
};

export const formatPricePerHour = (price: number, tokenPricing: IProjectTokenPricingResponse): string => {
    const calculatedPrice = price  *  tokenPricing.unit_cost;
    const numberString = calculatedPrice.toFixed(getHourDecimals(price.toString(), tokenPricing))
        .replace('.', tokenPricing.currency_decimals_separator)
        .replace(/\B(?=(\d{3})+(?!\d),.*$)/g, tokenPricing.currency_thousands_separator);

    return `${tokenPricing.currency_prefix}${numberString}${tokenPricing.currency_suffix}`;
};

const getHourDecimals = (price: string, tokenPricing: IProjectTokenPricingResponse): number => {
    if (!price.includes('.')) {
        return tokenPricing.currency_decimals;
    }

    const decimals = price.split('.')[1].length;

    if (decimals <= tokenPricing.currency_decimals) {
        return tokenPricing.currency_decimals;
    }

    if (decimals >= MAX_HOUR_DECIMALS) {
        return MAX_HOUR_DECIMALS;
    }

    return decimals;
};
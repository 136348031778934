// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import React from 'react';
import {
    SubTitleRow,
    SubTitleSection,
    SubTitleSectionLabel,
} from 'common/components/PageHeader/Styles';
import {
    Action,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import CopyText from 'common/containers/CopyText/CopyText';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import { CopyTexts } from 'common/containers/CopyText/Styles';
import { TEST } from 'admin/computeResourceVm/constants';
import { getViewableIps } from 'common/api/resources/ComputeResourceVm';
import { VIRTUALIZATION_TYPE_TRANSLATION_MAP } from 'common/api/resources/ComputeResource';
import { PlanDescription } from 'common/components/plan/components/PlanDescription/PlanDescription';
import { ServerBootModeIcon } from 'common/components/ServerBootMode/ServerBootModeIcon';
import { getServerBootModeName } from 'common/components/ServerBootMode/ServerBootModeName';

export type ComputeResourceVmDescriptionProps =
    ReturnType<typeof mapStateToProps>;

export const ComputeResourceVmDescription: React.FC<ComputeResourceVmDescriptionProps> = ({
    item,
    push,
}) => {
    const plan = React.useMemo(() => (
        <PlanDescription
            planName={!item.plan.is_custom ? item.plan.name : undefined}
            vcpu={item.specifications.vcpu}
            disk={item.specifications.disk}
            ram={item.specifications.ram}
        />
    ), [item.plan, item.specifications]);

    const handleClickResource = () => push(`${ADMIN_ROUTE_PREFIX}/compute_resources/${item.compute_resource!.id}`);

    return (
        <>
            <SubTitleRow>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <ServerBootModeIcon server={item} />
                    </SubTitleSectionLabel>
                    {getServerBootModeName(item)}
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.RESOURCE} size="12" />
                    </SubTitleSectionLabel>
                    <Action onClick={handleClickResource}>
                        {item.compute_resource!.name}
                    </Action>
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.PACKAGE} size="12" />
                    </SubTitleSectionLabel>
                    {VIRTUALIZATION_TYPE_TRANSLATION_MAP[item.virtualization_type]}
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.PLAN} size="12" />
                    </SubTitleSectionLabel>
                    {plan}
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.USER} size="12" />
                    </SubTitleSectionLabel>
                    {item.user.email}
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.PROJECT} size="12" />
                    </SubTitleSectionLabel>
                    {item.project.name}
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Translate content="servers.description.ips"/>
                    </SubTitleSectionLabel>
                    <CopyTexts data-cy={TEST.DESCRIPTION.IPS}>
                        {getViewableIps(item).map(ip => <CopyText key={ip.id}>{ip.ip}</CopyText>)}
                    </CopyTexts>
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Translate content="computeResourceVm.hypervisorId" />
                    </SubTitleSectionLabel>
                    <CopyText>
                        {item.uuid}
                    </CopyText>
                </SubTitleSection>
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Translate content="computeResourceVm.macAddress" />
                    </SubTitleSectionLabel>
                    <CopyText>
                        {item.settings.mac_address}
                    </CopyText>
                </SubTitleSection>
            </SubTitleRow>
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    push: ownProps.history.push,
    item: state.computeResourceVm.item,
});

export default withRouter(connect(mapStateToProps)(ComputeResourceVmDescription));

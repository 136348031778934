// Copyright 1999-2023. Plesk International GmbH. All rights reserved.


import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import * as React from 'react';
import {
    Form,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { IAccountUpdateRequest } from 'common/api/resources/Account';
import {
    requiredRule,
    validate,
} from 'common/validator';
import FormFieldPassword from 'common/components/Form/FormFieldPassword/FormFieldPassword';
import { FormContainer } from 'common/components/Form/Styles';
import * as authActions from 'common/modules/auth/actions';

export type PasswordFormProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues: IAccountUpdateRequest = {
    password: '',
    current_password: '',
    confirm_password: '',
};

export const PasswordForm: React.FC<PasswordFormProps> = ({
    isSaving,
    formErrors,
    authActions: {
        updateUserSettings,
    },
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState(defaultValues);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: IAccountUpdateRequest) => {
        const errors = validate(values, {
            password: requiredRule(<Translate content="validate.fieldRequired" />),
            current_password: requiredRule(<Translate content="validate.fieldRequired" />),
            confirm_password: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await updateUserSettings(values);

        setSubmitValues(defaultValues);
    };

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <FormContainer>
            <Form
                id="passwordForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldPassword
                        name="current_password"
                        label={<Translate content="account.passwordForm.currentPasswordLabel" />}
                        errors={formErrors}
                        size={SIZE.FILL}
                        required={true}
                    />
                    <FormFieldPassword
                        name="password"
                        label={<Translate content="account.passwordForm.newPasswordLabel" />}
                        errors={formErrors}
                        size={SIZE.FILL}
                        required={true}
                    />
                    <FormFieldPassword
                        name="confirm_password"
                        label={<Translate content="account.passwordForm.confirmPasswordLabel" />}
                        errors={formErrors}
                        size={SIZE.FILL}
                        required={true}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="passwordForm"
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isSaving}
            >
                <Translate content="account.passwordForm.saveBtn" />
            </Button>
        </FormContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.UPDATE_USER),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(PasswordForm);

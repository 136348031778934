// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    ISwitchWithConfirmationProps,
    SwitchWithConfirmation,
} from 'common/components/SwitchWithConfirmation';

export interface IVZPPPProps extends Omit<
    ISwitchWithConfirmationProps,
    'confirmOnDisable' | 'confirmOnEnable' | 'checked' | 'value' | 'label'
> {
    value: boolean;
    label?: React.ReactNode | string;
}

const VZPPPControl: React.FC<IVZPPPProps> = ({
    value,
    label,
    ...props
}) => {
    const confirmation = {
        enabled: true,
        translations: {
            title: <Translate content="servers.controls.vzPPP.confirmation.title" />,
            text: <Translate content="servers.controls.vzPPP.confirmation.text" />,
            button: <Translate content="servers.controls.vzPPP.confirmation.button" />,
        },
    };

    return (
        <SwitchWithConfirmation
            {...props}
            checked={value}
            confirmOnDisable={confirmation}
            confirmOnEnable={confirmation}
        >
            {label}
        </SwitchWithConfirmation>
    );
};

export default VZPPPControl;
// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { IIsoImageResponse } from 'common/api/resources/IsoImage';
import { TABLE_ACTIONS } from 'common/modules/isoImage/constants/test';

export interface IIsoImageDeleteProps {
    item: IIsoImageResponse;
    disabled?: boolean;
    handleRemove: () => void;
}

export const IsoImageDelete: React.FC<IIsoImageDeleteProps> = ({
    item,
    disabled,
    handleRemove,
}) => (
    <ButtonWithConfirmation
        isLoading={item.is_deleting}
        translations={{
            title: (
                <Translate content="isoImage.removePopover.title"/>
            ),
            button: (
                <Translate content="isoImage.removePopover.button"/>
            ),
            tooltip: disabled ? (
                <Translate content="isoImage.removePopover.disabledTooltip"/>
            ) : (
                <Translate content="isoImage.removePopover.tooltip"/>
            ),
        }}
        handleConfirm={handleRemove}
        data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
        icon={ICONS.RECYCLE}
        disabled={disabled}
    />
);
// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    AdditionalPrice,
    PriceInfo,
    PriceItem,
    PriceTaxes,
    TermsAndConditions,
    TotalPrice,
} from 'client/common/components/PlanPrice/Styles';
import {
    Link,
    Translate,
} from '@plesk/ui-library';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';

export interface IPlanPriceProps {
    planId?: number;
    isBackupEnabled: boolean;
    additionalIps?: number;
}

export type PlanPriceProps =
    IPlanPriceProps &
    ReturnType<typeof mapStateToProps>;

export const PlanPrice: React.FC<PlanPriceProps> = ({
    planId,
    plans,
    project,
    termsAndConditionsUrl,
    isBackupEnabled,
    additionalIps,
}) => {
    const plan = plans.data.find(item => item.id === planId);

    const planPrice = React.useMemo(() => {
        if (project.token_pricing && plan) {
            return {
                per_month: formatPricePerMonth(plan.tokens_per_month, project.token_pricing),
                per_hour: formatPricePerHour(plan.tokens_per_hour, project.token_pricing),
                backup_per_month: formatPricePerMonth(
                    plan.tokens_per_month * plan.backup_price / 100,
                    project.token_pricing
                ),
                additional_ips_per_month: formatPricePerMonth(
                    plan.ip_tokens_per_month * (additionalIps ?? 1),
                    project.token_pricing
                ),
            };
        }

        return undefined;
    }, [plan, project.token_pricing, additionalIps]);

    const totalPricePerMonth = React.useMemo(() => {
        if (!project.token_pricing || !plan) {
            return undefined;
        }

        let totalPrice = plan.tokens_per_month;

        if (isBackupEnabled) {
            totalPrice += totalPrice * plan.backup_price / 100;
        }

        if (additionalIps) {
            totalPrice += plan.ip_tokens_per_month * additionalIps;
        }

        return formatPricePerMonth(totalPrice, project.token_pricing);
    }, [plan, isBackupEnabled, project.token_pricing, additionalIps]);

    if (!plan || !planPrice || !project.token_pricing) {
        return (<></>);
    }

    const taxesText = project.token_pricing.taxes_inclusive ? 'plan.price.taxesInclusive' : 'plan.price.taxesExclusive';
    const hasAdditionalIps = additionalIps !== undefined && additionalIps > 0;

    const additionalPrice = (
        <>
            | <PriceItem>
                <span>
                    {planPrice.per_month}
                </span>
                <Translate content="plan.price.server"/>
            </PriceItem>
            {isBackupEnabled && <>
                + <PriceItem>
                    <span>
                        {planPrice.backup_per_month}
                    </span>
                    <Translate content="plan.price.backup"/>
                </PriceItem>
            </>}
            {hasAdditionalIps && plan.is_additional_ips_available && <>
                + <PriceItem>
                    <span>
                        {planPrice.additional_ips_per_month}
                    </span>
                    <Translate content="plan.price.additionalIps"/>
                </PriceItem>
            </>}
        </>
    );

    return (
        <PriceInfo>
            <TotalPrice>
                <Translate content="plan.price.total" params={{
                    price: totalPricePerMonth,
                }}/><AdditionalPrice>{additionalPrice}</AdditionalPrice>
            </TotalPrice>
            {project.token_pricing.taxes.length > 0 && <PriceTaxes>
                <Translate content={taxesText} params={{
                    taxes: project.token_pricing.taxes.map(tax => tax.rate + '% ' + tax.label).join(', '),
                }}/>
            </PriceTaxes>}
            {termsAndConditionsUrl && <TermsAndConditions>
                <Translate content="plan.price.termsAndConditions.text" params={{
                    link: <Link href={termsAndConditionsUrl} target="blank">
                        <Translate content="plan.price.termsAndConditions.link"/>
                    </Link>,
                }}/>
            </TermsAndConditions>}
        </PriceInfo>
    );
};

const mapStateToProps = (state: RootState) => ({
    plans: state.plan.list,
    project: state.project.projects.item,
    termsAndConditionsUrl: state.settings.theme.terms_and_conditions_url,
});

export default connect(mapStateToProps)(PlanPrice);
// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE_REVERSE_DNS: 'remove-reverse-dns',
    LIST_ENTRIES_REVERS_DNS: 'lit-entries-reverse-dns',
    REMOVE_ADDITIONAL_IP: 'remove-additional-ip',
    RESET_TRAFFIC: 'reset-traffic',
    CHANGE_PRIMARY_IP: 'change-primary-ip',
};

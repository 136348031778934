// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import * as projectActions from 'common/modules/project/actions';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { Loader } from 'common/components';
import { Translate } from '@plesk/ui-library';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import ProjectMembersList from 'client/project/containers/projectItem/tabs/ProjectMembersTab/ProjectMembersList';
import ProjectServersList from 'client/project/containers/projectItem/tabs/ProjectServerTab/ProjectServersList/ProjectServersList';
import ProjectItemHeader from 'client/project/containers/projectItem/ProjectItemHeader/ProjectItemHeader';
import { TabsExpander } from 'client/common/styles/Tabs';
import { VIEW_TYPE } from 'common/constants';
import { setViewType } from 'common/modules/app/viewType/actions';
import ViewSelect from 'common/components/ViewSelect';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export type ProjectItemProps =
    RouteComponentProps<{ id: string }> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const tabMappings = {
    '#servers': 0,
    '#members': 1,
};

export const ProjectItem: React.FC<ProjectItemProps> = ({
    selectedView,
    project,
    match: { params },
    history,
    location: { hash },
    projectActions: {
        getProject,
        getProjectTokenPricing,
    },
    isLoading,
    setSelectedView,
}) => {
    const projectId = parseInt(params.id, 10);

    if (!hash) {
        history.replace('#servers');
    }

    const onMount = async () => {
        await getProject(projectId);
        await getProjectTokenPricing(projectId);
    };

    const handleTabSelect = (index: number) => history.replace(Object.keys(tabMappings)[index]);
    const handleViewChange = (value: string) => setSelectedView(VIEW_TYPE[value]);

    return (
        <WithErrorPages onMount={onMount} deps={[projectId]}>
            <Loader isLoading={isLoading || project.id === 0}>
                <ProjectItemHeader project={project} />
                <Tabs selectedIndex={tabMappings[hash]} onSelect={handleTabSelect}>
                    <TabList>
                        <Tab><Translate content="projects.tabs.servers" /></Tab>
                        <Tab><Translate content="projects.tabs.members" /></Tab>
                        <TabsExpander />
                        <ViewSelect
                            selected={selectedView}
                            onChange={handleViewChange}
                        />
                    </TabList>
                    <TabPanel>
                        <ProjectServersList />
                    </TabPanel>
                    <TabPanel>
                        <ProjectMembersList
                            projectId={projectId}
                        />
                    </TabPanel>
                </Tabs>
            </Loader>
        </WithErrorPages>
    );
};

const mapStateToProps = (state: RootState) => ({
    selectedView: state.app.viewType,
    project: state.project.projects.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectActions: bindActionCreators(projectActions, dispatch),
    setSelectedView: bindActionCreators(setViewType, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ProjectItem));

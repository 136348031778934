// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  gap: 3px;
`;

export const PopoverContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
`;

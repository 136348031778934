// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    FC,
    useCallback,
} from 'react';
import {
    ComputeResourceVmStatus,
    IVmSettings,
    VirtualServerSettingsRequest,
} from 'common/api/resources/ComputeResourceVm';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import KvmSettingsTab from 'common/components/ServerTabs/SettingsTab/KVMSettingsTab';
import VzSettingsTab from 'common/components/ServerTabs/SettingsTab/VZSettingsTab';
import { ICommonState } from 'common/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { connect } from 'react-redux';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export interface ISettingsTabProps {
    serverId: number;
    serverSettings: IVmSettings;
    serverStatus: ComputeResourceVmStatus;
    serverVirtualizationType: VirtualizationType;
    serverIsSuspended: boolean;
    isAdminSettings: boolean;
}

export type SettingsTabProps =
    ISettingsTabProps &
    ReturnType<typeof mapDispatchToProps> &
    ReturnType<typeof mapStateToProps>;

export const SettingsTab: FC<SettingsTabProps> = ({
    serverId,
    serverSettings,
    serverStatus,
    serverVirtualizationType,
    isAdminSettings,
    isUpdating,
    updateComputeResourceVmSettings,
    isInstallingGuestTools,
    installGuestTools,
    serverIsSuspended,
    suspendComputeResourceVm,
    resumeComputeResourceVm,
    canManageServers,
}) => {
    isUpdating = isUpdating || serverStatus === ComputeResourceVmStatus.UPDATING;
    const isSuspendToggling = [
        ComputeResourceVmStatus.SUSPENDING,
        ComputeResourceVmStatus.RESUMING,
    ].includes(serverStatus);

    const updateSettings = (settings: VirtualServerSettingsRequest) => {
        updateComputeResourceVmSettings(serverId, settings);
    };

    const toggleSuspendStatus = (needSuspend: boolean) => {
        if (needSuspend) {
            suspendComputeResourceVm(serverId, serverStatus);
        } else {
            resumeComputeResourceVm(serverId, serverStatus);
        }
    };

    const installGuestToolsCallback = useCallback(() => {
        installGuestTools(serverId);
    }, [installGuestTools, serverId]);

    switch (serverVirtualizationType) {
    case VirtualizationType.KVM:
        return (<KvmSettingsTab
            serverSettings={serverSettings}
            isUpdating={isUpdating}
            updateSettings={updateSettings}
            isSuspended={serverIsSuspended}
            isSuspendToggling={isSuspendToggling}
            toggleSuspendStatus={toggleSuspendStatus}
            canManageServers={canManageServers}
            isInstallingGuestTools={isInstallingGuestTools}
            installGuestTools={installGuestToolsCallback}
        />);
    case VirtualizationType.VZ:
        return (<VzSettingsTab
            serverSettings={serverSettings}
            isUpdating={isUpdating}
            updateSettings={updateSettings}
            isSuspended={serverIsSuspended}
            isSuspendToggling={isSuspendToggling}
            toggleSuspendStatus={toggleSuspendStatus}
            canManageServers={canManageServers}
            isAdminSettings={isAdminSettings}
        />);
    default:
        return null;
    }
};

const mapStateToProps = (state: ICommonState) => ({
    isUpdating: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_UPDATE_SETTINGS),
    isInstallingGuestTools: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_INSTALL_GUEST_TOOLS),
    canManageServers: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateComputeResourceVmSettings: bindActionCreators(computeResourceVmActions.updateComputeResourceVmSettings, dispatch),
    installGuestTools: bindActionCreators(computeResourceVmActions.installGuestTools, dispatch),
    suspendComputeResourceVm: bindActionCreators(computeResourceVmActions.suspend, dispatch),
    resumeComputeResourceVm: bindActionCreators(computeResourceVmActions.resume, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsTab);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ipRule,
    validate,
    requiredRule,
    isIp,
} from 'common/validator';
import {
    CodeEditor,
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { IpBlockType } from 'common/api/resources/IpBlock';
import * as ipBlockActions from 'admin/ipBlock/actions';
import {
    IAddIpRequest,
    IAddIpsRequest,
} from 'common/api/resources/Ip';
import { StyledCodeEditor } from 'admin/common/styles/CodeEditor';
import validator from 'validator';

interface IAddIpFromProps {
    id: number;
    type: IpBlockType;
    onSubmit: () => void;
}

export type AddIpFormProps =
    IAddIpFromProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

enum Mode {
    SINGLE = 'single',
    MULTIPLE = 'multiple',
}

const modeButtons = [
    {
        title: <Translate content="ipBlock.addForm.single" />,
        value: Mode.SINGLE,
    },
    {
        title: <Translate content="ipBlock.addForm.multiple" />,
        value: Mode.MULTIPLE,
    },
];

const ipsValueToRequest = (value: string): string[] => value
    .trim()
    .split('\n')
    .map(ip => ip.trim())
    .filter(ip => ip !== '');

export const ipsRule = (message: JSX.Element, version?: validator.IPVersion) => ({
    validator: (value: string) => ipsValueToRequest(value).every(ip => isIp(ip, version)),
    message,
    comparison: false,
});

export const AddIpForm: React.FC<AddIpFormProps> = ({
    id,
    type,
    onSubmit,
    isIpAdding,
    formErrors,
    ipBlockActions: {
        addIpToBlock,
        addIpsToBlock,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        ip: '',
        ips: '',
        comment: '',
    });

    const [selectedMode, setSelectedMode] = React.useState<Mode>(Mode.SINGLE);

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmitIp = async () => {
        const version = type === IpBlockType.IPv4 ? 4 : 6;
        const rules = {
            ip: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, version),
            ],
        };

        const data: IAddIpRequest = {
            ip: submitValues.ip,
            comment: submitValues.comment,
        };

        const errors = validate<IAddIpRequest>(data, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await addIpToBlock(id, data);
        onSubmit();
    };

    const handleSubmitIps = async () => {
        const version = type === IpBlockType.IPv4 ? 4 : 6;
        const rules = {
            ips: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipsRule(<Translate content="validate.badIpAddresses" />, version),
            ],
        };

        const errors = validate({ ips: submitValues.ips }, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        const data: IAddIpsRequest = {
            ips: ipsValueToRequest(submitValues.ips),
        };

        await addIpsToBlock(id, data);
        onSubmit();
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleChangeMode = (mode: Mode) => {
        setSelectedMode(mode);
    };

    const handleIpsChange = (value: string) => {
        setSubmitValues({
            ...submitValues,
            ips: value,
        });
    };

    return (
        <>
            <Form
                id="addIpForm"
                footerClassName="hidden"
                onSubmit={selectedMode === Mode.MULTIPLE ? handleSubmitIps : handleSubmitIp}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormField>
                        <SegmentedControl
                            buttons={modeButtons}
                            selected={selectedMode}
                            onChange={handleChangeMode}
                        />
                    </FormField>
                    {selectedMode === Mode.SINGLE && (
                        <>
                            <FormFieldText
                                name="ip"
                                size={SIZE.FILL}
                                label={<Translate content="ipBlock.addForm.ip" />}
                                required={true}
                            />
                            <FormFieldText
                                name="comment"
                                size={SIZE.FILL}
                                label={<Translate content="ipBlock.addForm.comment" />}
                            />
                        </>
                    )}
                    {selectedMode === Mode.MULTIPLE && (
                        <FormField
                            name="ips"
                            label={<Translate content="ipBlock.addForm.ips" />}
                            required={true}
                        >
                            <StyledCodeEditor height={150}>
                                <CodeEditor onChange={handleIpsChange}>
                                    {submitValues.ips}
                                </CodeEditor>
                            </StyledCodeEditor>
                        </FormField>
                    )}
                </Section>
            </Form>
            <Button
                type="submit"
                form="addIpForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isIpAdding}
            >
                <Translate content="ipBlock.addForm.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isIpAdding: state.app.loadingFlags.has(LOADING_FLAGS.ADD_IP_TO_IPBLOCK),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddIpForm);

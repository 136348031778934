// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Button,
    FormField,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    ISelectRequiredOption,
    Loader,
} from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import * as activityLogActions from 'admin/activityLog/actions';
import { ValueType } from 'react-select';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import queryString from 'query-string';
import { ActivityLogDataDialog } from 'admin/activityLog/components/ActivityLogDataDialog';
import { IActivityLogResponse } from 'common/api/resources/ActivityLog';
import SelectInput from 'common/components/Select/SelectInput';
import {
    activityLogEventMap,
    ACTIVITY_LOG_EVENTS,
} from 'admin/activityLog/constants/activityLogEvents';
import Axios from 'axios';
import { RouteComponentProps } from 'react-router';
import { ISelectOption } from 'admin/user/components/Filters';
import { formatTableDate } from 'common/date';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import { ICONS } from 'common/constants';

export type ActivityLogsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IQueryParams {
    user_id?: string;
    event?: string;
}

const columns = [{
    width: '15%',
    key: 'colEvent',
    title: <Translate content="activityLog.list.event" />,
}, {
    width: '15%',
    key: 'colUser',
    title: <Translate content="activityLog.list.user" />,
}, {
    width: '10%',
    key: 'colIp',
    title: <Translate content="activityLog.list.ip" />,
}, {
    width: '10%',
    key: 'colCreated',
    title: <Translate content="activityLog.list.created" />,
}];

const events: ISelectOption[] = Object.values(ACTIVITY_LOG_EVENTS).map(event => ({
    label: <Translate content={activityLogEventMap[event]} />,
    value: event,
}));

export const ActivityLogs: React.FC<ActivityLogsProps> = ({
    isLoadingList,
    list,
    search,
    activityLogActions: {
        getActivityLogs,
    },
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedLogData, setSelectedLogData] = React.useState<IActivityLogResponse>();

    const query: IQueryParams = queryString.parse(search);

    const [filters, setFilters] = React.useState({
        user_id: query.user_id,
        event: query.event,
    });

    React.useEffect(() => {
        const { user_id, event } = filters;
        const source = Axios.CancelToken.source();

        const fetchActivityLogList = async () => {
            try {
                await getActivityLogs({
                    filters: {
                        user_id,
                        event,
                    },
                }, source);
            } catch (e) {
                if (Axios.isCancel(e)) {
                    return;
                }

                throw e;
            }
        };

        fetchActivityLogList();

        return () => {
            source.cancel();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    const loadPaginated = (page: number) => getActivityLogs({
        page,
        filters: {
            user_id: filters.user_id,
            event: filters.event,
        },
    });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const filtered = filters !== undefined && Object.values(filters).some(filter => !!filter);

    const handleOpen = (log: IActivityLogResponse) => () => {
        setSelectedLogData(log);
        setIsOpen(true);
    };

    const handleClose = () => setIsOpen(false);

    const handleUserFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters(values => ({
            ...values,
            user_id: option ? (option as ISelectOption).value : '',
        }));
    };

    const handleEventFilterChange = async (option: ValueType<ISelectOption>) => {
        setFilters(values => ({
            ...values,
            event: option ? (option as ISelectOption).value : '',
        }));
    };

    const data = list.data.map(item => ({
        colEvent: (
            <>
                <Translate content={activityLogEventMap[item.event]} />
                <Button
                    onClick={handleOpen(item)}
                    ghost={true}
                    icon={ICONS.BOX_DIAGONAL_TOP_IN}
                />
            </>
        ),
        colUser: item.user_email,
        colIp: item.user_ip,
        colCreated: formatTableDate(item.created_at),
        key: item.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={false}
                title={<Translate content="activityLog.title"/>}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="activityLog.emptyView.title"
                            icon="net"
                        />
                    }
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                    filtered={filtered || isLoadingList}
                    toolbar={(
                        <FilterForm>
                            <FormField label={<Translate content="activityLog.list.event" />}>
                                <SelectInput<ISelectOption>
                                    placeholder={<Translate content="filters.all"/>}
                                    options={events}
                                    isClearable={true}
                                    onChange={handleEventFilterChange}
                                />
                            </FormField>
                            <FormField label={<Translate content="activityLog.list.user" />}>
                                <UserFilter onChange={handleUserFilterChange} margin_left="0px" />
                            </FormField>
                        </FilterForm>
                    )}
                />
            </Loader>
            {selectedLogData && (
                <ActivityLogDataDialog
                    handleClose={handleClose}
                    isOpen={isOpen}
                    logData={selectedLogData}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps) => ({
    list: state.activityLog.list,
    search: ownProps.location.search,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.ACTIVITY_LOG_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    activityLogActions: bindActionCreators(activityLogActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogs);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { FC } from 'react';
import {
    DiskCacheMode,
    IVmSettings,
    VirtualServerSettingsRequest,
} from 'common/api/resources/ComputeResourceVm';
import {
    Grid,
    Translate,
} from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import SettingsPropertyCard from 'common/components/ServerTabs/SettingsTab/SettingsPropertyCard';
import SuspendResumeControl from 'common/components/ServerTabs/components/Controls/SuspendResumeControl';
import KVMDiskCacheModeControl from 'common/components/ServerTabs/components/Controls/KVMDiskCacheModeControl';

export interface IKVMSettingsTabProps {
    serverSettings: IVmSettings;
    isUpdating: boolean;
    updateSettings: (settings: VirtualServerSettingsRequest) => void;
    isInstallingGuestTools: boolean;
    installGuestTools: () => void;
    isSuspended: boolean;
    isSuspendToggling: boolean;
    toggleSuspendStatus: (isSuspended: boolean) => void;
    canManageServers: boolean;
}

export const KVMSettingsTab: FC<IKVMSettingsTabProps> = ({
    serverSettings,
    isUpdating,
    updateSettings,
    isInstallingGuestTools,
    installGuestTools,
    isSuspended,
    isSuspendToggling,
    toggleSuspendStatus,
    canManageServers,
}) => {
    const diskCacheModeChange = (value: DiskCacheMode) => {
        updateSettings({ disk_cache_mode: value });
    };

    return (
        <>
            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.diskCacheMode.title" />}
                    titleId="disk-cache-mode-label"
                >
                    <KVMDiskCacheModeControl
                        aria-labelledby="disk-cache-mode-label"
                        value={serverSettings.disk_cache_mode ?? DiskCacheMode.DEFAULT}
                        onChange={diskCacheModeChange}
                        disabled={isUpdating}
                        loading={isUpdating}
                    />
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.suspendResume.title" />}
                    text={<Translate content="servers.tabs.settings.suspendResume.text" />}
                >
                    <SuspendResumeControl
                        value={isSuspended}
                        disabled={isSuspendToggling || !canManageServers}
                        loading={isSuspendToggling}
                        onChange={toggleSuspendStatus}
                    />
                </SettingsPropertyCard>
            </Grid>

            <Grid gap="md">
                <SettingsPropertyCard xs={12} sm={12} md={12} xl={7} lg={7}
                    title={<Translate content="servers.tabs.settings.guestTools.title" />}
                >
                    <ButtonWithConfirmation
                        isLoading={isInstallingGuestTools}
                        disabled={isInstallingGuestTools}
                        buttonSize={SIZE.LG}
                        buttonIntent={INTENT_TYPE.PRIMARY}
                        confirmationButtonGhost={false}
                        translations={{
                            title: <Translate content="servers.tabs.settings.guestTools.confirmationDialog.title" />,
                            text: <Translate content="servers.tabs.settings.guestTools.confirmationDialog.content" />,
                            button: <Translate content="servers.tabs.settings.guestTools.confirmationDialog.button" />,
                        }}
                        confirmationButtonText={
                            <Translate content='servers.tabs.settings.guestTools.button' />
                        }
                        handleConfirm={installGuestTools}
                    />
                </SettingsPropertyCard>
            </Grid>
        </>
    );
};

export default KVMSettingsTab;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setNewPassword,
    updateVm,
    updateVmProgress,
} from 'common/modules/computeResourceVm/actions';
import { IUpdateComputeResourceVm } from 'common/api/resources/ComputeResourceVm';
import { IUpdateUser } from 'common/api/resources/ComputeResource';
import { updateUser } from 'common/modules/auth/actions';
import { IUpdateBackupResource } from 'common/api/resources/Backup';
import {
    updateItem as updateBackupItem,
    updateItemProgress,
} from 'common/modules/backup/actions';
import { IUpdateTask } from 'common/api/resources/Task';

export const updateProjectServerItemAction = (data: IUpdateComputeResourceVm) => async (dispatch: Dispatch) => {
    dispatch(updateVm(data.computeResourceVm));
};

export const updateProjectServerPasswordAction = (id: number, password: string) => async (dispatch: Dispatch) => {
    dispatch(setNewPassword(id, password));
};

export const updateUserAction = (data: IUpdateUser) => async (dispatch: Dispatch) => {
    dispatch(updateUser(data.user));
};

export const updateBackupItemAction = (data: IUpdateBackupResource) => async (dispatch: Dispatch) => {
    dispatch(updateBackupItem(data.backup));
};

export const updateTaskAction = (data: IUpdateTask) => async (dispatch: Dispatch) => {
    if (data.task.backup_id && data.task.progress > 0) {
        dispatch(updateItemProgress(data.task.backup_id, data.task.progress));
    }
    if (data.task.compute_resource_vm_id && data.task.progress > 0) {
        dispatch(updateVmProgress(
            data.task.compute_resource_vm_id,
            data.task.progress
        ));
    }
};

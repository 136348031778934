// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { CardWithSwitch } from 'common/components/CardWithSwitch/CardWithSwitch';
import {
    Icon,
    Link,
    Popover,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as settingsActions from 'common/modules/settings/actions';
import React from 'react';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    CardWithSwitchLink,
    CardWithSwitchLinkIcon,
} from 'common/components/CardWithSwitch/Styles';
import { Dialog } from 'common/components/Dialog/Dialog';
import { formatTableDate } from 'common/date';
import * as backupActions from 'common/modules/backup/actions';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import GlobalVsBackupSettingsForm from 'admin/backup/containers/GlobalVsBackupSettingsForm/GlobalVsBackupSettingsForm';
import { GLOBAL_VS_BACKUP_SETTINGS_CARD } from 'admin/backup/constants/tests';
import { IShortComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { IShortVmResponse } from 'common/api/resources/ComputeResourceVm';

export type SettingsCardProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const GlobalVsBackupSettingsCard: React.FC<SettingsCardProps> = ({
    backupSettings,
    nextScheduledDate,
    isSwitching,
    canManageBackups,
    settingsActions: { saveSettings },
    backupActions: { getNextScheduledDate },
}) => {
    const [isDialogOpened, setIsDialogOpened] = React.useState(false);

    const handleSettingsFormSubmit = () => {
        setIsDialogOpened(false);
        getNextScheduledDate();
    };

    const handleBackupsEnabledChange = async (isEnabled: boolean) => {
        await saveSettings({
            server_backup: {
                ...backupSettings,
                compute_resources: backupSettings.compute_resources.map((cr: IShortComputeResourceResponse) => cr.id),
                excluded_servers: backupSettings.excluded_servers.map((vs: IShortVmResponse) => vs.id),
                is_enabled: isEnabled,
            },
        });
    };

    const renderSwitchTooltip = () => {
        if (!canManageBackups) {
            return undefined;
        }

        return backupSettings.is_enabled
            ? (<Translate content="backups.settingsCard.content.disableInfo" />)
            : (<Translate content="backups.settingsCard.content.enableInfo" />);
    };

    return (
        <>
            <CardWithSwitch
                title={<Translate content="backups.settingsCard.title" />}
                isEnabled={backupSettings.is_enabled}
                isLoading={isSwitching}
                handleChange={handleBackupsEnabledChange}
                isDisabled={!canManageBackups}
                tooltip={renderSwitchTooltip()}
                switch-data-cy={GLOBAL_VS_BACKUP_SETTINGS_CARD.SWITCH}
            >
                <>
                    {backupSettings.is_enabled && nextScheduledDate && (
                        <div>
                            <Translate
                                content="backups.settingsCard.content.enabled"
                                params={{
                                    schedule_date: formatTableDate(nextScheduledDate),
                                }}
                            />
                        </div>
                    )}
                    {backupSettings.limit.is_enabled ? (
                        <Translate content="backups.settingsCard.content.limit.limited" params={{
                            limit: backupSettings.limit.limit,
                        }}/>
                    ) : (
                        <Translate content="backups.settingsCard.content.limit.unlimited"/>
                    )}
                    <Popover
                        target={(
                            <Icon name={ICONS.INFO_CIRCLE} size="12" className="pul-form-field__full-description"/>
                        )}>
                        <Translate content="backups.settingsCard.content.limit.description" />
                    </Popover>
                    <CardWithSwitchLink>
                        <CardWithSwitchLinkIcon name="calendar-clock"/>
                        <Link onClick={() => setIsDialogOpened(true)} disabled={!canManageBackups}>
                            <Translate content="backups.settingsCard.content.settings"/>
                        </Link>
                    </CardWithSwitchLink>
                </>
            </CardWithSwitch>
            <Dialog
                heading={<Translate content="backups.settingsCard.settingsTitle"/>}
                closeHandler={() => setIsDialogOpened(false)}
                isOpen={isDialogOpened}
                size={SIZE.XS}
            >
                <GlobalVsBackupSettingsForm onSubmit={handleSettingsFormSubmit} />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    backupSettings: state.settings.server_backup,
    nextScheduledDate: state.backup.nextScheduledDate,
    isSwitching: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
    canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_BACKUPS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
    backupActions: bindActionCreators(backupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GlobalVsBackupSettingsCard);

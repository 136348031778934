// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    Button,
    Popover,
    Translate,
} from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';

const availableSymbols = [
    {
        title: 'console',
    },
    {
        title: 'Math',
    },
    {
        title: 'parseInt',
    },
    {
        title: 'parseFloat',
    },
    {
        title: 'Object',
    },
    {
        title: 'JSON',
    },
    {
        title: 'encodeURI',
    },
    {
        title: 'encodeURIComponent',
    },
    {
        title: 'decodeURI',
    },
    {
        title: 'decodeURIComponent',
    },
    {
        title: 'atob',
    },
    {
        title: 'btoa',
    },
    {
        title: 'fetch',
    },
    {
        title: 'openWindow',
        help: <Translate content="application.loginLink.help.jsCode.symbols.openWindow"/>,
    },
    {
        title: 'end',
        help: <Translate content="application.loginLink.help.jsCode.symbols.end"/>,
    },
];

export const LoginLinkContentPopover = () => (
    <Popover
        target={(
            <Button
                ghost={true}
                intent="primary"
                icon="info-circle"
            >
                <Translate content="application.loginLink.help.jsCode.btn" />
            </Button>
        )}
        placement="right"
    >
        <h3>
            <Translate content="application.loginLink.help.jsCode.title" />
        </h3>
        <p>
            <Translate content="application.loginLink.help.jsCode.description" />
        </p>
        {availableSymbols.map((s) => (
            <p key={s.title}>
                <b style={{ color: COLORS.PRIMARY }}>
                    {s.title}
                </b>
                {s.help && (
                    <>
                        &nbsp;— {s.help}
                    </>
                )}
            </p>
        ))}
    </Popover>
);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    CodeEditor,
    FormField,
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import { StyledSelect } from 'common/components/styles/Select';
import {
    StrategySelector,
    Label,
} from 'admin/settings/containers/Settings/Styles';
import {
    ComputeResourcesBalanceStrategy,
    ISettingsResponse,
} from 'common/api/resources/Settings';
import * as settingsActions from 'common/modules/settings/actions';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { SIZE } from 'common/constants';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { RootState } from 'admin/core/store';
import { DiskCacheMode } from 'common/api/resources/ComputeResourceVm';
import { StyledCodeEditor } from 'admin/common/styles/CodeEditor';
import { Button } from 'admin/common/components/Button/Button';
import { initialState } from 'common/modules/settings/reducer';

export type ComputeResourceSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const balanceStrategyOptions = Object.values(ComputeResourcesBalanceStrategy).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

const diskCacheModeOptions = Object.values(DiskCacheMode).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

export const ComputeResourceSettings: React.FC<ComputeResourceSettingsProps> = ({
    settings,
    settingsActions: { saveSettings },
}) => {
    const [libvirtXmlCpu, setLibvirtXmlCpu] = React.useState(settings.compute_resource.libvirt_xml_cpu);

    React.useEffect(() => {
        setLibvirtXmlCpu(settings.compute_resource.libvirt_xml_cpu);
    }, [settings.compute_resource.libvirt_xml_cpu]);

    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            compute_resource: {
                ...values.compute_resource,
                libvirt_xml_cpu: libvirtXmlCpu,
            },
            non_existent_vms_remover: values.non_existent_vms_remover,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.computeResource"
            onSubmit={handleSubmit}
        >
            <Section>
                <StyledSelect>
                    <StrategySelector
                        size={SIZE.XL}
                        name="compute_resource[balance_strategy]"
                        description={<Translate content="settings.computeResource.vmCreateStrategyDescription" />}
                        required={true}
                        label={<Translate content="settings.computeResource.vmCreateStrategy" />}
                    >
                        {balanceStrategyOptions}
                    </StrategySelector>
                </StyledSelect>
                <FormFieldText
                    required={true}
                    name="compute_resource[rescue_iso_url]"
                    size={SIZE.XL}
                    label={
                        <Translate content="settings.computeResource.rescueImageUrl" />
                    }
                    description={
                        <Translate content="settings.computeResource.rescueImageUrlDescription" />
                    }
                />
                <FormFieldCheckbox
                    name="non_existent_vms_remover[enabled]"
                    label={
                        <Translate content="settings.nonExistentVmsRemover.enabled" />
                    }
                />
                <FormFieldNumber
                    label={
                        <Translate content="settings.nonExistentVmsRemover.interval" />
                    }
                    name="non_existent_vms_remover[interval]"
                    min={1}
                    max={60}
                    disabled={!settings.non_existent_vms_remover.enabled}
                />
            </Section>
            <Section title={<Translate content="settings.computeResource.kvm.title" />}>
                <FormFieldSelect
                    size={SIZE.XL}
                    name="compute_resource[vs_disk_cache_mode]"
                    description={<Translate content="settings.computeResource.kvm.diskCacheModeDescription" />}
                    required={true}
                    label={<Translate content="settings.computeResource.kvm.diskCacheMode" />}
                >
                    {diskCacheModeOptions}
                </FormFieldSelect>
                <FormField
                    name="compute_resource[libvirt_xml_cpu]"
                    size={SIZE.XL}
                    label={(
                        <Label>
                            <Translate content="settings.computeResource.kvm.libvirtXmlCpu" />
                            <Button
                                ghost={false}
                                onClick={() => setLibvirtXmlCpu(initialState.compute_resource.libvirt_xml_cpu)}
                            >
                                <Translate content="settings.computeResource.kvm.setDefaultLibvirtXmlCpu" />
                            </Button>
                        </Label>
                    )}
                >
                    <StyledCodeEditor height={400} width={500}>
                        <CodeEditor
                            mode="xml"
                            onChange={setLibvirtXmlCpu}
                        >
                            {libvirtXmlCpu}
                        </CodeEditor>
                    </StyledCodeEditor>
                </FormField>
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceSettings);

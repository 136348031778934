// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import SettingsTab from 'common/components/ServerTabs/SettingsTab/SettingsTab';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';

const mapStateToProps = (state: RootState) => ({
    serverId: state.project.servers.item.id,
    serverSettings: state.project.servers.item.settings,
    serverStatus: state.project.servers.item.status,
    serverVirtualizationType: state.project.servers.item.virtualization_type,
    serverIsSuspended: state.project.servers.item.is_suspended,
    isAdminSettings: false,
});

export default connect(mapStateToProps)(SettingsTab);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    List,
    Switch,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceActions from 'admin/computeResource/actions';
import { connect } from 'react-redux';
import { IStorageResponse } from 'common/api/resources/Storage';
import {
    STORAGE_TYPES_TRANSLATION_MAP,
    StorageType,
} from 'common/api/resources/StorageType';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { dataCySelector } from 'common/tests/selectors';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import StorageCreateDialog from 'admin/computeResource/page/containers/dialogs/StorageCreateDialog/StorageCreateDialog';
import { Dialog } from 'common/components/Dialog/Dialog';
import { STORAGE } from 'admin/computeResource/constants/tests';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import { getActionColumnProps } from 'common/helpers/list';

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="computeResource.storages.colId" />,
}, {
    key: 'colAvailableForBalancing',
    width: '10%',
    title: <Translate content="computeResource.storages.colAvailableForBalancing" />,
}, {
    key: 'colType',
    width: '10%',
    title: <Translate content="computeResource.storages.colType" />,
}, {
    key: 'colFreeSpace',
    title: <Translate content="computeResource.storages.colFreeSpace" />,
}, {
    key: 'colPath',
    title: <Translate content="computeResource.storages.colPath" />,
    cellProps: {
        className: 'cell-bold',
    },
}, getActionColumnProps()];

interface IStoragesTabProps {
    isOpened: boolean;
    onClose: () => void;
}

export type StoragesTabProps =
    IStoragesTabProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const StoragesTab: React.FC<StoragesTabProps> = ({
    isOpened,
    onClose,
    computeResource,
    computeResourceActions: {
        getStorages,
        patchComputeResourceStorage,
        removeComputeResourceStorage,
        unmountComputeResourceStorage,
    },
    storages,
}) => {
    const [isLoading, setIsLoading] = React.useState(true);

    const loadStorages = async () => {
        try {
            setIsLoading(true);
            if (computeResource.id) {
                await getStorages(computeResource.id);
            }
        } finally {
            setIsLoading(false);
        }
    };

    React.useEffect(() => {
        loadStorages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemoveStorage = (id: number, type: StorageType) => async() => type === StorageType.NFS
        ? await unmountComputeResourceStorage(id, computeResource.id)
        : await removeComputeResourceStorage(id);
    const handleToggleIsAvailableForBalancing = (storage: IStorageResponse) => async() => await patchComputeResourceStorage(storage.id, {
        is_available_for_balancing: !storage.is_available_for_balancing,
    });

    const data = storages.map((item) => {
        let deleteTooltipKey = 'computeResource.storages.buttonWithConfirmation.tooltip';
        if (item.type.name === StorageType.VZ) {
            deleteTooltipKey = 'computeResource.storages.buttonWithConfirmation.tooltipVZStorage';
        } else if (item.servers_count > 0) {
            deleteTooltipKey = 'computeResource.storages.buttonWithConfirmation.tooltipServerExists';
        }

        return {
            colId: item.id,
            colAvailableForBalancing: (
                <Switch
                    disabled={item.type.name === StorageType.NFS}
                    tooltip={item.type.name === StorageType.NFS && (
                        <Translate content="computeResource.storages.tooltip.useForNewServersDisabled" />
                    )}
                    checked={item.is_available_for_balancing}
                    onChange={handleToggleIsAvailableForBalancing(item)}
                />
            ),
            colType: STORAGE_TYPES_TRANSLATION_MAP[item.type.name],
            colFreeSpace: item.free_space,
            colPath: item.thin_pool ? `${item.path}/${item.thin_pool}` : item.path,
            colActions: (
                <ButtonWithConfirmation
                    isLoading={item.isDeleting}
                    translations={{
                        title: (
                            <Translate content="computeResource.storages.buttonWithConfirmation.title" />
                        ),
                        button: (
                            <Translate content="computeResource.storages.buttonWithConfirmation.button" />
                        ),
                        tooltip: (
                            <Translate content={deleteTooltipKey} />
                        ),
                    }}
                    handleConfirm={handleRemoveStorage(item.id, item.type.name)}
                    data-cy={dataCySelector(item.id, 'remove')}
                    disabled={item.servers_count > 0 || item.type.name === StorageType.VZ}
                    icon={ICONS.RECYCLE}
                />
            ),
            key: item.id.toString(),
        };
    });

    return (
        <>
            <Loader isLoading={isLoading}>
                <StyledTable>
                    <List
                        emptyView={
                            <EmptyView
                                title="computeResource.storages.emptyView.title"
                                description="computeResource.storages.emptyView.description"
                                icon={ICONS.STORAGE}
                            />
                        }
                        columns={columns}
                        data={data}
                    />
                </StyledTable>
            </Loader>
            <Dialog
                key={isOpened.toString()}
                heading={
                    <Translate content="computeResource.storages.form.title" />
                }
                closeHandler={onClose}
                isOpen={isOpened}
                size={SIZE.XS}
                data-cy={STORAGE.DIALOG}
            >
                <StorageCreateDialog onClose={onClose}/>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    storages: state.computeResource.storages,
    computeResource: state.computeResource.item,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(StoragesTab);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'client/core/theme';

interface ISubmitButtonProps {
    isActive: boolean;
}

interface IServerNameProps {
    withRefresh?: boolean;
    hasErrors: boolean;
}

export const Container = styled.div`
    height: 100%;
    padding-bottom: 45px;
`;

export const SubmitButton = styled.span<ISubmitButtonProps>`
    button, a {
        padding: 10px 68px;
        background: ${props => props.isActive ? props.theme.primary_color : 'linear-gradient(0deg, #EEEEEE, #EEEEEE), #FFFFFF'};
        border-radius: 2px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
        color: ${props => props.isActive ? '#FFFFFF' : '#CCCCCC'};
        text-decoration: none;
        &:hover {
            text-decoration: none;
            color: ${props => props.isActive ? '#FFFFFF' : '#CCCCCC'};
        }
    }
`;

export const Footer = styled.div`
    display: flex;
    z-index: 1;
    padding: 20px 40px;
    margin-left: -40px;
    position: fixed;
    align-items: center;
    bottom: 0;
    width: 100%;
    background: #FBFBFB;
    box-shadow: 0 -2px 4px rgba(34, 34, 34, 0.15);
    
    @media (max-width: 560px) {
      margin-left: -16px;
    }
`;

export const ServersContainer = styled.div`
    display: flex;
    flex-wrap: wrap;
    margin-top: 20px;
    margin-right: -24px;
`;

export const ServerName = styled.div<IServerNameProps>`
    margin-bottom: 20px;
    position: relative;

    input {
        background: ${props => props.hasErrors ? 'rgba(208,45,75,0.04)' : COLORS.WHITE_0};
        border: 1px solid ${props => props.hasErrors ? COLORS.RED_0 : COLORS.GREY_50};
        box-sizing: border-box;
        border-radius: 2px;
        padding: 10px ${props => props.withRefresh && '30px'} 10px 10px;
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.GREY_100};
        width: 100%;
    
        &:focus {
            outline: none;
            background: rgba(40, 170, 222, 0.05);
            border: 1px solid ${props => props.theme.primary_color};
            box-sizing: border-box;
            box-shadow: inset 0 3px 2px rgba(40, 170, 222, 0.09);
        }

        &:disabled {
            color: ${COLORS.GREY_60};
            background: ${COLORS.GREY_20};
        }
    }
    
    .error {
        color: red;
        font-size: 12px;
        padding-top: 5px;
    }
`;

export const ServerNamesContainer = styled.div`
    flex: 1;
    flex-basis: 400px;
    max-width: 400px;
    
    margin-right: 24px;

    .pul-form-field--error input{
        border: 1px solid #D02D4B;
        background: rgba(208,45,75,0.04);
    }

    .pul-form-field__error {
        color: #D02D4B;
    }

    div:last-child {
        margin-bottom: 0;
    }
`;

export const GenerateNameButton = styled.button`
    position: absolute;
    outline: none;
    border: 0;
    background: none;
    margin-left: -35px;
    margin-top: 12px;
    cursor: pointer;
    vertical-align: middle;
    color: ${props => props.theme.primary_color};

    &:active {
        border: none;
    }
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  padding: 0 15px;
  color: red;
`;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export default {
    toasts: {
        networkError: 'Network error',
        task: {
            serverCreateDone: 'Server was created.',
            serverCreateFailed: 'Failed to create a server.',
            serverReinstallDone: 'The server was reinstalled.',
            serverReinstallFailed: 'Failed to reinstall the server.',
            serverDeleteDone: 'Server was deleted.',
            serverDeleteFailed: 'Failed to delete the server.',
            serverUpdateDone: 'The server was updated.',
            serverUpdateFailed: 'Failed to update the server.',
            serverPasswordChangeDone: 'The server\'s password was changed.',
            serverPasswordChangeFailed: 'Failed to change the server\'s password.',
            serverStartDone: 'The server was started.',
            serverStartFailed: 'Failed to start the server.',
            serverStopDone: 'The server was stopped.',
            serverStopFailed: 'Failed to stop the server.',
            serverRestartDone: 'The server was restarted.',
            serverRestartFailed: 'Failed to restart the server.',
            serverSuspendDone: 'The server was suspended.',
            serverSuspendFailed: 'Failed to suspend the server.',
            serverResumeDone: 'The server was resumed.',
            serverResumeFailed: 'Failed to resume the server.',
            serverResizeDone: 'The server was resized.',
            serverResizeFailed: 'Failed to resize the server.',
            serversMigrateDone: 'The servers were migrated.',
            serversMigrateFailed: 'Failed to migrate the servers.',
            serverMigrateDone: 'The server was migrated.',
            serverMigrateFailed: 'Failed to migrate the server.',
            serverUpdateNetworkAddIpsDone: 'IP address was added.',
            serverUpdateNetworkAddIpsFailed: 'Failed to add IP address.',
            serverUpdateNetworkDeleteIpsDone: 'IP address was deleted.',
            serverUpdateNetworkDeleteIpsFailed: 'Failed to delete IP address',
            serverUpdateNetworkChangePrimaryIpDone: 'Primary IP address was changed.',
            serverUpdateNetworkChangePrimaryIpFailed: 'Failed to change the primary IP address.',
            serverUpdateLimitsOnResetUsageDone: 'Usage limits were updated.',
            serverUpdateLimitsOnResetUsageFailed: 'Failed to update usage limits.',
            dnsRecordsRegisterDone: 'The DNS record was registered.',
            dnsRecordsRegisterFailed: 'Failed to register the DNS record.',
            dnsRecordsUnregisterDone: 'The DNS record was unregistered.',
            dnsRecordsUnregisterFailed: 'Failed to unregister the DNS record.',
            dnsRecordsUpdateDone: 'The DNS record was updated.',
            dnsRecordsUpdateFailed: 'Failed to update the DNS record.',
            reverseDnsRecordsRegisterDone: 'The reverse DNS record was registered.',
            reverseDnsRecordsRegisterFailed: 'Failed to register the reverse DNS record.',
            reverseDnsRecordsUnregisterDone: 'The reverse DNS record was unregistered.',
            reverseDnsRecordsUnregisterFailed: 'Failed to unregister the reverse DNS record.',
            snapshotCreateDone: 'Snapshot was created.',
            snapshotCreateFailed: 'Failed to create a snapshot.',
            snapshotDeleteDone: 'Snapshot was deleted.',
            snapshotDeleteFailed: 'Failed to delete the snapshot.',
            snapshotRevertDone: 'The snapshot was reverted.',
            snapshotRevertFailed: 'Failed to revert the snapshot.',
            prepareInstallerForUpdateDone: 'Preparing the installer for version update was done.',
            prepareInstallerForUpdateFailed: 'Preparing the installer for version update failed.',
            runVersionUpdateDone: 'Version was updated.',
            runVersionUpdateFailed: 'Failed to update version.',
            backupCreateDone: 'Backup was created.',
            backupCreateFailed: 'Failed to create a backup.',
            backupRestoreDone: 'Backup was restored.',
            backupRestoreFailed: 'Failed to restore the backup.',
            backupDeleteDone: 'Backup was deleted.',
            backupDeleteFailed: 'Failed to delete the backup.',
            backupRotateDone: 'The backup was rotated.',
            backupRotateFailed: 'Failed to rotate the backup.',
            backupPurgeComputeResourceVmDone: 'Backups were deleted.',
            backupPurgeComputeResourceVmFailed: 'Failed to delete backups.',
            managementNodeBackupCreateDone: 'Management node backup was created.',
            managementNodeBackupCreateFailed: 'Failed to create a management node backup.',
            managementNodeBackupRestoreDone: 'Management node backup was restored.',
            managementNodeBackupRestoreFailed: 'Failed to restore the management node backup.',
            managementNodeBackupDeleteDone: 'Management node backup was deleted.',
            managementNodeBackupDeleteFailed: 'Failed to delete the management node backup.',
            managementNodeBackupRotateDone: 'Management node backups were rotated.',
            managementNodeBackupRotateFailed: 'Failed to rotate the management node backups.',
            managementNodeBackupRecoverDone: 'Management node backup was recovered.',
            managementNodeBackupRecoverFailed: 'Failed to recover management node backup.',
            configureNetworkDone: 'Network was configured.',
            configureNetworkFailed: 'Failed to configure network.',
            updateNetworkRulesDone: 'Network rules were updated.',
            updateNetworkRulesFailed: 'Failed to update network rules.',
            upgradeComputeResourceDone: 'The compute resource was upgraded.',
            upgradeComputeResourceFailed: 'Failed to upgrade the compute resource.',
            resetNetworkTrafficDone: 'The server network traffic was reset.',
            resetNetworkTrafficFailed: 'Failed to reset the server network traffic.',
            clearImageCacheDone: 'Image cache was cleared.',
            clearImageCacheFailed: 'Failed to clear image cache.',
            changeHostnameDone: 'Hostname was changed.',
            changeHostnameFailed: 'Failed to change a hostname.',
            prepareClusterImportDone: 'Cluster import was prepared.',
            prepareClusterImportFailed: 'Failed to prepare cluster import.',
            refreshClusterImportDone: 'Cluster import was refreshed.',
            refreshClusterImportFailed: 'Failed to refresh cluster import.',
            clusterImportDone: 'Cluster import was finished.',
            clusterImportFailed: 'Cluster import failed.',
        },
    },
    appTitle: 'solus.io',
    errorPages: {
        notFound: {
            title: '404 Not Found',
            description: 'Sorry, this page does not exist.',
        },
        accessForbidden: {
            title: '403 Access Forbidden',
            description: 'Sorry, you don\'t have the permissions to access this page.',
        },
        internalError: {
            title: 'Service temporarily unavailable',
        },
    },
    list: {
        emptyViewOfFilters: {
            title: 'No Filter Results',
            description: 'The filter didn’t find any matches. Try to change the filtering criteria.',
        },
    },
    auth: {
        signIn: 'Sign In',
        register: 'Register',
        loginBtn: 'Sign In',
        registerBtn: 'Register',
        updatePasswordBtn: 'Update Password',
        notRegisteredMessage: 'not registered yet?',
        authorizing: 'Authorizing...',
        login: 'Email',
        resetPasswordTitle: 'Reset password',
        password: 'Password',
        confirmPassword: 'Confirm',
        passwordsMismatch: 'Passwords don\'t match',
        resetPassword: 'Forgot password?',
        forgotPassword: 'Forgot password',
        sendRecoveryLink: 'Send me a recovery link',
        resendText: 'Resend available in',
        linkSent: 'We have sent you a link to change your password',
        registerLink: 'Register!',
        title: 'Login form',
        policyText: 'I accept the %%link%%.',
        policyLink: 'Privacy Policy',
        validatePolicy: 'Accept Privacy Policy to continue',
        registerSimple: {
            joining: 'Joining project...',
            failed: 'Failed to join the project',
        },
        verify: {
            verifyingEmail: 'Verifying email address...',
            header: 'Email Verification',
            continue: 'Start working with Solus now',
            login: 'Login to continue',
            success: 'Congratulations! Your account is verified now.',
            fail: 'Failed to verify the email.',
        },
        logout: 'Logout',
        activeDirectorySignIn: 'Sign in with Active Directory',
        twoFactorAuth: {
            title: 'Two-factor authentication',
            info: 'Open your two-factor authenticator app (TOTP) and enter the authentication code from the app here. You can also enter a recovery code if you cannot get the code from the application for any reason.',
            code: 'Authentication or recovery code',
            invalidCode: 'The authentication code must have 6 digits. Recovery code has the following format: XXXX-XXXX.',
            signIn: 'Sign In',
            signInToOtherAccount: 'Sign In to another account',
        },
        confirmIdentity: {
            dialog: {
                title: 'Confirm your identity',
            },
            form: {
                info: 'To prove that it is you, please enter your password.',
                password: 'Password',
                submit: 'Confirm',
            },
        },
    },
    validate: {
        badIpAddress: 'The IP address isn\'t valid.',
        badIpAddresses: 'The list of IP addresses isn\'t valid.',
        badNetmask: 'The netmask isn\'t valid.',
        badGateway: 'The gateway isn\'t valid.',
        badDNS: 'The DNS isn\'t valid.',
        badRange: 'The range isn\'t valid.',
        badUrl: 'The URL isn\'t valid.',
        badDomain: 'The domain is invalid because its FQDN can\'t be registered in DNS.',
        badPassword: 'Must be at least 8 characters long and contain the combination of lowercase (a-z), uppercase (A-Z), and numeric (0-9) characters.',
        fieldRequired: 'This field is required.',
        fieldNumeric: 'This field must be numeric.',
        wrongEmail: 'Fill in a valid email.',
        wrongIP: 'Fill in a valid IP address.',
        wrongConfiguration: 'You can\'t create a server with the selected combination of a plan, location, OS image/application. Please contact support.',
    },
    vncDialog: {
        title: 'VNC client',
        connecting: 'Connecting...',
        connected: 'Connected',
        connectionFailed: 'Could not establish the connection to the server console.',
        paste: {
            promptTitle: 'Enter the text to be sent to the console, "Enter / Return" won\'t be sent.',
        },
        accessDenied: 'Access denied',
    },
    snapshots: {
        createSnapshot: 'Create snapshot',
        create: 'Create',
        card: {
            save: 'Save',
        },
        list: {
            colName: 'Name',
            colStatus: 'Status',
            colSize: 'Size',
            colCreated: 'Created',
        },
        confirmationRemove: {
            text: 'Do you really want to remove this snapshot?',
            button: 'Remove',
        },
        confirmationRevert: {
            text: 'Do you really want to revert this snapshot?',
            button: 'Revert',
        },
        emptyView: {
            title: 'You don\'t have any Snapshots yet.',
            description: 'We recommend that you power down your server before taking a snapshot to ensure data consistency.',
        },
        status: {
            available: 'Available',
            failed: 'Failed',
            processing: 'Processing',
        },
    },
    servers: {
        guestToolsMissing: 'Guest tools are required for this action.',
        status: {
            running: 'Running',
            paused: 'Paused',
            unknown: 'Unknown',
            unavailable: 'Unavailable',
            started: 'Started',
            stopped: 'Stopped',
            noState: 'Unknown',
            processing: 'Processing',
            suspended: 'Suspended',
            resizing: 'Resizing',
            restarting: 'Restarting',
            stopping: 'Stopping',
            starting: 'Starting',
            creating: 'Creating',
            suspending: 'Suspending',
            resuming: 'Resuming',
            updating: 'Updating',
            deleting: 'Deleting',
            reinstalling: 'Reinstalling',
            migrating: 'Migrating',
            snapshotReverting: 'Reverting the snapshot',
            snapshotCreating: 'Creating the snapshot',
            snapshotDeleting: 'Deleting the snapshot',
            usageResetting: 'Resetting network usage',
            backupRestoring: 'Restoring the backup',
            backupCreating: 'Creating the backup',
            passwordChanging: 'Changing the password',
            additionalIpAdding: 'Adding IP',
            additionalIpDeleting: 'Deleting IP',
            primaryIpChanging: 'Changing Primary IP',
        },
        actions: {
            forceRestart: 'Forced restart',
            forceStop: 'Forced stop',
        },
        description: {
            ips: 'IPs',
        },
        delete: {
            cannotDelete: 'You do not have sufficient permissions to delete the server.',
        },
        create: {
            location: 'Location',
            plan: {
                dialog: {
                    title: 'Apply custom plan',
                },
                nonCustomizedCardTitle: 'Apply custom plan',
                customizedCardTitle: 'Custom plan',
                customize: 'Customize this plan',
                cpu: 'vCPU',
                ram: 'RAM',
                disk: 'Disk',
            },
            package: 'Plan',
            ipBlockTypes: 'IP address',
            ssh: {
                generate: 'Generate a new key pair',
                tooltip: 'Your key pair will be saved and downloaded.',
                addKey: 'Add SSH Key',
                createBtn: 'Add Public SSH Key',
                sectionTitle: 'SSH Keys',
                addBtn: 'Add Public SSH Key',
                showAll: 'Show all',
                addSelectedBtn: 'Add selected',
                placeholders: {
                    name: 'Name',
                    body: 'Public SSH Key',
                },
            },
            otherVersions: 'Other versions',
            serverType: {
                sectionTitle: 'Server Type',
                tabs: {
                    operatingSystems: 'Operating Systems',
                    applications: 'Applications',
                },
            },
            userData: {
                sectionTitle: 'User Data',
                drawerTitle: 'Set User Data',
                title: 'User Data',
                setBtn: 'Set',
                clearBtn: 'Clear',
                sampleBtn: 'Sample',
            },
        },
        tabs: {
            graph: {
                cpu: 'CPU',
                network: 'Network',
                diskThroughput: 'Disk Throughput',
                memory: 'Memory',
            },
            settings: {
                netfilter: {
                    text: 'To use containers like Docker or firewall features on the server, enable one of the three Netfilter modes: full, stateful, or stateless. Netfilter enables packet filtering, network address [and port] translation (NA[P]T), packet logging, userspace packet queueing, and other packet mangling.',
                },
                tunTAP: {
                    text: 'TUN/TAP is used to launches OpenVPN in the VZ container (OpenVPN will not work without TUN/TAP)',
                },
                ppp: {
                    text: 'Connects virtual servers using Point-To-Point protocol.',
                },
                vncConsole: {
                    title: 'VNC console',
                    button: 'Enable VNC console',
                    confirmationDialog: {
                        title: 'Enable VNC console',
                        content: 'This will reboot the server. Continue?',
                        button: 'Enable VNC console',
                    },
                },
                diskCacheMode: {
                    title: 'Disk cache mode',
                },
                guestTools: {
                    title: 'Guest Tools',
                    button: 'Install',
                    confirmationDialog: {
                        title: 'Install guest tools',
                        content: 'This will reboot the server. We recommend that you back up the server before running this operation. Continue?',
                        button: 'Install',
                    },
                },
                suspendResume: {
                    title: 'Suspend/Resume',
                    text: 'The server will be stopped and the owner will not be able to perform any actions on it.',
                },
            },
            rescue: {
                title: 'Boot & Rescue',
                resetPassword: {
                    title: 'Root Password',
                    content: 'This will set a new "root" password.',
                    button: 'Reset root password',
                    dialog: {
                        title: 'Reset Root Password',
                        content: 'After password reset, the server reboots and you receive a new "root" password by email. Continue?',
                        contentForNotOwner: 'After a password reset, the server is rebooted. You and the server owner will then receive a new "root" password by email. Continue?',
                        button: 'Reset',
                    },
                    newPasswordDialog: {
                        title: 'New Root Password',
                    },
                },
                rescue: {
                    disk: {
                        title: 'Boot from Disk',
                    },
                    iso: {
                        title: 'Boot from Rescue ISO',
                    },
                    isoImage: {
                        emptyTitle: 'Boot from ISO Image',
                        title: 'Boot from ISO Image %%name%%',
                    },
                    title: 'Boot',
                    description1: 'Here you can choose different boot options. Booting from the rescue ISO helps you fix kernel mismatches and corrupted file systems.',
                    description2: 'By default, a server is booted from its disk. If your server was booted from the rescue ISO and you want to boot the server from the disk again, do the following:',
                    description3: '1. Shut down or reboot your server. To shut down the server, click the Stop button above or use the command line.',
                    description4: '2. Power on your server. To do so, click the Start button above or boot the server from its disk.',
                    button: 'Apply',
                    buttonAndBuy: 'Apply & Buy Now',
                    isoImageDialog: {
                        title: 'Select ISO image',
                        placeholder: 'ISO image',
                        description: {
                            price: 'Booting from an ISO image costs %%monthPrice%%/month (%%hourPrice%%/hour).',
                            taxesInclusive: 'The price including %%taxes%%.',
                            taxesExclusive: 'The price excluding %%taxes%%.',
                            termsAndConditions: {
                                text: 'Our %%link%% apply.',
                                link: 'terms and conditions',
                            },
                        },
                        confirmation: {
                            header: 'Confirm the action(s)?',
                            linuxGuestTools: {
                                header: 'Remember to install guest tools by running the following commands:',
                                footer: 'After running the commands above, power off and start the server.',
                            },
                            windowsGuestTools: 'To install guest tools, you need to download and install the Cloudbase on the virtual server %%link%%.',
                            network: 'Don\'t forget to configure network of your server for the following IP addresses:',
                            networkFooter: 'You can find all the above information about IP addresses on the "Networking" tab of this server.',
                            ipList: {
                                ip: 'IP',
                                netmask: 'Netmask',
                                gateway: 'Gateway',
                                ns_1: 'Primary Name Server',
                                ns_2: 'Secondary Name Server',
                            },
                        },
                        button: 'Apply',
                    },
                },
            },
            networking: {
                title: 'Networking',
                traffic: {
                    incoming: {
                        title: 'Incoming traffic',
                        description: 'If you reach the incoming traffic limit, the network bandwidth will be reduced.',
                        exceededDescription: 'The network bandwidth is reduced because you\'ve reached the incoming traffic limit.',
                    },
                    outgoing: {
                        title: 'Outgoing traffic',
                        description: 'If you reach the outgoing traffic limit, the network bandwidth will be reduced.',
                        exceededDescription: 'The network bandwidth is reduced because you\'ve reached the outgoing traffic limit.',
                    },
                    total: {
                        title: 'Total traffic',
                        description: 'If you reach the total traffic limit, the network bandwidth will be reduced.',
                        exceededDescription: 'The network bandwidth is reduced because you\'ve reached the total traffic limit.',
                        outgoing: 'Outgoing:',
                        incoming: 'Incoming:',
                    },
                    resetPopover: {
                        title: 'Traffic Reset',
                        text: 'Reset the traffic? This will delete the information about the network traffic of the server.',
                        button: 'Reset traffic',
                        confirmationButton: 'Yes, reset!',
                    },
                },
                reverseDns: {
                    toasts: {
                        removed: 'Reverse DNS entry was removed.',
                        created: 'Reverse DNS entry was created.',
                        updated: 'Reverse DNS entry was updated.',
                    },
                    title: 'Primary IPs',
                    list: {
                        ip: 'IP',
                        netmask: 'Netmask',
                        cidr: 'CIDR',
                        gateway: 'Gateway',
                        ns_1: 'Primary Name Server',
                        ns_2: 'Secondary Name Server',
                        info: 'IP address information',
                        domain: 'Reverse DNS',
                        primary: 'Primary',
                        addReverseDns: 'Add Reverse DNS entry',
                        entries: '%%count%% Entries',
                    },
                    popover: {
                        ipv6: 'IPv6',
                        domain: 'Reverse DNS',
                        save: 'Save',
                        createTitle: 'Add Reverse DNS',
                        editTitle: 'Edit Reverse DNS entry',
                        information: {
                            button: 'Show details',
                            header: 'IP address information',
                        },
                    },
                    removePopover: {
                        title: 'Delete reverse DNS entry',
                        button: 'Delete',
                        tooltip: 'Delete reverse DNS entry',
                    },
                    tooltip: {
                        edit: 'Edit Reverse DNS entry',
                    },
                },
                additionalIps: {
                    button: 'Add IP',
                    toasts: {
                        removed: 'The IP was removed.',
                        removingFails: 'Failed to delete IP',
                        created: 'The IP was created.',
                        creatingFails: 'Failed to create IP',
                        primaryIpChanged: 'The primary IP was changed.',
                        primaryIpChangingFails: 'Failed to change primary IP.',
                    },
                    removePopover: {
                        title: 'Delete the additional IP',
                        button: 'Delete',
                        tooltip: 'Delete the additional IP',
                        tooltipForbiddenPrimary: 'Cannot delete primary IP',
                        text: 'While the IP address is being removed, the server will be temporarily unavailable',
                    },
                    createDialog: {
                        header: 'Add IP address',
                        warning: 'While the IP address is being added, the server will be temporarily unavailable.',
                        description: {
                            price: 'Add an additional IP for %%monthPrice%%/month (%%hourPrice%%/hour).',
                            taxesInclusive: 'The prices incl. %%taxes%%.',
                            taxesExclusive: 'The prices excl. %%taxes%%.',
                            termsAndConditions: {
                                text: 'Our %%link%% apply.',
                                link: 'terms and conditions',
                            },
                        },
                        createAndBuyButton: 'Create & Buy Now',
                        createButton: 'Create',
                        form: {
                            type: 'Type',
                            enableSpecificIp: 'Create a specific IP address',
                            ip: 'IP address',
                        },
                    },
                    changePrimaryIpConfirmationPopover: {
                        button: 'Change',
                        title: 'Change primary IP',
                    },
                },
            },
            reinstall: {
                popover: {
                    title: 'Reinstall server',
                    text: 'Reinstalling your server will power it down and overwrite its disk with the image you select. All previous data on the disk will be lost.',
                    button: 'Reinstall',
                },
                button: 'Reinstall',
                internalError: 'Service temporary unavailable',
                accessDenied: 'Access denied',
            },
            resize: {
                button: 'Resize',
                infoCard: {
                    title: 'Resize',
                    p1: 'Need more performance? Upgrade to a more powerful plan! You can resize your CPU and RAM only and leave your server’s disk untouched or expand the disk as well.',
                    p2: 'The server can be stopped before resize. Resize usually takes just a few minutes.',
                    cpuAndRamOnly: {
                        title: 'CPU and RAM only',
                        description: 'This leaves the disk size as it is, which gives you the option to downgrade later.',
                    },
                    backupInfo: 'You can\'t change your disk size because the server has incremental backups.',
                },
                emptyView: {
                    title: 'No plans you can use for resize',
                },
                popover: {
                    title: 'Resize Server',
                    resizeText: 'The server will be stopped. The server\'s parameters and the price will be changed according to the selected plan. A server resize deletes all the server\'s snapshots.',
                    changePlanText: 'The server\'s parameters and the price will be changed according to the selected plan.',
                    button: 'Resize',
                    buyButton: 'Resize and Buy now',
                },
                tooltips: {
                    activeTasksRunning: 'The server has running tasks. Please wait until the tasks are finished',
                    samePlan: 'A plan you use for resize must differ from the current one',
                    selectPlan: 'You must select a plan for resize',
                    hddSizeExceed: 'The disk size of the plan for resize must be greater than that of the current plan',
                },
                planDialog: {
                    header: 'Confirm the action(s)?',
                    additionalIpDescription: 'Disabling additional IPs will delete already existing additional IPs for this server.',
                    perhapsBackupDescription: 'Disabling backups will irrevocably delete already existing backups of this server if backups are not used in the global settings.',
                    backupDescription: 'Disabling backups will irrevocably delete already existing backups of this server.',
                    networkLimitsDescription: 'The network traffic limits will be exceeded because the plan you\'re going to switch to has lower limits than the plan you\'re currently on.',
                    backupsNumberDescription: 'The number of backup files will be reduced because the plan you\'re going to switch to has the lower backup limit than the plan you\'re currently on.',
                },
            },
        },
        rescueISO: 'Rescue ISO',
        controls: {
            kvmDeskCacheMode: {
                confirmation: {
                    title: 'Change disk cache mode',
                    text: 'This will reboot the server. Continue?',
                    button: 'Change disk cache mode',
                },
            },
            suspendResume: {
                label: 'Suspended',
                confirmation: {
                    suspend: {
                        title: 'Suspend the server',
                        text: 'This will suspend the server. Continue?',
                        button: 'Suspend',
                    },
                    resume: {
                        title: 'Resume the server',
                        text: 'This will resume the server. Continue?',
                        button: 'Resume',
                    },
                },
            },
            vzNetfilterStatus: {
                confirmation: {
                    title: 'Change Netfilter',
                    text: 'This will reboot the server. Continue?',
                    button: 'Change',
                },
            },
            vzPPP: {
                confirmation: {
                    title: 'Change PPP',
                    text: 'This will reboot the server. Continue?',
                    button: 'Change',
                },
            },
            vzTUNTAP: {
                confirmation: {
                    title: 'Change TUN/TAP',
                    text: 'This will reboot the server. Continue?',
                    button: 'Change',
                },
            },
        },
    },
    tooltips: {
        listView: 'List view',
        cardView: 'Card view',
    },
    invalidAuthLink: 'Auth link is invalid',
    backup: {
        type: {
            auto: 'Auto',
            manual: 'Manual',
            full: 'Full',
            incremental: 'Incremental',
        },
        status: {
            pending: 'Pending',
            inProgress: 'In Progress',
            failed: 'Failed',
            created: 'Created',
            creating: 'Creating',
            restoring: 'Restoring',
        },
        list: {
            id: 'ID',
            status: 'Status',
            type: 'Type',
            creationMethod: 'Creation method',
            createdAt: 'Created',
            size: 'Size, GiB',
            backupSize: 'Backup size, GiB',
            serverDisk: 'Server disk, GiB',
            computeResourceVm: 'Virtual Server',
            computeResource: 'Compute Resource',
            backupNode: 'Backup Node',
            backupType: 'Backup Type',
            creator: 'User',
        },
        buttonWithConfirmation: {
            confirmationText: 'Delete Backup',
            confirmationTextWithIncremental: 'Delete Backup. All related incremental backups with IDs %%backups%% will be deleted as well.',
            button: 'Delete',
            title: 'Delete Backup',
            tooltip: 'Delete the backup?',
            cantDeleteIncrementalBackup: 'You can\'t delete an incremental backup.',
        },
        restorePopover: {
            confirmationText: 'The backup restoration overwrites all data currently stored in the server with those stored in backup. This operation cannot be undone.',
            button: 'Restore',
            title: 'Restore from backup',
            tooltip: 'Restore from backup.',
            disabledTooltip: {
                doesNotHaveServer: 'The backup isn\'t connected to any server.',
                serverIsProcessing: 'The server is processing a task.',
                backupIsNotEnabled: 'The backup isn\'t enabled.',
                diskIsTooBig: 'The backup disk is larger than the server disk.',
                inRescueMode: 'You can\'t restore a backup of a server in Rescue Mode.',
                cannotRestore: 'You can\'t restore a backup of a server.',
                isGarbageBackup: 'The backup isn\'t enabled.',
                isNotCreatedBackup: 'The backup isn\'t created.',
                serverIsSuspended: 'The server is suspended.',
                unavailableBackupNodes: 'The backup unavailable.',
                serverInvalidStatus: 'The server has invalid status.',
            },
        },
        toasters: {
            listLoadFail: 'Could not load the server\'s backups.',
            deleteFail: 'Cannot delete the backup because it is used at the moment.',
            restoreSuccess: 'The server %%computeResourceVm%% was restored from the backup.',
            restoreFail: 'Could not restore the server from the backup.',
            batchRemoved: 'The backups were deleted.',
        },
        tooltip: {
            details: 'View details',
        },
        detailsDialog: {
            title: 'Backup fail reason',
        },
    },
    backupCard: {
        title: 'Backups',
        settingsTitle: 'Schedule settings',
        content: {
            disabled: 'To use backups, enable this feature.',
            enabled: 'Next backup on %%schedule_date%%',
        },
        scheduleSettings: 'Schedule settings',
        switchTooltip: {
            planNotAvailable: 'Can\'t enable backups because the plan doesn\'t offer them.',
        },
        disableConfirm: {
            title: 'Disable backups',
            textPerhapsDeleteBackups: 'Disabling backups also deletes any existing backup files for this server if backups are not used in the global settings. The data cannot be retrieved later.',
            textDeleteBackups: 'Disabling backups also deletes any existing backup files for this server. The data cannot be retrieved later.',
            button: 'Disable',
        },
        enableConfirm: {
            default: {
                title: 'Enable backups',
                text: 'Backups cost an additional %%percent%%% of the server price.',
                button: 'Enable',
            },
            qcow2: {
                text: 'A server with the QCOW2 image format loses its snapshots after being restored from a backup. It happens because of the way snapshots are implemented for QCOW2.',
            },
        },
        backupsNumber: {
            limited: 'Backup limit: %%limit%%',
            unlimited: 'Backup limit: Unlimited',
            description: 'Maximum number of backups to store (including scheduled and manual backups)',
        },
    },
    backupSettingsForm: {
        saveBtn: 'Save Changes',
        limitLabel: 'Maximum number of backups to store (including scheduled and manual backups)',
    },
    schedule: {
        type: {
            monthly: 'Monthly',
            weekly: 'Weekly',
            daily: 'Daily',
            hourly: 'Hourly',
        },
        schedule: 'Schedule',
        days: 'Days',
        time: 'Time',
        minutes: 'Every hour at certain minute',
    },
    weekday: {
        monday: 'Monday',
        tuesday: 'Tuesday',
        wednesday: 'Wednesday',
        thursday: 'Thursday',
        friday: 'Friday',
        saturday: 'Saturday',
        sunday: 'Sunday',
    },
    copyText: {
        copied: 'Copied!',
        copy: 'Copy to clipboard',
    },
    confirmation: {
        dialog: {
            ok: 'OK',
            cancel: 'Cancel',
            forceConfirm: 'force confirm',
        },
    },
    actionStatus: {
        viewDetails: 'View details',
    },
    List: {
        selectedRows: '%%selectedRows%% of %%totalRows%% items selected',
        totalRows: '%%totalRows%% items found',
        selectAll: 'Select all',
        deselectAll: 'Deselect',
        all: 'All',
    },
    Pagination: {
        total: 'Page %%current%% of %%total%%',
        totalShort: 'of %%total%%',
        itemsPerPage: 'Items per page',
        all: 'All',
    },
    limitForm: {
        limit: 'Limit',
        unlimited: 'Unlimited',
    },
    languages: {
        customized: '(customized)',
    },
    vzParameters: {
        tunTAP: {
            confirmationPopoverTitle: 'Change TUN/TAP',
        },
        ppp: {
            confirmationPopoverTitle: 'Change PPP',
        },
    },
    components: {
        disableEnableSegmentedControl: {
            disabled: 'Disabled',
            enabled: 'Enabled',
            confirmText: 'This will reboot the server. Continue?',
        },
        vzNetfilterStatusSegmentedControl: {
            confirmationPopoverTitle: 'Change Netfilter',
            tooltips: {
                disabled: 'Disables all packet processing features of the Linux kernel. This mode is the most secure against kernel module vulnerabilities.',
                stateless: 'Enable to use some firewall features but reduce the exposure to kernel module vulnerabilities.',
                stateful: 'Enable to use the majority of the firewall features.',
                full: 'Enable to use containers like Docker. The mode enables all packet processing features of the Linux kernel, which means the highest exposure to kernel module vulnerabilities.',
            },
            confirmText: 'This will reboot the server. Continue?',
        },
        vzTUNTAPSegmentedControl: {
            confirmationPopoverTitle: 'Change TUN/TAP',
        },
        vzPPPSegmentedControl: {
            confirmationPopoverTitle: 'Change PPP',
        },
    },
    change: 'Change',
    planInfo: {
        cpu: '%%cpu%% vCPU',
        disk: '%%disk%% GiB Disk',
        memory: '%%ram%% MiB RAM',
    },
    isoImage: {
        title: 'ISO images',
        addButton: 'Add ISO image',
        type: 'Type',
        size: 'Size: %%value%% GiB',
        visibility: {
            public: 'Public',
            private: 'Private',
        },
        list: {
            id: 'ID',
            name: 'Name',
            user: 'Owner',
            osType: 'Operating System',
            visibility: 'Visibility',
            size: 'Size (GiB)',
        },
        form: {
            name: 'Name',
            icon: 'Icon',
            user: 'Owner',
            visibility: 'Visibility',
            osType: 'Operating System Type',
            url: 'ISO URL',
            useTls: 'Verify SSL/TLS certificate',
            checksumMethod: 'ISO checksum method',
            checksum: 'ISO checksum',
            showUrlAndChecksum: 'Show image URL and checksum',
            showTls: 'Show security options',
            saveBtn: 'Save',
        },
        dialog: {
            createTitle: 'Create ISO image',
            editTitle: 'Edit ISO image',
        },
        removePopover: {
            title: 'Delete ISO image',
            button: 'Delete',
            tooltip: 'Delete ISO image',
            disabledTooltip: 'You can\'t remove public ISO image',
        },
        tooltip: {
            edit: 'Edit ISO image',
            disabled: 'You can\'t edit public ISO image',
        },
        toasts: {
            deleted: 'The ISO image was deleted.',
            saved: 'The ISO image was saved.',
        },
        emptyView: {
            buttonText: 'Add ISO image',
            title: 'No ISO images added yet.',
            description: 'Go ahead and create your first ISO image now—it only takes a few seconds.',
        },
        description: {
            checksum: 'Copy the file checksum to clipboard.',
            tls: 'The SSL/TLS certificate of the ISO image will be verified.',
            withoutTls: 'The SSL/TLS certificate of the ISO image won\'t be verified.',
        },
    },
    commonAccount: {
        toasts: {
            passwordChanged: 'Password was changed.',
            twoFactorAuthEnabled: 'Two-factor authentication was enabled.',
            twoFactorAuthDisabled: 'Two-factor authentication was disabled.',
            twoFactorAuthRecoveryCodeCountLeft: 'Recovery code count left: %%count%%.',
        },
        twoFactorAuthForm: {
            info: 'You can use an authenticator application that supports TOTP (such as Google Authenticator or Microsoft Authenticator) to generate codes. Those codes can be used as a second factor to verify your identity.',
            qrCode: 'Scan the QR code with your app to enable two-factor authentication:',
            secretKey: 'Or copy the secret key to the app:',
            code: 'Code from the app',
            invalidCode: 'The code must have %%length%% digits.',
            enableBtn: 'Enable',
            disableBtn: 'Disable',
            recoveryCodeCount: 'Recovery code count: %%count%%',
            generateNewRecoveryCodes: 'Generate new recovery codes',
            isEnabled: 'Two-factor authentication is enabled.',
            recoveryCodesDialog: {
                title: 'Recovery codes',
                info: 'You can use these one time recovery codes if you lose access to your device and cannot receive two-factor authentication codes. Save them in a safe place. You will not be shown these codes again after closing this window.',
                copyAndClose: 'Copy & Close',
            },
            generatingNewRecoveryCodesConfirmationDialog: {
                title: 'Generate new recovery codes',
                content: 'New recovery codes will be generated. All existing recovery codes will be deactivated. Continue?',
                button: 'Generate',
            },
            disablingConfirmationDialog: {
                title: 'Disable two-factor authentication',
                content: 'Two-factor authentication will be disabled. All existing recovery codes will be deactivated. Continue?',
                button: 'Disable',
            },
        },
    },
};

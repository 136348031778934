// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import * as React from 'react';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import SelectInput from 'common/components/Select/SelectInput';
import { statusMappings } from 'common/components/VmStatus';
import { Translate } from '@plesk/ui-library';

export interface IComputeResourceVmStatusFilterProps {
    onChange?: (option: ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export const ComputeResourceVmStatusFilter: React.FC<IComputeResourceVmStatusFilterProps> = ({
    onChange,
    value,
}) => {
    const statuses: ISelectRequiredOption[] = Object.keys(statusMappings).map(key => ({
        value: key,
        label: statusMappings[key],
    }));

    return (
        <FixedFormField data-cy={FILTERS.COMPUTE_RESOURCE_VM_STATUS}>
            <SelectInput<ISelectRequiredOption>
                inputId="compute-resource-vm-status-filter"
                placeholder={<Translate content="filters.computeResourceVmStatus"/>}
                options={statuses}
                isClearable={true}
                onChange={onChange}
                value={value}
            />
        </FixedFormField>
    );
};

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    Dispatch,
    Store,
} from 'redux';
import { api } from 'common/api/resources/Response';
import { logout } from 'common/modules/auth/actions';
import {
    clearFormErrors,
    setFormErrors,
} from 'common/modules/app/formErrors/actions';
import {
    clearResponseError,
    setResponseError,
} from 'common/modules/app/responseError/actions';
import { HTTP_CODES } from 'common/api/constants';
import { INTENT_TYPE } from 'common/constants';
import {
    bakeForegroundToast,
    bakeToast,
} from 'common/modules/app/toaster/actions';
import Axios from 'axios';
import { ICommonState } from 'common/store';
import { openConfirmIdentityDialog } from 'common/modules/auth/confirmIdentity/actions';

export interface IError {
    message: string;
    response?: {
        status: number;
        data: {
            message: string;
            errors: Record<string, unknown>;
        };
    };
}

export const onRejected = (dispatch: Dispatch, getState: () => ICommonState) => (error: IError) => {
    if (Axios.isCancel(error)) {
        return Promise.reject(error);
    }

    if (error.response) {
        if (error.response.status === HTTP_CODES.UNAUTHORIZED) {
            if (error.response.data.message === 'Identity verifying required.') {
                openConfirmIdentityDialog()(dispatch);
                return Promise.reject(error);
            }
            logout()(dispatch, getState);
            return Promise.reject(error);
        }

        if (error.response.status === HTTP_CODES.VALIDATION_ERROR) {
            dispatch(setFormErrors(error.response.data.errors));
            return Promise.reject(error);
        }

        if (error.response.status === HTTP_CODES.NOT_FOUND) {
            return Promise.reject(error);
        }

        if (error.response.status === HTTP_CODES.GATEWAY_TIMEOUT) {
            bakeForegroundToast(INTENT_TYPE.DANGER, 'gatewayTimeout')(dispatch);
            return Promise.reject(error);
        }

        if (error.response.status >= HTTP_CODES.BAD_REQUEST) {
            bakeToast(
                INTENT_TYPE.DANGER,
                error.response.data.message,
                {},
                false,
                false
            )(dispatch);

            dispatch(setResponseError({
                code: error.response.status,
                error: error.response.data.message,
            }));
            return Promise.reject(error);
        }
    }

    bakeForegroundToast(INTENT_TYPE.DANGER, 'toasts.networkError')(dispatch);

    dispatch(setResponseError({
        code: 100,
        error: error.message,
    }));

    return Promise.reject(error);
};

export const setUpInterceptors = (store: Store) => {
    api.interceptors.request.use(config => {
        store.dispatch(clearFormErrors());
        store.dispatch(clearResponseError());

        config.headers['Accept-Language'] = store.getState().app.language.locale;

        return config;
    }, error => Promise.reject(error));

    api.interceptors.response.use(response => response, onRejected(store.dispatch, store.getState));
};

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { Loader } from 'common/components';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { Translate } from '@plesk/ui-library';
import * as authActions from 'common/modules/auth/actions';
import * as mailConfirmationActions from 'client/project/actions/mailConfirmation';
import { AlertMessage } from 'client/auth/components/AlertMessage/AlertMessage';
import LogoBox from 'common/modules/auth/components/LogoBox/LogoBox';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RootState } from 'client/core/store';
import { AuthContainer } from 'common/modules/auth/Styles';

export type VerifyEmailProps =
    RouteComponentProps<{ id: string}> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VerifyEmail: React.FC<VerifyEmailProps> = ({
    location,
    isVerifying,
    match: { params },
    authActions: { verifyEmail },
    mailConfirmationActions: { setIsShown },
}) => {
    const [isVerified, setVerified] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');

    React.useEffect(() => {
        const verify = async() => {
            const token = new URLSearchParams(location.search).get('token');

            try {
                await verifyEmail(parseInt(params.id, 10), { token });

                setVerified(true);
                setIsShown(true);
            } catch (e) {
                setErrorMessage(e.response.data.message);
            }
        };

        verify();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (isVerified) {
        return <Redirect to="/" />;
    }

    if (isVerifying) {
        return (
            <Loader
                text={<Translate content="auth.verify.verifyingEmail" />}
                isLoading={!errorMessage.length}
            />
        );
    }

    return (
        <AuthContainer>
            <LogoBox />
            {errorMessage && (
                <AlertMessage
                    message={errorMessage}
                    title={<Translate content="auth.verify.fail" />}
                />
            )}
        </AuthContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    isVerifying: state.app.loadingFlags.has(LOADING_FLAGS.VERIFY_EMAIL),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    authActions: bindActionCreators(authActions, dispatch),
    mailConfirmationActions: bindActionCreators(mailConfirmationActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmail));

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { RootState } from 'client/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { unsetList } from 'common/modules/backup/actions';
import { BackupsTab } from 'common/components/ServerTabs/BackupsTab/BackupsTab';
import { BackupsTableColumns } from 'common/modules/backup/containers/BackupsTable';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';

const mapStateToProps = (state: RootState) => ({
    columns: {
        [BackupsTableColumns.ID]: { width: '1%' },
        [BackupsTableColumns.STATUS]: { width: '10%' },
        [BackupsTableColumns.CREATED_AT]: { width: '10%' },
        [BackupsTableColumns.CREATION_METHOD]: { width: '10%' },
        [BackupsTableColumns.DISK_SIZE]: { width: '10%' },
        [BackupsTableColumns.ACTIONS]: { width: '1%' },
    },
    shouldHideStatusDetailsButton: true,
    confirmEnableBackupsOnQcow: false,
    item: state.project.servers.item,
    isBackupSettingsUpdating: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_SETTINGS_UPDATE),
    isBackupLoading: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_LIST),
    shouldShowBackupsWhenDisabled: false,
    isBackupEnabledGlobally: false,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    unsetBackups: bindActionCreators(unsetList, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(BackupsTab);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/auth/confirmIdentity/constants';
import { Dispatch } from 'redux';
import {
    auth,
    IAuthenticateRequest,
} from 'common/api/resources/Auth';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export const setConfirmIdentityIsIsOpened = createCustomAction(
    types.SET_CONFIRM_IDENTITY_IS_OPENED,
    (isOpened: boolean) => ({ payload: isOpened })
);

export const openConfirmIdentityDialog = () => (dispatch: Dispatch) => {
    dispatch(setConfirmIdentityIsIsOpened(true));
};

export const closeConfirmIdentityDialog = () => (dispatch: Dispatch) => {
    dispatch(setConfirmIdentityIsIsOpened(false));
};

export const authenticate = (data: IAuthenticateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.AUTHENTICATE));
    try {
        return await auth.authenticate(data);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.AUTHENTICATE));
    }
};
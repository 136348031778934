// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { ICONS } from 'common/constants';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { FEATURE } from 'common/api/resources/Settings';

export interface IMenuItem {
    path: string;
    icon: ICONS;
    value: string;
    permission?: string[];
    feature?: string;
}

export const menuItems: IMenuItem[] = [{
    path: '/projects',
    icon: ICONS.PROJECTS,
    value: 'menu.projects',
}, {
    path: '/account',
    icon: ICONS.USER,
    value: 'menu.account',
}, {
    path: '/iso_images',
    icon: ICONS.CD,
    value: 'menu.isoImages',
    permission: [PERMISSION_LIST.MANAGE_ALL_ISO_IMAGES, PERMISSION_LIST.MANAGE_OWNED_ISO_IMAGES],
}, {
    path: '/documentation',
    icon: ICONS.GLOBE,
    value: 'menu.documentation',
    feature: FEATURE.HIDE_API_DOCUMENTATION_LINK,
}];

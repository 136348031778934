// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { INTENT_TYPE } from 'common/constants';
import { StyledButton } from 'admin/common/components/Button/Styles';
import { Button as UILibraryButton } from '@plesk/ui-library';

export interface IButtonProps extends UILibraryButton {
    intent?: INTENT_TYPE;
    disabled?: boolean;
    size?: string;
    icon?: string;
    type?: string;
    onClick?: () => void;
    fill?: boolean;
    isLoading?: boolean;
    state?: string | null;
    form?: string;
    ghost?: boolean;
    tooltip?: React.ReactNode;
}

export const Button: React.FC<React.PropsWithChildren<IButtonProps>> = ({ children, isLoading, state, ...rest }) => {
    const buttonState = state ? state : null;

    return (
        <StyledButton state={isLoading ? 'loading' : buttonState} {...rest}>
            {children}
        </StyledButton>
    );
};

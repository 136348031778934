// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IVlanListRequest,
    IVlanPatchRequest,
    IVlanRequest,
    IVlanResponse,
} from 'common/api/resources/Vlan/model';

export const vlans = {
    list: (params?: IVlanListRequest) => api.get<IPaginateApiResponse<IVlanResponse[]>>('vlans', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IVlanResponse>>(`vlans/${id}`),
    remove: (id: number) => api.delete(`vlans/${id}`),
    create: (data: IVlanRequest) => api.post<IApiResponse<IVlanResponse>>('vlans', data),
    update: (id: number, data: IVlanRequest) => api.put<IApiResponse<IVlanResponse>>(`vlans/${id}`, data),
    patch: (id: number, data: IVlanPatchRequest) => api.patch<IApiResponse<IVlanResponse>>(`vlans/${id}`, data),
};
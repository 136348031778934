// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import {
    FC,
    ReactNode,
    useState,
    useEffect,
    useCallback,
} from 'react';
import { connect } from 'react-redux';
import { PageSection } from 'common/components/PageHeader/Styles';
import PageHeader from 'common/components/PageHeader/PageHeader';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as vlanActions from 'admin/vlan/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import { Loader } from 'common/components';
import VlanForm from 'admin/vlan/containers/VlanForm';
import ActionsColumn from 'admin/vlan/components/ActionsColumn';
import { EmptyView } from 'common/components/EmptyView/EmptyView';

const columns = [
    {
        key: 'colId',
        width: '1%',
        title: <Translate content="vlan.list.id" />,
    },
    {
        key: 'colName',
        title: <Translate content="vlan.list.name" />,
        cellProps: {
            className: 'cell-bold',
        },
    },
    {
        width: '15%',
        key: 'colTag',
        title: <Translate content="vlan.list.tag" />,
    },
    {
        key: 'colMode',
        title: <Translate content="vlan.list.mode" />,
    },
    {
        key: 'colTrunks',
        title: <Translate content="vlan.list.trunks" />,
    },
    getActionColumnProps(),
];

type Column = typeof columns[number]['key'];
type DataItem =
    & { [K in Column]: ReactNode }
    & { key: string };

export type VlansProps =
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const Vlans: FC<VlansProps> = ({
    vlan: {
        item,
        list: items,
    },
    vlanActions: {
        getVlan,
        getVlans,
        removeVlan,
        unsetVlanItem,
    },
    loadingFlags: {
        isLoadingList,
        isLoadingItem,
    },
}) => {
    const [dialogOpened, setDialogOpened] = useState(false);
    const handleClose = () => setDialogOpened(false);

    const isFirstLoading = useIsFirstLoading(isLoadingList);
    const loadPage = useCallback((page: number) => {
        getVlans({
            page,
        });
    }, [getVlans]);

    useEffect(() => {
        loadPage(1);
    }, [loadPage]);

    const handleCreate = () => {
        unsetVlanItem();
        setDialogOpened(true);
    };
    const handleEdit = (id: number) => async () => {
        await getVlan(id);
        setDialogOpened(true);
    };
    const handleRemove = (id: number) => async () => {
        await removeVlan(id);
        reloadListData(items, loadPage);
    };

    const data = items.data.map((vlan): DataItem => ({
        colId: vlan.id,
        colName: vlan.name,
        colTag: vlan.tag,
        colMode: vlan.mode,
        colTrunks: vlan.trunks
            ? vlan.trunks.join(', ')
            : '—',
        colActions: <ActionsColumn vlan={vlan} handleEdit={handleEdit} handleRemove={handleRemove} />,
        key: vlan.id.toString(),
    }));

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        <Translate content="vlan.title" />
                    </PageSection>
                )}
                actionButton={(
                    <Button
                        intent={INTENT_TYPE.PRIMARY}
                        size={SIZE.LG}
                        icon={ICONS.PLUS}
                        onClick={handleCreate}
                    >
                        <Translate content="vlan.createBtn" />
                    </Button>
                )}
            />
            <List
                emptyView={
                    <EmptyView
                        title="vlan.emptyView.title"
                        description="vlan.emptyView.description"
                        buttonText="vlan.emptyView.buttonText"
                        onButtonClick={handleCreate}
                        icon={ICONS.IP_ADDRESSES}
                    />
                }
                isLoading={isLoadingList}
                isFirstLoading={isFirstLoading}
                columns={columns}
                loadItems={loadPage}
                data={data}
                meta={items.meta}
            />
            <Dialog
                heading={
                    <Translate content={item?.id ? 'vlan.form.titleEdit' : 'vlan.form.titleAdd'} />
                }
                closeHandler={handleClose}
                isOpen={dialogOpened}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <VlanForm onSubmit={handleClose} />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    vlan: state.vlan,
    loadingFlags: {
        isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.VLAN_LIST),
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.VLAN_ITEM),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vlanActions: bindActionCreators(vlanActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Vlans);
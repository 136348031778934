// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as storageActions from 'admin/storage/actions';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Button,
    List,
    Switch,
    Translate,
} from '@plesk/ui-library';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import {
    STORAGE_TYPES_TRANSLATION_MAP,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import StorageForm from 'admin/storage/containers/StorageForm';
import { IStorageResponse } from 'common/api/resources/Storage';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/storage/constants/tests';
import { getActionColumnProps } from 'common/helpers/list';

export type StoragesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="storage.list.id" />,
},  {
    width: '10%',
    key: 'colAvailableForBalancing',
    title: <Translate content="storage.list.availableForBalancing" />,
}, {
    key: 'colName',
    title: <Translate content="storage.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colType',
    title: <Translate content="storage.list.type" />,
}, {
    key: 'colServers',
    title: <Translate content="storage.list.servers" />,
}, {
    key: 'colCrs',
    title: <Translate content="storage.list.crs" />,
}, getActionColumnProps(),
];

export const Storages: React.FC<StoragesProps> = ({
    storageActions: {
        loadStoragesOnScroll,
        getStorages,
        getStorage,
        removeStorage,
        patchComputeResourceStorage,
    },
    storage: {
        list,
        item,
    },
    loadingFlags: {
        isLoadingList,
        isLoadingScroll,
        isLoadingItem,
    },
}) => {
    const [isOpen, setIsOpen] = React.useState(false);

    React.useEffect(() => {
        getStorages({
            filters: {
                type: StorageType.NFS,
            },
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreate = () => setIsOpen(true);
    const handleRemove = (id: number) => async() => await removeStorage(id);
    const handleClose = () => setIsOpen(false);
    const handleEdit = (id: number) => async() => {
        getStorage(id);
        setIsOpen(true);
    };

    const handleToggleIsAvailableForBalancing = (storage: IStorageResponse) => async() => {
        try {
            await patchComputeResourceStorage(storage.id, {
                is_available_for_balancing: !storage.is_available_for_balancing,
            });
        } catch (e) {
            throw e;
        }
    };

    const data = list.data.map((storage) => {
        const actionsEl = (
            <StyledActions>
                <Button
                    ghost={true}
                    disabled={storage.compute_resources_count > 0}
                    icon={ICONS.PENCIL}
                    className="action-icon"
                    onClick={handleEdit(storage.id)}
                    data-cy={dataCySelector(storage.id, TABLE_ACTIONS.EDIT)}
                    tooltip={
                        <Translate content="storage.tooltip.edit"/>
                    }
                />
                <ButtonWithConfirmation
                    disabled={storage.compute_resources_count > 0}
                    isLoading={storage.isDeleting}
                    translations={{
                        button: (<Translate content="storage.buttonWithConfirmation.button" />
                        ),
                        title: (
                            <Translate content="storage.buttonWithConfirmation.title" />
                        ),
                        tooltip: (
                            <Translate content="storage.tooltip.remove" />
                        ),
                    }}
                    handleConfirm={handleRemove(storage.id)}
                    icon={ICONS.RECYCLE}
                    data-cy={dataCySelector(storage.id, TABLE_ACTIONS.REMOVE)}
                />
            </StyledActions>
        );

        return {
            colId: storage.id,
            colAvailableForBalancing: (
                <Switch
                    loading={storage.isLoading}
                    checked={storage.is_available_for_balancing}
                    onChange={handleToggleIsAvailableForBalancing(storage)}
                />
            ),
            colName: storage.name,
            colType: STORAGE_TYPES_TRANSLATION_MAP[storage.type.name],
            colServers: (
                <Button ghost={true}>
                    {storage.servers_count}
                </Button>
            ),
            colCrs: (
                <Button ghost={true}>
                    {storage.compute_resources_count}
                </Button>
            ),
            colActions: actionsEl,
            key: storage.id.toString(),
        };
    });

    return (
        <>
            <PageHeader
                title={<Translate content="storage.title"/>}
                buttonText="storage.addBtn"
                buttonIcon={ICONS.RESOURCE}
                onButtonClick={handleCreate}
                isButtonShown={data.length > 0}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadStoragesOnScroll}
                    hasMore={!!list.links.next && !isLoadingScroll}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="storage.emptyView.title"
                                    description="storage.emptyView.description"
                                    buttonText="storage.emptyView.buttonText"
                                    onButtonClick={handleCreate}
                                    icon={ICONS.RESOURCE}
                                />
                            }
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={
                    <Translate
                        content={item.id ? 'storage.form.titleEdit' : 'storage.form.titleAdd'}
                    />
                }
                closeHandler={handleClose}
                isOpen={isOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <StorageForm closeDialog={handleClose} />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    storage: state.storage,
    errors: state.app.formErrors,
    loadingFlags: {
        isLoadingScroll: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_LIST)
            && !state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_LIST_INFINITY_SCROLL),
        isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_LIST),
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_GET),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    storageActions: bindActionCreators(storageActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Storages);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import selectEvent from 'react-select-event';
import {
    fireEvent,
    screen,
    within,
    Matcher,
    waitForElementToBeRemoved,
} from '@testing-library/react';

export const dataCySelector = (selectorId: number | string, postfix: string): string => `${selectorId}-${postfix}`;

const isHtmlInputElement = (node: HTMLElement): node is HTMLInputElement => node instanceof HTMLInputElement;

type Value = number | string | boolean;

export const setInputValue = (selector: string | number, value: Value, screenSelector = screen.getByLabelText) => {
    if (typeof value === 'boolean') {
        const currentValue = screenSelector(selector);

        if (!isHtmlInputElement(currentValue)) {
            throw new Error(`${selector} is not an html input element`);
        }

        if (value !== currentValue.checked) {
            fireEvent.click(screenSelector(selector));
        }
        return;
    }

    valueSetter(screenSelector(selector), value);
};

export const valueSetter = (selector: HTMLElement, value: string | number | boolean | unknown[] | Record<string, unknown>) => {
    fireEvent.change(selector, { target: { value } });
    fireEvent.blur(selector);
};

export const simulateAsyncSelect = async (selector: HTMLElement, optionOrOptions: Matcher | Matcher[]) => {
    const options = Array.isArray(optionOrOptions) ? optionOrOptions : [optionOrOptions];

    for (const option of options) {
        selectEvent.openMenu(selector);
        const node = await screen.findByText(option);
        fireEvent.click(node);
    }
};

export const simulateSelect = async (selector: HTMLElement, option: string|string[]) => {
    await selectEvent.openMenu(selector);

    const options = typeof option === 'string' ? [option] : option;
    options.forEach(opt => fireEvent.click(screen.getByRole('menuitem', { name: opt })));

    // close menu
    if (screen.queryByRole('menu')) {
        fireEvent.click(document.body);
        await waitForElementToBeRemoved(screen.getByRole('menu'));
    }
};

export const setLimit = (selectorId: string, limit: number) => {
    fireEvent.click(within(screen.getByTestId(selectorId)).getByText('[limitForm.limit]'));
    valueSetter(within(screen.getByTestId(selectorId)).getAllByRole('textbox')[0], limit);
};

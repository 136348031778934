// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { PageSection } from 'common/components/PageHeader/Styles';
import {
    Action,
    Button,
    Form,
    FormField,
    Grid,
    GridCol,
    Icon,
    setIn,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as H from 'history';
import {
    ComputeResourceVmCustomPlan,
    defaultBackupSettings,
    IBackupSettings,
    IVmCreateRequest,
} from 'common/api/resources/ComputeResourceVm';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import ServerTypeTabs from 'common/components/serverType/ServerTypeTabs';
import ServerUserData from 'common/components/ServerUserData/ServerUserData';
import PlanList,
{ CUSTOM_PLAN } from 'common/components/plan/PlanList';
import {
    generateName,
    generatePassword,
} from 'common/helpers/vm';
import { sshKeys } from 'common/api/resources/SshKey';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { loadComputeResourceVmCreatePageData } from 'common/modules/computeResourceVm/actions';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import { HTTP_CODES } from 'common/api/constants';
import {
    ISelectOption,
    Loader,
} from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { PageSubHeader } from 'client/common/components';
import {
    ErrorMessage,
    Footer,
    InlineFields,
    InputNumber,
    InputPassword,
    InputPrimaryIp,
    InputText,
    InputWithRefreshButton,
    SubmitButton,
} from 'admin/computeResourceVm/containers/ComputeResourceVmCreate/Styles';
import SelectInput from 'common/components/Select/SelectInput';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { createOptionsLoader } from 'common/components/Select/helpers';
import * as computeResourceActions from 'admin/computeResource/actions';
import * as userProjectsActions from 'admin/user/actions/projects';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IProjectResponse } from 'common/api/resources/Project';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import {
    domainRule,
    intMinRule,
    ipRule,
    requiredRule,
    validate,
} from 'common/validator';
import { TEST } from 'admin/computeResourceVm/constants';
import BackupCard from 'common/containers/BackupCard/BackupCard';
import { IPlanResponse } from 'common/api/resources/Plan';
import {
    getHostnameTemplate,
    shouldRegisterFqdnOnServerCreate,
} from 'common/modules/settings/selectors';
import {
    IJSONSchemaForm,
    ISubmitEvent,
} from 'common/components/JSONSchemaForm/JSONSchemaForm';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import ManagerOfDisabledEntities from 'common/helpers/ManagerOfDisabledEntities';
import LocationList from 'common/components/location/LocationList';
import { CardWithSwitchContainer } from 'common/components/CardWithSwitch/Styles';
import * as projectActions from 'common/modules/project/actions';
import { IpBlockType } from 'common/api/resources/IpBlock';
import { IpBlockTypeCard } from 'admin/computeResourceVm/components/IpBlockType/IpBlockTypeCard';

export const defaultSubmitValues: IVmCreateRequest = {
    name: '',
    location: 0,
    description: '',
    plan: 0,
    ssh_keys: [],
    password: '',
    user: 0,
    project: 0,
    backup_settings: defaultBackupSettings,
    ip_types: [IpBlockType.IPv4, IpBlockType.IPv6],
    additional_ip_count: 0,
    additional_ipv6_count: 0,
};

export interface IComputeResourceVmCreateProps {
    history: H.History;
}

export type ComputeResourceVmCreateProps =
    IComputeResourceVmCreateProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceVmCreate: React.FC<ComputeResourceVmCreateProps> = ({
    nameTemplate,
    generateHostname,
    generatePasswd,
    registerFqdn,
    locations,
    computeResources,
    plans,
    osImages,
    applications,
    user,
    projects,
    project,
    projectActions: {
        setProjectItem,
    },
    history,
    formErrors,
    loadData,
    isLoading,
    isLoadingProject,
    isCreating,
    computeResourceActions: {
        getComputeResourceIpBlocks,
    },
    computeResourceVmActions: {
        createServer,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    userProjectsActions: { getProjects },
    permissions: {
        canGetComputeResources,
        canGetSshKeys,
        canGetUsers,
    },
}) => {
    const formRef = React.useRef<IJSONSchemaForm>(null);
    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitValues, setSubmitValues] = React.useState<IVmCreateRequest>({
        ...defaultSubmitValues,
        name: generateHostname(nameTemplate),
        user: user.id,
    });
    const [hasAccess, setHasAccess] = React.useState(true);
    const [internalError, setInternalError] = React.useState(false);
    const [errorMessage, setErrorMessage] = React.useState('');
    const [planSelection, setPlanSelection] = React.useState<number | typeof CUSTOM_PLAN>(0);

    const selectedPlan = React.useMemo(
        () => plans.find(plan => plan.id === submitValues.plan),
        [submitValues.plan, plans]
    );

    const getComputeResourceOptions = (): ISelectOption[] => {
        if (!canGetComputeResources || submitValues.location === 0) {
            return [];
        }

        const selectedLocation = locations.find(location => location.id === submitValues.location);
        return computeResources
            .filter(cr => selectedLocation?.compute_resources.some(location => location.id === cr.id))
            .map((cr: IComputeResourceResponse) => ({
                label: cr.name,
                value: cr.id,
            }));
    };

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        setSubmitValues(values => ({
            ...values,
            name: generateHostname(nameTemplate),
        }));
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [nameTemplate]);

    React.useEffect(() => {
        const defaultLocation = locations.find(item => item.is_default);
        if (defaultLocation) {
            setSubmitValues(values => ({
                ...values,
                location: defaultLocation.id,
            }));
        }
    }, [locations]);

    React.useEffect(() => {
        const defaultPlan = plans.find(item => item.is_default);
        if (defaultPlan) {
            setPlanValues(defaultPlan);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [plans]);

    React.useEffect(() => {
        const defaultOsImage = osImages.data.find(item => item.is_default);
        if (defaultOsImage && defaultOsImage.versions.length) {
            setSubmitValues(values => ({
                ...values,
                os: defaultOsImage.versions[0].id,
            }));
        }
    }, [osImages]);

    React.useEffect(() => {
        const defaultProject = projects.find((item: IProjectResponse) => item.is_default);
        if (defaultProject) {
            setSubmitValues(values => ({
                ...values,
                project: defaultProject.id,
            }));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [projects]);

    const computeResourceOptions = getComputeResourceOptions();

    const translate = useTranslate();
    const getDescription = (plan: IPlanResponse | ComputeResourceVmCustomPlan): string => {
        if (plan) {
            return translate('planInfo.cpu', { cpu: plan.params.vcpu }) + ', '
                + translate('planInfo.memory', {
                    ram: convertToDataUnit(plan.params.ram, DataUnit.MiB),
                }) + ', '
                + translate('planInfo.disk', { disk: plan.params.disk });
        }

        return '';
    };

    const locationIdsWithoutCRs = React.useMemo(
        () => locations.filter(location => location.compute_resources.length === 0).map(location => location.id),
        [locations]
    );

    const locationTooltips = React.useMemo(
        () => locationIdsWithoutCRs
            .reduce(
                (result, currentValue) => {
                    result[currentValue] = <Translate content="computeResourceVm.locationList.noComputeResources" />;
                    return result;
                },
                {}
            ),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [locations]
    );

    const manager = new ManagerOfDisabledEntities(
        plans,
        osImages.data,
        applications.data,
        submitValues.plan,
        submitValues.os,
        submitValues.application,
        locations,
        submitValues.location
    );
    const disabledPlanIds = manager.getDisabledPlanIds();
    const disabledOsImageVersionIds = manager.getDisabledOsImageVersionIds(planSelection === CUSTOM_PLAN
        ? submitValues.custom_plan
        : undefined
    );
    const disabledApplicationIds = manager.getDisabledApplicationIds(planSelection === CUSTOM_PLAN
        ? submitValues.custom_plan
        : undefined
    );
    const disabledLocationIds = [...manager.getDisabledLocationIds(), ...locationIdsWithoutCRs];

    const handleSubmit = async () => {
        if (isSubmitting) {
            return;
        }

        setIsSubmitting(true);

        clearFormErrors();
        setErrorMessage('');

        try {

            if (formRef.current && !formRef.current.isValid()) {
                return;
            }

            const rules = {
                name: requiredRule(<Translate content="validate.fieldRequired" />),
                password: requiredRule(<Translate content="validate.fieldRequired" />),
                location: intMinRule(<Translate content="validate.fieldRequired" />, 1),
                user: intMinRule(<Translate content="validate.fieldRequired" />, 1),
                project: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            };

            if (registerFqdn) {
                rules.name = domainRule(<Translate content="validate.badDomain" />);
            }

            if (submitValues.primary_ip) {
                rules['primary_ip'] = ipRule(<Translate content="validate.badIpAddress" />);
                rules['compute_resource'] = requiredRule(<Translate content="validate.fieldRequired" />);
            }

            const errors = validate<IVmCreateRequest>(submitValues, rules);

            if (Object.keys(errors).length) {
                setFormErrors(errors);
                return;
            }

            if ((submitValues.plan && submitValues.plan > 0 && disabledPlanIds.includes(submitValues.plan))
                || disabledLocationIds.includes(submitValues.location)
                || (
                    submitValues.os
                    && disabledOsImageVersionIds.includes(submitValues.os as number)
                )
                || (
                    submitValues.application
                    && disabledApplicationIds.includes(submitValues.application as number)
                )
            ) {
                setErrorMessage(translate('validate.wrongConfiguration').toString());
                return;
            }

            const values = { ...submitValues };

            if (!values.application) {
                delete values.application_data;
            }

            if (registerFqdn) {
                values.fqdns = [values.name];
            }

            if (!values.backup_settings.enabled) {
                delete values.backup_settings.limit;
            }

            if (planSelection === CUSTOM_PLAN) {
                delete values.plan;
            } else {
                delete values.custom_plan;
            }

            await createServer(values);

            history.push(`${ADMIN_ROUTE_PREFIX}/servers`);
        } catch (e) {
            if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
                if (e.response.data.errors.user_data) {
                    setErrorMessage(e.response.data.errors.user_data.join(''));
                }

                if (e.response.data.errors.os) {
                    setErrorMessage(e.response.data.errors.os);
                }

                return;
            }

            if (e.response.status >= HTTP_CODES.BAD_REQUEST) {
                setErrorMessage(e.response.data.message);
            }
        } finally {
            setIsSubmitting(false);
        }
    };

    const handleVirtualServersClick = () => history.push(`${ADMIN_ROUTE_PREFIX}/servers`);

    const showSshKeys = React.useCallback((id: number | undefined) => {
        for (const osImage of osImages.data) {
            const result = osImage.versions.find(item => item.id === id);
            if (result) {
                return result.is_ssh_keys_supported;
            }
        }

        return true;
    }, [osImages]);

    const handleApplicationDataChange = async ({ formData }: ISubmitEvent) => {
        setSubmitValues(values => ({
            ...values,
            application_data: formData,
        }));
    };

    const handleVersionSelect = (id: number) => {
        setSubmitValues(values => {
            delete values.application;

            return {
                ...values,
                os: values.os !== id ? id : 0,
                ssh_keys: values.os !== id && showSshKeys(id) ? values.ssh_keys : [],
            };
        });
    };

    const handleApplicationSelect = (id: number) => {
        setSubmitValues(values => {
            delete values.os;

            return {
                ...values,
                application: values.application !== id ? id : 0,
            };
        });
    };

    const handlePlanSelect = (plan: IPlanResponse) => {
        setPlanValues(plan.id !== submitValues.plan ? plan : undefined);
    };

    const handleCustomPlanSelect = (customPlan: ComputeResourceVmCustomPlan) => {
        setPlanSelection(CUSTOM_PLAN);
        setSubmitValues(values => ({
            ...values,
            plan: 0,
            custom_plan: customPlan,
            description: getDescription(customPlan),
            backup_settings: customPlan?.is_backup_available ? {
                ...values.backup_settings,
                limit: customPlan.limits.backups_number,
            } : defaultBackupSettings,
            additional_ip_count: customPlan?.is_additional_ips_available ? values.additional_ip_count : 0,
        }));
    };

    const setPlanValues = (plan?: IPlanResponse) => {
        const planId = plan ? plan.id : 0;
        setPlanSelection(planId);
        setSubmitValues(values => ({
            ...values,
            plan: planId,
            description: plan ? getDescription(plan) : submitValues.description,
            backup_settings: plan && plan?.is_backup_available ? {
                ...values.backup_settings,
                limit: plan.limits.backups_number,
            } : defaultBackupSettings,
            additional_ip_count: plan?.is_additional_ips_available ? values.additional_ip_count : 0,
        }));
    };

    const handleGenerateButtonClick = () => {
        setSubmitValues((state) => ({
            ...state,
            name: generateHostname(nameTemplate),
        }));
    };

    const handleLocationSelect = (id: number) => {
        setSubmitValues(values => ({
            ...values,
            location: values.location !== id ? id : 0,
            compute_resource: undefined,
        }));
    };

    const handleIpTypesToggle = (type: IpBlockType) => () => {
        const types: IpBlockType[] = submitValues.ip_types;

        if (types.length === 1 && types[0] === type) {
            return;
        }

        setSubmitValues(values => ({
            ...values,
            ip_types: toggleIpType(types, type),
            ...resetAdditionalIpCount(types, type),
        }));
    };

    const toggleIpType = (types: IpBlockType[], type: IpBlockType): IpBlockType[] =>
        types.includes(type) ? types.filter(i => i !== type) : [...types, type];

    const resetAdditionalIpCount = (types: IpBlockType[], type: IpBlockType) => {
        if (!types.includes(type)) {
            return {};
        }

        switch (type) {
        case IpBlockType.IPv4: return { additional_ip_count: 0 };
        case IpBlockType.IPv6: return { additional_ipv6_count: 0 };
        }
    };

    const isAllowedType = (type: IpBlockType) => submitValues.ip_types.includes(type);

    React.useEffect(() => {
        setSubmitValues(values => ({
            ...values,
            application_data: {
                email: user.email,
                domain: values.name,
                passwd: generatePasswd(),
            },
        }));
    }, [user, setSubmitValues, generatePasswd]);

    const onMount = async () => {
        try {
            await loadData(user.id);
        } catch (e) {
            if (e.response.status === HTTP_CODES.FORBIDDEN) {
                setHasAccess(false);
            }

            if (e.response.status === HTTP_CODES.INTERNAL) {
                setInternalError(true);
            }

            throw e;
        }
    };

    const projectOptions = React.useMemo(() => projects ? projects.map((item: IProjectResponse) => ({
        label: item.name,
        value: item.id,
    })) : [], [projects]);

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleSshKeyChange = (options: any) => {
        setSubmitValues({
            ...submitValues,
            ssh_keys: options !== null ? options.map((option: ISelectOption) => option.value) : [],
        });
    };

    const loadUserOptions = createOptionsLoader(
        users.list,
        (item: IUserResponse) => ({
            label: item.email,
            value: item.id,
        }),
        canGetUsers
    );

    const loadSshKeyOptions = createOptionsLoader(
        sshKeys.list,
        (sshKey) => ({
            label: sshKey.name,
            value: sshKey.id,
        }),
        canGetSshKeys
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserChange = (option: any) => {
        if (option !== null) {
            setSubmitValues({
                ...submitValues,
                user: option.value,
                project: 0,
            });
            getProjects(option.value);
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleProjectChange = (option: any) => {
        setSubmitValues((values) => ({
            ...values,
            project: option.value,
        }));
        setProjectItem(projects.find((item: IProjectResponse) => item.id === option.value)!);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleComputeResourceChange = (option: any) => {
        if (option !== null) {
            setSubmitValues({ ...submitValues, compute_resource: option.value });
            getComputeResourceIpBlocks(option.value);
        }
    };

    const handleSetUserData = (data: string | undefined) => {
        if (data) {
            setSubmitValues({ ...submitValues, user_data: data });
        } else if ('user_data' in submitValues) {
            const { user_data, ...values } = submitValues;
            setSubmitValues(values);
        }
    };

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    const projectOption = React.useMemo(() => {
        const option = projectOptions.find((item) => item.value === submitValues.project);

        if (option === undefined) {
            return null;
        }

        return option;
    }, [projectOptions, submitValues.project]);

    const computeResourceOption = () => {
        const option = computeResourceOptions.find(item => item.value === submitValues.compute_resource);

        if (option === undefined) {
            return null;
        }

        return option;
    };

    const handleJsonFormError = () => {
        formRef.current?.scrollTo();
    };

    const handleSetBackupSettings = (backupSettings: IBackupSettings) => {
        setSubmitValues(values => ({
            ...values,
            backup_settings: backupSettings,
        }));
    };

    const maxAdditionalIpv4 = user.limit_group?.additional_ips.is_enabled
        ? user.limit_group.additional_ips.limit - user.limit_usage.additional_ips
        : undefined;

    const maxAdditionalIpv6 = user.limit_group?.additional_ipv6.is_enabled
        ? user.limit_group.additional_ipv6.limit - user.limit_usage.additional_ipv6
        : undefined;

    const plan = planSelection === CUSTOM_PLAN ? submitValues.custom_plan! : selectedPlan;

    if (!hasAccess) {
        return (<Translate content="projects.server.create.accessDenied" />);
    }

    if (internalError) {
        return (<Translate content="projects.internalError" />);
    }

    return (
        <WithErrorPages onMount={onMount} deps={[]}>
            <Loader isLoading={isLoading}>
                <PageHeader
                    title={(
                        <PageSection>
                            <Action onClick={handleVirtualServersClick}>
                                <Translate content="computeResourceVm.header" />
                            </Action>
                            <Icon name="chevron-right" />
                            <Translate content="computeResourceVm.create" />
                        </PageSection>
                    )}
                />
                <Form
                    id="serverCreateForm"
                    errors={formErrors}
                    values={submitValues}
                    hideRequiredLegend={true}
                    applyButton={false}
                    cancelButton={false}
                    submitButton={false}
                    vertical={true}
                    onFieldChange={handleFieldChange}
                >
                    <PageSubHeader title="computeResource.servers.form.name" />
                    <InputWithRefreshButton>
                        <InputText
                            name="name"
                            size="lg"
                            label={null}
                            required={true}
                        />
                        <Button
                            ghost={true}
                            icon={ICONS.REFRESH}
                            onClick={handleGenerateButtonClick}
                        />
                    </InputWithRefreshButton>
                    <FormField
                        name="location"
                        required={true}
                        label={null}
                    >
                        <LocationList
                            selectedId={submitValues.location}
                            onItemClicked={handleLocationSelect}
                            disabledLocationIds={disabledLocationIds}
                            tooltips={locationTooltips}
                        />
                    </FormField>
                    {canGetComputeResources && (
                        <>
                            <PageSubHeader title="computeResource.servers.form.computeResource" />
                            <FormField
                                name="compute_resource"
                                required={true}
                                label={null}
                                data-cy={TEST.VM_CREATE.COMPUTE_RESOURCE_SELECT}
                            >
                                <SelectInput
                                    options={computeResourceOptions}
                                    onChange={handleComputeResourceChange}
                                    minHeight="40px"
                                    width="350px"
                                    isDisabled={submitValues.location === 0}
                                    value={computeResourceOption()}
                                />
                            </FormField>
                        </>
                    )}
                </Form>
                <ServerTypeTabs
                    onChange={handleApplicationDataChange}
                    onApplicationSelected={handleApplicationSelect}
                    onVersionSelected={handleVersionSelect}
                    selectedOsImageVersionId={submitValues.os || 0}
                    selectedApplicationId={submitValues.application || 0}
                    onError={handleJsonFormError}
                    formData={submitValues.application_data}
                    formRef={formRef}
                    disabledOsImageVersionIds={disabledOsImageVersionIds}
                    disabledApplicationIds={disabledApplicationIds}
                />
                <Form
                    id="serverCreateCredentialsForm"
                    errors={formErrors}
                    values={submitValues}
                    hideRequiredLegend={true}
                    applyButton={false}
                    cancelButton={false}
                    submitButton={false}
                    vertical={true}
                    onFieldChange={handleFieldChange}
                >
                    <FormField
                        name="plan"
                        required={true}
                        label={null}
                    >
                        <PlanList
                            selectedPlan={planSelection}
                            onItemClicked={handlePlanSelect}
                            disabledPlanIds={disabledPlanIds}
                            canCustomizePlan={true}
                            customPlan={submitValues.custom_plan}
                            onCustomizePlan={handleCustomPlanSelect}
                            project={project}
                        />
                    </FormField>
                    {(plan?.is_backup_available) && (
                        <CardWithSwitchContainer>
                            <BackupCard
                                backupSettings={submitValues.backup_settings}
                                setBackupSettings={handleSetBackupSettings}
                                isBackupSettingsUpdating={false}
                                nextScheduledBackupAt={null}
                                backupPrice={selectedPlan ? selectedPlan?.backup_price : 0}
                                backupsNumber={submitValues.backup_settings.limit}
                            />
                        </CardWithSwitchContainer>
                    )}
                    <div
                        style={{ marginTop: '24px' }}
                    >
                        <PageSubHeader title="servers.create.ipBlockTypes" />
                        <Grid
                            gap={SIZE.LG}
                            xs={1}
                            sm={2}
                            md={3}
                            lg={4}
                            xl={5}
                        >
                            <GridCol>
                                <IpBlockTypeCard
                                    isSelected={isAllowedType(IpBlockType.IPv4)}
                                    onSelect={handleIpTypesToggle(IpBlockType.IPv4)}
                                >
                                    { IpBlockType.IPv4 }
                                </IpBlockTypeCard>
                            </GridCol>
                            <GridCol>
                                <IpBlockTypeCard
                                    isSelected={isAllowedType(IpBlockType.IPv6)}
                                    onSelect={handleIpTypesToggle(IpBlockType.IPv6)}
                                >
                                    { IpBlockType.IPv6 }
                                </IpBlockTypeCard>
                            </GridCol>
                        </Grid>
                    </div>
                    <InputPrimaryIp
                        name="primary_ip"
                        size={SIZE.LG}
                        label={<Translate content="computeResource.servers.form.primaryIp" />}
                    />
                    {(plan?.is_additional_ips_available) && (
                        <InlineFields>
                            <InputNumber
                                label={<Translate content="computeResource.servers.form.additionalIpCount" />}
                                name="additional_ip_count"
                                disabled={!isAllowedType(IpBlockType.IPv4) || maxAdditionalIpv4 === 0}
                                size={SIZE.LG}
                                min={0}
                                max={maxAdditionalIpv4}
                            />
                            <InputNumber
                                label={<Translate content="computeResource.servers.form.additionalIpv6Count" />}
                                name="additional_ipv6_count"
                                disabled={!isAllowedType(IpBlockType.IPv6) || maxAdditionalIpv6 === 0}
                                size={SIZE.LG}
                                min={0}
                                max={maxAdditionalIpv6}
                            />
                        </InlineFields>
                    )}
                    <InputPassword
                        size="lg"
                        label={<Translate content="computeResource.servers.form.password" />}
                        name="password"
                        autoComplete="new-password"
                        hideGenerateButton={true}
                        hidePasswordMeter={true}
                        required={false}
                        data-cy={TEST.VM_CREATE.PASSWORD}
                    />
                    {showSshKeys(submitValues.os) && (
                        <>
                            <PageSubHeader title="computeResource.servers.form.sshKeys" />
                            <FormField
                                name="ssh_keys"
                                label={null}
                                data-cy={TEST.VM_CREATE.SSH_SELECT}
                            >
                                <AsyncSelectInput
                                    isMulti={true}
                                    minHeight="40px"
                                    width="350px"
                                    loadOptions={loadSshKeyOptions}
                                    onChange={handleSshKeyChange}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                />
                            </FormField>
                        </>
                    )}
                    <InlineFields>
                        <div>
                            <PageSubHeader title="computeResource.servers.form.user" />
                            <FormField
                                name="user"
                                required={true}
                                label={null}
                                data-cy={TEST.VM_CREATE.USER}
                            >
                                <AsyncSelectInput
                                    loadOptions={loadUserOptions}
                                    onChange={handleUserChange}
                                    debounceTimeout={1000}
                                    hasErrors={'user' in formErrors}
                                    additional={{ page: 1 }}
                                    minHeight="40px"
                                    width="350px"
                                    defaultValue={{
                                        value: user.id.toString(),
                                        label: user.email,
                                    }}
                                />
                            </FormField>
                        </div>
                        <div>
                            <PageSubHeader title="computeResource.servers.form.project" />
                            <FormField
                                name="project"
                                required={true}
                                label={null}
                                data-cy={TEST.VM_CREATE.PROJECT}
                            >
                                <SelectInput
                                    options={projectOptions}
                                    onChange={handleProjectChange}
                                    hasErrors={'project' in formErrors}
                                    minHeight="40px"
                                    width="350px"
                                    isDisabled={projectOptions.length === 0}
                                    value={projectOption}
                                    isLoading={isLoadingProject}
                                />
                            </FormField>
                        </div>
                    </InlineFields>
                </Form>
                <ServerUserData
                    isSelected={submitValues.user_data !== undefined}
                    isLinux={!!showSshKeys(submitValues.os)}
                    onSetUserData={handleSetUserData}
                    planId={submitValues.plan}
                    osImageVersionId={submitValues.os}
                    disabledPlanIds={disabledPlanIds}
                    disabledOsImageVersionIds={disabledOsImageVersionIds}
                />
                <Footer>
                    <SubmitButton
                        type="submit"
                        form="serverCreateForm"
                        size="lg"
                        state={isCreating ? 'loading' : null}
                        intent={INTENT_TYPE.PRIMARY}
                        onClick={handleSubmit}
                        data-cy={TEST.VM_CREATE.CREATE_BUTTON}
                    >
                        <Translate content="computeResource.servers.form.createBtn" />
                    </SubmitButton>
                    {errorMessage && <ErrorMessage>{errorMessage}</ErrorMessage>}
                </Footer>
            </Loader>
        </WithErrorPages >
    );
};

const mapStateToProps = (state: RootState) => ({
    nameTemplate: getHostnameTemplate(state),
    generateHostname: generateName,
    generatePasswd: generatePassword,
    registerFqdn: shouldRegisterFqdnOnServerCreate(state),
    locations: state.location.list.data,
    computeResources: state.computeResource.list.data,
    plans: state.plan.list.data,
    osImages: state.osImage.list,
    applications: state.application.list,
    user: state.auth.user,
    projects: state.project.list.data,
    project: state.project.item,
    formErrors: getProcessedErrors(state),
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_CREATE),
    isLoadingProject: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_LIST),
    isCreating: state.app.loadingFlags.has(LOADING_FLAGS.CREATE_COMPUTE_RESOURCE_VM),
    permissions: {
        canGetComputeResources: hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES) || hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCES),
        canGetSshKeys: hasPermission(state, PERMISSION_LIST.MANAGE_SSH_KEYS) || hasPermission(state, PERMISSION_LIST.GET_SSH_KEYS),
        canGetUsers: hasPermission(state, PERMISSION_LIST.MANAGE_USERS) || hasPermission(state, PERMISSION_LIST.GET_USERS),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadData: bindActionCreators(loadComputeResourceVmCreatePageData, dispatch),
    projectActions: bindActionCreators(projectActions, dispatch),
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
    userProjectsActions: bindActionCreators(userProjectsActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceVmCreate);

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const TEST = {
    TAB: {
        WEBHOOKS: 'event-handler-tab-webhooks',
    },

    WEBHOOKS: {
        CREATE_BUTTON: 'webhooks-create',
    },
};
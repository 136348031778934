// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { connect } from 'react-redux';
import { SnapshotsList } from 'common/components/ServerTabs/SnapshotTab/SnapshotsList';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as snapshotActions from 'common/modules/snapshot/actions';
import { VIEW_TYPE } from 'common/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

const mapStateToProps = (state: RootState) => ({
    view: VIEW_TYPE.LIST,
    snapshots: state.snapshot.list.data,
    server: state.computeResourceVm.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.SNAPSHOTS_LIST),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
    canRevertServersSnapshot: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.REVERT_SERVERS_SNAPSHOT
    ),
    canDeleteServersSnapshot: hasPermission(
        state,
        PERMISSION_LIST.MANAGE_SERVERS,
        PERMISSION_LIST.DELETE_SERVERS_SNAPSHOT
    ),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    snapshotActions: bindActionCreators(snapshotActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SnapshotsList);

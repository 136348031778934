// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'admin/ipBlock/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    removeBatch,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import {
    IIpBlockCreateRequest,
    IIpBlockResponse,
    ipBlocks,
} from 'common/api/resources/IpBlock';
import {
    IReserveIpRequest,
    IIpResponse,
    ips,
    IPatchIpRequest,
    IAddIpRequest,
    IAddIpsRequest,
} from 'common/api/resources/Ip';
import { HTTP_CODES } from 'common/api/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';

export const setIpBlockList = createCustomAction(
    types.SET_IPBLOCK_LIST,
    (data: IPaginateApiResponse<IIpBlockResponse[]>) => ({ payload: data })
);
export const setIpBlockItem = createCustomAction(
    types.SET_IPBLOCK_ITEM,
    (data: IIpBlockResponse) => ({ payload: data })
);
export const unsetIpBlockItem = createCustomAction(types.UNSET_IPBLOCK_ITEM);
export const addIpBlockItem = createCustomAction(
    types.ADD_IPBLOCK_ITEM,
    (data: IIpBlockResponse) => ({ payload: data })
);
export const updateIpBlockItem = createCustomAction(
    types.UPDATE_IPBLOCK_ITEM,
    (data: IIpBlockResponse) => ({ payload: data })
);
export const removeIpBlockItem = createCustomAction(
    types.REMOVE_IPBLOCK_ITEM,
    (id: number) => ({ payload: id })
);
export const removeIpItem = createCustomAction(
    types.REMOVE_IP_ITEM,
    (id: number) => ({ payload: id })
);
export const removeIpItems = createCustomAction(
    types.REMOVE_IP_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const removeIpBlockItems = createCustomAction(
    types.REMOVE_IPBLOCK_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const updateIpItem = createCustomAction(
    types.UPDATE_IP_ITEM,
    (data: IIpResponse) => ({ payload: data })
);
export const setIpBlockIps = createCustomAction(
    types.SET_IP_BLOCK_IP_LIST,
    (data: IPaginateApiResponse<IIpResponse[]>) => ({ payload: data })
);
export const setIpBlockItemIsDeleting = createCustomAction(
    types.SET_IP_BLOCK_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);
export const setIpItemIsLoading = createCustomAction(
    types.SET_IP_ITEM_IS_LOADING,
    (id: number, isLoading: boolean) => ({ payload: { id, isLoading } })
);
export const appendIpBlocks = createCustomAction(
    types.APPEND_IP_BLOCKS,
    (data: IPaginateApiResponse<IIpBlockResponse[]>) => ({ payload: data })
);
export const appendIps = createCustomAction(
    types.APPEND_IPS,
    (data: IPaginateApiResponse<IIpResponse[]>) => ({ payload: data })
);
export const addIpToBlockItem = createCustomAction(
    types.ADD_IP_TO_BLOCK_ITEM,
    (data: IIpResponse) => ({ payload: data })
);
export const addIpsToBlockItem = createCustomAction(
    types.ADD_IPS_TO_BLOCK_ITEM,
    (data: IIpResponse[]) => ({ payload: data })
);

export const getIpBlocks = () => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.IPBLOCK_LIST,
    action: setIpBlockList,
    apiCall: ipBlocks.list,
});

export const createIpBlock = (data: IIpBlockCreateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_IPBLOCK_ITEM,
    action: addIpBlockItem,
    apiCall: ipBlocks.create,
    translations: {
        success: 'ipBlock.toasts.ipBlockSaved',
    },
});

// The reserveIpInBlock method is only used for an IP block with a Range list type,
// so it is ok to use "create" action wrapper here.
export const reserveIpInBlock = (id: number, data: IReserveIpRequest) => async (dispatch: Dispatch) => {
    const apiCall = async (request: IReserveIpRequest) => ipBlocks.ips.reserve(id, request);
    return await create({
        data,
        dispatch,
        loadingFlag: LOADING_FLAGS.ADD_IP_TO_IPBLOCK,
        action: addIpToBlockItem,
        apiCall,
        translations: {
            success: 'ipBlock.toasts.ipReserved',
        },
    });
};

export const addIpToBlock = (id: number, data: IAddIpRequest) => async (dispatch: Dispatch) => {
    const apiCall = async (request: IAddIpRequest) => ipBlocks.ips.addSingle(id, request);
    return await create({
        data,
        dispatch,
        loadingFlag: LOADING_FLAGS.ADD_IP_TO_IPBLOCK,
        action: addIpToBlockItem,
        apiCall,
        translations: {
            success: 'ipBlock.toasts.ipAdded',
        },
    });
};

export const addIpsToBlock = (id: number, data: IAddIpsRequest) => async (dispatch: Dispatch) => {
    const apiCall = async (request: IAddIpsRequest) => ipBlocks.ips.addMultiple(id, request);
    return await create({
        data,
        dispatch,
        loadingFlag: LOADING_FLAGS.ADD_IP_TO_IPBLOCK,
        action: addIpsToBlockItem,
        apiCall,
        translations: {
            success: 'ipBlock.toasts.ipsAdded',
        },
    });
};

export const getIpBlock = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: ipBlocks.item,
    action: setIpBlockItem,
    loadingFlag: LOADING_FLAGS.IPBLOCK_ITEM,
});

export const updateIpBlock = (id: number, data: IIpBlockCreateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: ipBlocks.update,
    action: updateIpBlockItem,
    loadingFlag: LOADING_FLAGS.SAVE_IPBLOCK_ITEM,
    translations: {
        success: 'ipBlock.toasts.ipBlockSaved',
    },
});

export const removeIp = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: ips.remove,
    setLoadingAction: setIpItemIsLoading,
    action: removeIpItem,
    loadingFlag: LOADING_FLAGS.REMOVE_IP_ITEM,
    translations: {
        success: 'ipBlock.toasts.ipDeleted',
    },
});

export const patchIp = (id: number, data: IPatchIpRequest) => async (dispatch: Dispatch) => {
    dispatch(setIpItemIsLoading(id, true));

    try {
        const result = await ips.patch(id, data);

        if (result.status === HTTP_CODES.OK) {
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'ip.toasts.ipSaved')(dispatch);
            dispatch(updateIpItem(result.data.data));
        }

        return result;
    } finally {
        dispatch(setIpItemIsLoading(id, false));
    }
};

export const removeIpBlock = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: ipBlocks.remove,
    setLoadingAction: setIpBlockItemIsDeleting,
    action: removeIpBlockItem,
    loadingFlag: LOADING_FLAGS.REMOVE_IPBLOCK_ITEM,
    translations: {
        success: 'ipBlock.toasts.ipBlockDeleted',
    },
});

export const removeIpBlocks = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: ipBlocks.removeBatch,
    action: removeIpBlockItems,
    loadingFlag: LOADING_FLAGS.REMOVE_IPBLOCK_ITEM,
    translations: {
        success: 'ipBlock.toasts.ipBlockBatchDeleted',
    },
});

export const getIpBlockIps = (id: number, params?: IPaginatedWithSearch) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.IP_LIST));

    try {
        const result = await ipBlocks.ips.list(id, params);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setIpBlockIps(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.IP_LIST));
    }
};

export const loadIpBlocksOnScroll = () => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.ipBlock.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.IPBLOCK_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendIpBlocks,
        loadingFlag: LOADING_FLAGS.IPBLOCK_LIST,
    });
};

export const removeIps = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: ips.removeBatch,
    action: removeIpItems,
    loadingFlag: LOADING_FLAGS.REMOVE_IPBLOCK_ITEM,
    translations: {
        success: 'ipBlock.toasts.ipBlockBatchDeleted',
    },
});

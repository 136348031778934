// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    getStatus,
    IComputeResourceResponse,
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import {
    Action,
    Icon,
    List,
    Switch,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    COMPUTE_RESOURCE,
    COMPUTE_RESOURCES,
} from 'admin/computeResource/constants/tests';
import CopyText from 'common/containers/CopyText/CopyText';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import { Status } from 'admin/computeResource/common/Status/Status';
import { getActionColumnProps } from 'common/helpers/list';
import { ICONS } from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceActions from 'admin/computeResource/actions';
import { connect } from 'react-redux';
import PopoverList from 'common/components/PopoverList/PopoverList';
import { ILocationResponse } from 'common/api/resources/Location';
import LocationCell from 'common/components/IconCell/LocationCell';

const columns = [{
    width: '1%',
    key: 'colId',
    title: <Translate content="computeResource.list.id" />,
}, {
    key: 'colName',
    cellProps: {
        className: 'cell-bold',
    },
    title: <Translate content="computeResource.list.name" />,
}, {
    width: '10%',
    key: 'colStatus',
    title: <Translate content="computeResource.list.status" />,
}, {
    width: '20%',
    key: 'colHost',
    title: <Translate content="computeResource.list.host" />,
}, {
    width: '5%',
    key: 'colVms',
    title: <Translate content="computeResource.list.vmCount" />,
}, {
    width: '5%',
    key: 'colVersion',
    title: <Translate content="computeResource.list.version" />,
}, {
    width: '25%',
    key: 'colLocations',
    title: <Translate content="computeResource.list.locations" />,
}, {
    width: '35%',
    key: 'colVirtualizationTypes',
    title: <Translate content="computeResource.list.virtualizationTypes" />,
}, {
    width: '1%',
    key: 'colLocked',
    title: <Translate content="computeResource.list.isLocked" />,
}, getActionColumnProps(),
];

export interface IComputeResourceTableProps extends RouteComponentProps {
    items: IComputeResourceResponse[];
    onCreate: () => void;
    onEdit: (id: number) => void;
    onRemove: (id: number) => void;
    onSettingsClick: (id: number) => void;
    onDetailsClick: (id: number) => void;
}

export type ComputeResourceTableProps =
    IComputeResourceTableProps &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceTable: React.FC<ComputeResourceTableProps> = ({
    items,
    onCreate,
    onEdit,
    onRemove,
    onSettingsClick,
    onDetailsClick,
    history,
    computeResourceActions: {
        updateIsLocked,
    },
}) => {
    const [lockingChanging, setLockingChanging] = React.useState<number[]>([]);

    const handleDetailsClick = (id: number) => () => onDetailsClick(id);
    const handleRemove = (id: number) => () => onRemove(id);
    const handleEdit = (id: number) => () => onEdit(id);
    const handleSettingsClick = (id: number) => () => onSettingsClick(id);
    const handleClick = (id: number) => () => history.push(`${ADMIN_ROUTE_PREFIX}/compute_resources/${id}`);
    const handleToggleIsLocked = (cr: IComputeResourceResponse) => async() => {
        if (lockingChanging.includes(cr.id)) {
            return;
        }

        try {
            lockingChanging.push(cr.id);
            setLockingChanging(lockingChanging);
            await updateIsLocked(cr.id, !cr.is_locked);
        } finally {
            setLockingChanging(lockingChanging.filter(id => id !== cr.id));
        }
    };

    const buttonToComputeResource = (id: number, title: string) => (
        <Action onClick={handleClick(id)}>
            {title}
        </Action>
    );

    const renderVirtualizationTypes = (virtualizationTypes: VirtualizationType[]) =>
        virtualizationTypes
            .map(type => VIRTUALIZATION_TYPE_TRANSLATION_MAP[type])
            .join(', ');

    const renderLocation = (location: ILocationResponse) => (<LocationCell location={location}/>);

    const data = items.map((item) => ({
        colId: buttonToComputeResource(item.id, item.id.toString()),
        colName: buttonToComputeResource(item.id, item.name),
        colStatus: <Status
            status={getStatus(item.status)}
            onDetailsClick={handleDetailsClick(item.id)}
            compact={true}
        />,
        colHost: <CopyText>{item.host}</CopyText>,
        colVirtualizationTypes: renderVirtualizationTypes(item.settings.virtualization_types),
        colVms: item.vms_count ? buttonToComputeResource(item.id, item.vms_count.toString()) : '',
        colVersion: item.version,
        colLocations: (
            <PopoverList
                items={item.locations}
                render={renderLocation}
                keyExtractor={l => l.id.toString()}
            />
        ),
        colLocked: <Switch
            intent='danger'
            checked={item.is_locked}
            onChange={handleToggleIsLocked(item)}
            loading={lockingChanging.includes(item.id)}
            data-cy={dataCySelector(item.id, COMPUTE_RESOURCE.IS_LOCKED)}
        />,
        colActions: (
            <StyledActions>
                <Action
                    icon={<Icon name={ICONS.GEAR} />}
                    className="action-icon"
                    onClick={handleSettingsClick(item.id)}
                    data-cy={dataCySelector(item.id, COMPUTE_RESOURCE.SETTINGS)}
                />
                <Tooltip title={<Translate content="computeResource.actionDialog.editBtn"/>}>
                    <Action
                        icon={<Icon name={ICONS.PENCIL} />}
                        className="action-icon"
                        onClick={handleEdit(item.id)}
                        data-cy={dataCySelector(item.id, COMPUTE_RESOURCE.EDIT)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    disabled={item.vms_count > 0}
                    isLoading={item.is_deleting}
                    translations={{
                        button: (
                            <Translate content="computeResource.buttonWithConfirmation.button" />
                        ),
                        title: (
                            <Translate content="computeResource.buttonWithConfirmation.title" />
                        ),
                        tooltip: (
                            <Translate content="computeResource.buttonWithConfirmation.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRemove(item.id)}
                    data-cy={dataCySelector(item.id, COMPUTE_RESOURCE.DELETE)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: item.id.toString(),
    }));

    return (
        <List
            emptyView={
                <EmptyView
                    title="computeResource.emptyView.title"
                    description="computeResource.emptyView.description"
                    buttonText="computeResource.emptyView.buttonText"
                    onButtonClick={onCreate}
                    icon="resource"
                />
            }
            data-cy={COMPUTE_RESOURCES.TABLE}
            columns={columns}
            data={data}
        />
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default withRouter(connect(null, mapDispatchToProps)(ComputeResourceTable));

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components';
import { CardList } from 'client/common/components/Card';
import SnapshotCard from 'common/components/ServerTabs/SnapshotTab/SnapshotCard';
import { ISnapshotResponse } from 'common/api/resources/Snapshot';
import {
    ICONS,
    INTENT_TYPE,
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
    VIEW_TYPE,
} from 'common/constants';
import { StyledList } from 'client/common/styles/List';
import {
    List,
    Status,
    Translate,
} from '@plesk/ui-library';
import {
    SNAPSHOT_STATUS_AVAILABLE,
    SNAPSHOT_STATUS_FAILED,
    SNAPSHOT_STATUS_PROCESSING,
} from 'common/modules/project/constants';
import moment from 'moment';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import { bindedActionCreators } from 'common/redux/types';
import * as snapshotsServerActions from 'common/modules/snapshot/actions';
import { initEchoConnection } from 'common/services/EchoService';
import { IUpdateSnapshot } from 'common/api/resources/ComputeResource';
import { getActionColumnProps } from 'common/helpers/list';
import { dataCySelector } from 'common/tests/selectors';
import { SNAPSHOT_ACTIONS } from 'common/components/ServerTabs/SnapshotTab/constants/test';

export interface ISnapshotsListProps {
    echoCredentials: string;
    view: VIEW_TYPE;
    snapshotActions: bindedActionCreators<typeof snapshotsServerActions>;
    server: IVmResponse;
    isLoading: boolean;
    snapshots: ISnapshotResponse[];
    serverId: number;
    canRevertServersSnapshot: boolean;
    canDeleteServersSnapshot: boolean;
}

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="snapshots.list.colName" />,
},
{
    width: '15%',
    key: 'colStatus',
    title: <Translate content="snapshots.list.colStatus" />,
    className: 'header-status',
    cellProps: {
        className: 'cell-status',
    },
}, {
    width: '15%',
    key: 'colSize',
    title: <Translate content="snapshots.list.colSize" />,
}, {
    width: '15%',
    key: 'colCreated',
    title: <Translate content="snapshots.list.colCreated" />,
}, getActionColumnProps(),
];

export const SnapshotsList: React.FC<ISnapshotsListProps> = ({
    echoCredentials,
    view,
    snapshots,
    snapshotActions: {
        getSnapshots,
        revertSnapshot,
        removeSnapshot,
        updateSnapshotItemById,
    },
    isLoading,
    server,
    serverId,
    canRevertServersSnapshot,
    canDeleteServersSnapshot,
}) => {
    React.useLayoutEffect(() => {
        getSnapshots(serverId);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        const echo = initEchoConnection(echoCredentials);

        if (server.uuid) {
            const channel = echo.private(`${SOCKET_CHANNELS.COMPUTE_RESOURCE_VM}.${server.uuid}`);

            channel.listen(SOCKET_EVENTS.SNAPSHOT_UPDATED, (updateData: IUpdateSnapshot) => updateSnapshotItemById(updateData.snapshot));
        }

        return () => echo.disconnect();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.uuid]);

    const handleRevert = (snapshot: ISnapshotResponse) => () => revertSnapshot(snapshot);
    const handleRemove = (snapshot: ISnapshotResponse) => () => removeSnapshot(snapshot);

    const data = snapshots.map((item) => {
        const actionsEl = (
            <>
                {item.status !== SNAPSHOT_STATUS_PROCESSING && (
                    <>
                        {
                            canRevertServersSnapshot
                            && item.status === SNAPSHOT_STATUS_AVAILABLE
                            && !server.is_suspended
                            && (
                                <ButtonWithConfirmation
                                    icon={ICONS.TRANSFER}
                                    translations={{
                                        text: (
                                            <Translate content="snapshots.confirmationRevert.text" />
                                        ),
                                        button: (
                                            <Translate content="snapshots.confirmationRevert.button" />
                                        ),
                                        tooltip: (
                                            <Translate content="snapshots.confirmationRevert.button" />
                                        ),
                                    }}
                                    handleConfirm={handleRevert(item)}
                                    data-cy={dataCySelector(item.id, SNAPSHOT_ACTIONS.REVERT)}
                                />
                            )
                        }
                        {canDeleteServersSnapshot && (
                            <ButtonWithConfirmation
                                icon={ICONS.RECYCLE}
                                isLoading={item.isLoading}
                                translations={{
                                    text: (
                                        <Translate content="snapshots.confirmationRemove.text" />
                                    ),
                                    button: (
                                        <Translate content="snapshots.confirmationRemove.button" />
                                    ),
                                    tooltip: (
                                        <Translate content="snapshots.confirmationRemove.button" />
                                    ),
                                }}
                                handleConfirm={handleRemove(item)}
                                data-cy={dataCySelector(item.id, SNAPSHOT_ACTIONS.DELETE)}
                            />
                        )}
                    </>
                )}
            </>
        );

        return {
            colName: <div style={{ marginLeft: '12px', fontWeight: 600 }}>{item.name}</div>,
            colStatus: (
                <Status
                    intent={item.status !== SNAPSHOT_STATUS_FAILED ? INTENT_TYPE.SUCCESS : INTENT_TYPE.DANGER}
                    progress={item.status === SNAPSHOT_STATUS_PROCESSING}
                    compact={item.status !== SNAPSHOT_STATUS_PROCESSING}
                >
                    {item.status !== SNAPSHOT_STATUS_PROCESSING && (
                        <Translate content={'snapshots.status.' + item.status} />
                    )}
                </Status>
            ),
            colSize: <>{item.size} GiB</>,
            colCreated: moment(item.created_at).format('DD/MM/YYYY HH:mm'),
            colActions: actionsEl,
            key: item.id.toString(),
        };
    });

    return (
        <Loader isLoading={isLoading}>
            {view === VIEW_TYPE.CARD && (
                <CardList
                    items={snapshots}
                    component={snapshot => (<SnapshotCard key={snapshot.id} snapshot={snapshot} />)}
                />
            )}
            {view === VIEW_TYPE.LIST && (
                <StyledList>
                    <List
                        emptyView={null}
                        columns={columns}
                        data={data}
                    />
                </StyledList>
            )}
            {!data.length && (
                <EmptyView
                    title="snapshots.emptyView.title"
                    description="snapshots.emptyView.description"
                    icon="camera"
                />
            )}
        </Loader>
    );
};

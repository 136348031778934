// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

export const TABLE_ACTIONS = {
    EDIT: 'edit',
    REMOVE: 'remove',
    CLONE: 'clone',
    VISIBILITY: 'visibility',
    DEFAULT: 'default',
    BATCH_DELETE_BTN: 'batch-delete',
};

export const FORM_ACTIONS = {
    CONTENT: 'content',
};
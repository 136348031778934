// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Icon,
    Label,
    Translate,
} from '@plesk/ui-library';
import {
    ComputeResourceVmNameContainer,
    MigrationFormTitleLink,
    MigrationFromTitle,
} from 'admin/computeResourceVm/containers/ComputeResourceVmTable/Styles';
import {
    getViewableIps,
    IComputeResourceVmListFilters,
} from 'common/api/resources/ComputeResourceVm';
import CopyText from 'common/containers/CopyText/CopyText';
import { VmStatus } from 'common/components/VmStatus';
import ComputeResourceVmRow from 'admin/computeResourceVm/containers/ComputeResourceVmRow/ComputeResourceVmRow';
import Usage from 'admin/common/components/Usage/Usage';
import { SERVER } from 'admin/computeResource/constants/tests';
import { COLORS } from 'admin/core/theme';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import ComputeResourceVmActions from 'admin/computeResourceVm/containers/ComputeResourceVmActions/ComputeResourceVmActions';
import { Dialog } from 'common/components/Dialog/Dialog';
import MigrationForm from 'admin/computeResourceVm/containers/MigrationForm/MigrationForm';
import { formatTableDate } from 'common/date';
import { IListRow } from 'common/list';
import { VMS_TABS } from 'admin/computeResourceVm/constants';
import { areNetworkLimitsExceeded } from 'common/api/resources/Plan';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';
import ComputeResourceVmOperations from 'admin/computeResourceVm/containers/ComputeResourceVmTable/ComputeResourceVmOperations';
import List from 'common/components/List/List';
import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import ServerEditDialog from 'admin/common/containers/ServerEditDialog/ServerEditDialog';

export interface IComputeResourceVmFilters {
    status?: ValueType<ISelectRequiredOption>;
    computeResource?: ValueType<ISelectRequiredOption>;
    user?: ValueType<ISelectRequiredOption>;
    virtualizationType?: ValueType<ISelectRequiredOption>;
}

interface IComputeResourceVmTableProps extends RouteComponentProps {
    excludedColumns?: string[];
    withFilters?: boolean;
    computeResourceId?: number;
    emptyView: React.ReactNode;
}

export type ComputeResourceVmTableProps =
    IComputeResourceVmTableProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    title: <Translate content="computeResource.servers.list.id" />,
    width: '1%',
}, {
    key: 'colName',
    title: <Translate content="computeResource.servers.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    width: '15%',
    key: 'colStatus',
    title: <Translate content="computeResource.servers.list.status" />,
}, {
    key: 'colComputeResource',
    title: <Translate content="computeResource.servers.list.computeResource" />,
}, {
    key: 'colIp',
    title: <Translate content="computeResource.servers.list.ip" />,
}, {
    key: 'colCpu',
    title: <Translate content="computeResource.servers.list.cpu" />,
}, {
    key: 'colOwner',
    title: <Translate content="computeResource.servers.list.owner" />,
}, {
    key: 'colCreated',
    title: <Translate content="computeResource.servers.list.created" />,
}, getActionColumnProps({ width: '5%' }),
];

export const ComputeResourceVmTable: React.FC<ComputeResourceVmTableProps> = ({
    computeResourceVms,
    computeResourceVmActions: {
        getComputeResourceVms,
        deleteComputeResourceVm,
        deleteComputeResourceVms,
    },
    excludedColumns,
    isLoading,
    history,
    withFilters,
    computeResourceId,
    emptyView,
}) => {
    const [selectedVm, setSelectedVm] = React.useState<number>(0);
    const [selection, setSelection] = React.useState<string[]>([]);
    const [expandedRows, setExpandedRows] = React.useState<string[]>([]);
    const [filters, setFilters] = React.useState<IComputeResourceVmFilters>({});
    const [isServerEditDialogOpened, setServerEditDialogOpened] = React.useState(false);
    const [isMigrationDialogOpened, setMigrationDialogOpened] = React.useState(false);

    const getFilters = (): IComputeResourceVmListFilters => ({
        user_id: filters.user ? parseFloat((filters.user as ISelectRequiredOption).value) : 0,
        status: filters.status ? (filters.status as ISelectRequiredOption).value : '',
        compute_resource_id: filters.computeResource
            ? parseFloat((filters.computeResource as ISelectRequiredOption).value)
            : computeResourceId,
        virtualization_type: filters.virtualizationType
            ? (filters.virtualizationType as ISelectRequiredOption).value
            : '',
    });

    const isFirstLoading = useRequestCancellationEffect(
        token => getComputeResourceVms({ filters: getFilters() }, token),
        [filters]
    );

    const openMigrationDialog = () => { setMigrationDialogOpened(true); };

    const handleMigrationDialogClose = () => {
        setMigrationDialogOpened(false);
        setSelection([]);
    };
    const handleMigrationSubmit = () => {
        handleMigrationDialogClose();
        history.replace(VMS_TABS.MIGRATIONS_TAB);
    };

    const handleServerEditDialogDialog = () => { setServerEditDialogOpened(false); };

    const linkToComputeResourcePage = (id: number) => () => history.push(`${ADMIN_ROUTE_PREFIX}/compute_resources/${id}`);

    const loadPaginated = (page: number) => getComputeResourceVms({ page, filters: getFilters() });

    const handleSelect = (id: number) => () => history.push(`${ADMIN_ROUTE_PREFIX}/servers/${id}`);

    const handleDelete = async (id: number) => {
        await deleteComputeResourceVm(id, false);
        reloadListData(computeResourceVms, loadPaginated);
    };

    const handleBatchDelete = async () => {
        const result = await deleteComputeResourceVms(selection.map(id => parseInt(id, 10)));
        setSelection([]);
        reloadListData(computeResourceVms, loadPaginated, result.length);
    };

    const listData = computeResourceVms.data.map((item) => {
        const handleUpdate = () => {
            setSelectedVm(item.id);
            setServerEditDialogOpened(true);
        };

        return {
            colId: item.id,
            colName: (
                <ComputeResourceVmNameContainer>
                    {item.settings.os_image.icon && (
                        <img src={item.settings.os_image.icon} alt=""/>
                    )}
                    <Action onClick={handleSelect(item.id)}>{item.name}</Action>
                    {item.is_suspended && (
                        <>
                            &nbsp;
                            <Label intent={INTENT_TYPE.WARNING}><Translate content="servers.status.suspended" /></Label>
                        </>
                    )}
                </ComputeResourceVmNameContainer>
            ),
            colStatus: (
                <>
                    {areNetworkLimitsExceeded(item) && (
                        <Icon
                            name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED}
                            intent={INTENT_TYPE.WARNING}
                            style={{ paddingRight: '5px' }}
                        />
                    )}
                    <VmStatus
                        status={item.status}
                        compact={true}
                        progress={item.progress}
                        isProcessing={item.is_processing}
                    />
                </>
            ),
            colComputeResource: item.compute_resource && (
                <Action onClick={linkToComputeResourcePage(item.compute_resource.id)}>{item.compute_resource.host}</Action>
            ),
            colIp: getViewableIps(item).map(ip => <CopyText key={ip.id}>{ip.ip}</CopyText>),
            colCpu: <Usage
                progress={item.usage.cpu}
                title={item.usage.cpu + '%'}
                startColor={COLORS.GREEN}
                endColor={COLORS.RED}
            />,
            colOwner: item?.user.email,
            colCreated: formatTableDate(item.created_at),
            colActions: <ComputeResourceVmActions item={item} iconSize="16" onUpdate={handleUpdate} onDelete={handleDelete}/>,
            key: item.id.toString(),
        };
    });

    const renderRow = (row: IListRow) => {
        const computeResourceVm = computeResourceVms.data.find(item => item.id.toString() === row.key);
        if (computeResourceVm === undefined) {
            return;
        }

        const isItemLoading = !!computeResourceVm.isLoading && !isServerEditDialogOpened;

        return (<ComputeResourceVmRow isLoading={isItemLoading} item={computeResourceVm}/>);
    };

    const filtersIsNotEmpty = !!withFilters && Object.values(filters).some(filter => !!filter);

    return (
        <>
            <List
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                emptyView={emptyView}
                columns={columns.filter(
                    (column) => !excludedColumns || !excludedColumns.includes(column.key)
                )}
                data={listData}
                selection={selection}
                onSelectionChange={setSelection}
                renderRowBody={renderRow}
                onExpandedRowsChange={setExpandedRows}
                expandedRows={expandedRows}
                filtered={filtersIsNotEmpty || isLoading}
                meta={computeResourceVms.meta}
                toolbar={(
                    <ComputeResourceVmOperations
                        filters={filters}
                        setFilters={setFilters}
                        selection={selection.map(id => parseInt(id, 10))}
                        setSelection={setSelection}
                        withFilters={withFilters}
                        openMigrationDialog={openMigrationDialog}
                        handleBatchDelete={handleBatchDelete}
                    />
                )}
            />
            <Dialog
                data-cy={SERVER.MIGRATION_DIALOG}
                heading={
                    <MigrationFromTitle>
                        <Translate content="computeResource.servers.migrationForm.title"/>
                        <MigrationFormTitleLink href="https://docs.solusvm.com/v2/administrator-guide/Migration%2Bof%2BServers%2BBetween%2BCompute%2BResources.html" target="_blank">
                            <Icon name="info-circle" size={12}/>
                            &nbsp;<Translate content="computeResource.servers.migrationForm.documentationLinkTitle"/>
                        </MigrationFormTitleLink>
                    </MigrationFromTitle>
                }
                closeHandler={handleMigrationDialogClose}
                isOpen={isMigrationDialogOpened}
                size="xs"
            >
                <MigrationForm
                    servers={computeResourceVms.data.filter(item => selection.find(id => item.id.toString() === id))}
                    onSubmit={handleMigrationSubmit}
                />
            </Dialog>
            <Dialog
                heading={<Translate content="computeResource.servers.form.titleEdit" />}
                closeHandler={handleServerEditDialogDialog}
                isOpen={isServerEditDialogOpened}
                size="xs"
            >
                <ServerEditDialog computeResourceVmId={selectedVm} onClose={handleServerEditDialogDialog} />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    computeResourceVms: state.computeResourceVm.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComputeResourceVmTable));

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    ISegmentedControlProps,
    SegmentedControl,
} from 'common/components/SegmentedControl/SegmentedControl';

export interface IDisableEnableSegmentedControlProps extends Pick<ISegmentedControlProps<boolean>, 'withConfirmation' | 'onChange'> {
    confirmationPopoverTitle?: React.ReactNode;
    disabled?: boolean;
    value: boolean;
}

const DisabledEnabledSegmentedControl: React.FC<IDisableEnableSegmentedControlProps> = ({
    confirmationPopoverTitle,
    disabled = false,
    value,
    ...rest
}) => {
    const buttons = [
        {
            title: <Translate content="components.disableEnableSegmentedControl.disabled" />,
            value: false,
            disabled: disabled || !value,
        },
        {
            title: <Translate content="components.disableEnableSegmentedControl.enabled" />,
            value: true,
            disabled: disabled || value,
        },
    ];

    return (
        <SegmentedControl
            buttons={buttons}
            confirmationPopoverTranslations={{
                title: confirmationPopoverTitle,
                button: <Translate content="change" />,
                text: <Translate content="components.disableEnableSegmentedControl.confirmText" />,
            }}
            {...rest}
            selected={value}
        />
    );
};

export default DisabledEnabledSegmentedControl;

// Copyright 1999-2023. Plesk International GmbH. All rights reserved.

import styled from 'styled-components';
import { Icon as UILibIcon } from '@plesk/ui-library';

export const Paragraph = styled.div`
  margin-bottom: 20px;
`;

export const InlineItem = styled.div`
  display: flex;
  margin-bottom: 10px;
  > div {
    margin-right: 3px;
  }
`;

export const Icon = styled(UILibIcon)`
    color: ${props => props.theme.primary_color};
`;
